<template lang="pug">
  div.form-group
    input.form-control( :type="type"
                        :value="value"
                        @input="onInput"
                        :id="name"
                        :class="[ validation.$error ? 'is-invalid' : '', inputClass ]" )

    small.form-text.text-muted( v-if="hint" )
      | {{ hint }}
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'required' ) && !validation.required && validation.$error" )
      | can't be blank
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'email' ) && !validation.email && validation.$error" )
      | must be a valid email address
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'maxLength' ) && !validation.maxLength && validation.$error" )
      | is too long
</template>

<script>
  export default {
    name: "formInputWithoutLabel",

    props: {
      value: String,
      validation: Object,
      name: String,
      hint: String,
      type: {
        type: String,
        default: 'text'
      },
      trim: {
        type: Boolean,
        default: true
      },
      inputClass: {
        type: String,
        default: '',
        required: false
      }
    },

    methods: {
      onInput( event ) {
        const value = event.target.value;

        this.$emit( 'input', this.trim ? value.trim() : value );
      }
    }

  }
</script>

<style scoped>
</style>
