export default {
  data() {
    return {
      sortColumn: null,
      reverse:    false
    }
  },

  methods: {
    changeSort( columnName ) {
      if ( this.sortColumn === columnName ) {
        this.reverse = !this.reverse
      }
      else {
        this.sortColumn = columnName
        this.reverse = false
      }
    },

    sortClass( columnName ) {
      if ( this.sortColumn === columnName ) {
        return this.reverse ? 'fa-sort-down' : 'fa-sort-up'
      }
      else {
        return 'fa-sort'
      }
    },

    orderedFilteredSorted( aList, filterFunction ) {
      let list = _.sortBy( aList, this.sortColumn )

      list = _.filter( list, ( i ) => {
        return this.userFilter === '' ? true : filterFunction( i )
      })

      if ( this.reverseOrder ) {
        list = list.reverse()
      }

      return list
    }
  }
}
