<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary      
        form( novalidate @submit.prevent="submit" )
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information
              .row.mb-2
                .col-md
                  .text-muted Name
                  | {{form.employeedetails.first_name}} {{form.employeedetails.last_name}}
                .col-md
                  .text-muted Address
                  | {{form.employeedetails.address}}
                .col-md
                  .text-muted City
                  | {{form.employeedetails.city}}
                .col-md
                  .text-muted State
                  | {{form.employeedetails.state}}
                .col-md
                  .text-muted Zip
                  | {{form.employeedetails.zipcode}}
          .card.mb-3
            .card-body
              h5.card-title
                i.fas.fa-address-book.mr-2
                | Daily Report Details

              .row
                .col-md-2
                  form-date( :value="form.from_date"
                              name="from_date"
                              label="From Date"
                              :validation="$v.form.from_date"
                              @input="form.from_date = $event; $v.form.from_date.$touch()")
                .col-md-2
                  form-date( :value="form.to_date"
                                  name="to_date"
                                  label="To Date"
                                  :validation="$v.form.to_date"
                                  @input="form.to_date = $event; $v.form.to_date.$touch()" )
              .row
                .col-md
                  .card.border-secondary
                  table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
                      thead.bg-secondary.text-white
                        tr
                          th
                          th Date
                          th Reg Hours
                          th Over Time Hours
                          th Total Hours
                      tbody
                        tr.pointer( v-for="row in form_items" :key="row.id" v-if="loaded" )
                          td
                            span
                            | {{row.day_date | dayname}}
                          td
                            span
                            form-input( :value="row.day_date | date"
                                            name="Date"
                                            :validation="$v.form_items.day_date"
                                            disabled="disabled"
                                            @input="row.day_date = $event; $v.row.day_date.$touch()")
                          td
                            form-input( :value="row.reg_hours"
                                            name="Reg Hours"
                                            :validation="$v.form_items.reg_hours"
                                            @input="row.reg_hours = $event; $v.row.reg_hours.$touch()")
                          td
                            form-input( :value="row.overtime_hours"
                                            name="Over Time Hours"
                                            :validation="$v.form_items.overtime_hours"
                                            @input="row.overtime_hours = $event; $v.row.overtime_hours.$touch()")
                          td
                            form-input( :value="row.total_hours"
                                            name="Total Hours"
                                            :validation="$v.form_items.total_hours"
                                            @input="row.total_hours = $event; $v.row.total_hours.$touch()")
                        tr(v-if="form_items.length==0")
                          td(:colspan="4") No Records
              .row
                .col-md
                  form-input( :value="form.other_compensation"
                              name="other_compensation"
                              label="On Call / Other Compensation $"
                              :validation="$v.form.other_compensation"
                              @input="form.other_compensation = $event; $v.form.other_compensation.$touch()")
                .col-md
                  form-input( :value="form.advances_loans_other_deductions"
                              name="advances_loans_other_deductions"
                              label="Advances, Loans, Other Deductions $"
                              :validation="$v.form.advances_loans_other_deductions"
                              @input="form.advances_loans_other_deductions = $event; $v.form.advances_loans_other_deductions.$touch()")
              .row
                .col-md
                  form-input( :value="form.compensation_details"
                              name="compensation_details"
                              label="Explain Compensation"
                              :validation="$v.form.compensation_details"
                              @input="form.compensation_details = $event; $v.form.compensation_details.$touch()")
                .col-md
                  form-input( :value="form.deduction_details"
                              name="deduction_details"
                              label="Explain Deductions"
                              :validation="$v.form.deduction_details"
                              @input="form.deduction_details = $event; $v.form.deduction_details.$touch()")
              .row
                .col-md
                  form-input( :value="form.employee_signature"
                              name="employee_signature"
                              label="Employee Signature"
                              :validation="$v.form.employee_signature"
                              @input="form.employee_signature = $event; $v.form.employee_signature.$touch()")
                .col-md
                  form-input( :value="form.approved_by"
                              name="approved_by"
                              label="Approved By"
                              :validation="$v.form.approved_by"
                              @input="form.approved_by = $event; $v.form.approved_by.$touch()")
                .col-md
                  form-input( :value="form.overtime_approved_by"
                              name="overtime_approved_by"
                              label="Overtime Approved By"
                              :validation="$v.form.overtime_approved_by"
                              @input="form.overtime_approved_by = $event; $v.form.overtime_approved_by.$touch()")
              .row
                .col-md
                  button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
                  button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
                .col-md
                  div( style="position: absolute; right: 1em; bottom: 0;" )
                    i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                    | are
                    |
                    strong required
                    |
                    | fields
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../../shared/form_input'
  import FormTextarea from '../../shared/form_textarea'
  import FormRadio from '../../shared/form_radio'
  import FormSelect from '../../shared/form_select'
  import FormDatetime from '../../shared/form_datetime'
  import FormDate from '../../shared/form_date'
  import FormTime from '../../shared/form_time'
  import parseAddress from 'parse-address'
  import ServiceReports from '../../service_reports/index'
  import moment from 'moment'

  export default {
    name: 'employee_daily_activity_report_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormDatetime,
      FormDate,
      FormTime,
      ServiceReports
    },

    data() {
      return {
        form: null,
        form_items: [],
        is_editable: false,
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },
    watch: {
        'form.from_date': function(val, oldVal) {
          if(val > this.form.to_date)
            this.form.from_date = oldVal;
          this.addnewweeklyreportitem();
        },        
        'form.to_date': function(val, oldVal) {
          if(val < this.form.from_date)
            this.form.to_date = oldVal;
          this.addnewweeklyreportitem();
        }//,
        // 'form_items':function(val, oldVal) {
        //   alert('test')
        //   // formItemChange(val);
        // }
    },

    validations: {
      form: {
        from_date: { required },
        to_date: { required },
        employee_signature: { required },
        approved_by: { required },
        compensation_details: { required },
        deduction_details: { required },
        overtime_approved_by: { required },
        other_compensation: { required },
        advances_loans_other_deductions: { required },
      },
      form_items:{
        day_date:{},
        reg_hours:{},
        overtime_hours:{},
        total_hours:{}
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'employee_weekly_activity_report_new' ) {
          this.exists = false
          url = 'employees/' + this.$route.params.employee_id + '/weekly-activity-report/new'
        }        
        else if ( this.$route.name === 'employee_self_weekly_activity_report_new' ) {
          this.exists = false
          url = 'employees/weekly-activity-report/new'
        }
        else {
          this.exists = true
          url = 'employees/' + this.$route.params.employee_id + '/weekly-activity-report/' + this.$route.params.report_id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.daily_report
            this.form_items = response.data.daily_report_items
            if(this.form==null)
              this.form = {};
            this.form.employeedetails = response.data.employeedetails
            this.loaded = true
          })

      },
      addnewweeklyreportitem(){
        var _from_date = this.form.from_date;
        if((this.form.from_date !=null && this.form.to_date != null) && (this.form.from_date <= this.form.to_date)){
          var days = moment(this.form.to_date).diff(moment(this.form.from_date), 'days');
          for (let i = 0; i <= days; i++) {
            var valObj = this.form_items.filter(function(elem){
                if(moment(elem.day_date).format("M/D/YYYY") == moment(_from_date).add(i,'days').format("M/D/YYYY")) 
                return elem;
            });
            if(valObj.length == 0)
            {
              this.form_items.push({
                day_date: moment(this.form.from_date).add(i,'days'),
                reg_hours:0,
                overtime_hours:0,
                total_hours:0,
                employee_weekly_report_id:this.form.id
              })
            }
          }
        }
      },
      formItemChange(item){
        // alert(JSON.stringify(item))
        alert('test')
        // item.total_hours = item.reg_hours + item.overtime_hours;
      },
      submit() {
        this.submitting = true
        if ( this.exists ) {
          var reportid = this.form.id;
          if(reportid == null)
            reportid = this.$route.params.report_id

          this.$http.secured.post( 'employees/' + this.form.employeedetails.id + '/weekly-activity-report/' + reportid, { weekly_report: this.form, weekly_report_items:this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Weekly Report has been updated successfully!' )
              if(this.$route.params.employee_id!=null)
                this.$router.push( { name: 'employee_weekly_activity_report_index', params: { employee_id: this.$route.params.employee_id } } )
              else
                this.$router.push( { name: 'employee_weekly_activity_report_all_index' } )
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'employees/' + this.$route.params.employee_id + '/weekly-activity-report', { weekly_report: this.form, weekly_report_items:this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Weekly Report has been created successfully!' )
              if(this.$route.params.employee_id!=null)
                this.$router.push( { name: 'employee_weekly_activity_report_index', params: { employee_id: this.$route.params.employee_id } } )
              else
                this.$router.push( { name: 'employee_weekly_activity_report_all_index' } )
            })
        }
      },
      downloadPDF() {
        this.$router.push( { name: 'subcontractor_weekly_activity_report_print', params: { id: this.form.id,employee_id: this.form.employee_id } } )
      }
    }
  }
</script>

<style scoped>
</style>
