<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary      
        form( novalidate @submit.prevent="submit" )
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information
              .row.mb-2
                .col-md
                  .text-muted Name
                  | {{form.employeedetails.first_name}} {{form.employeedetails.last_name}}
                .col-md
                  .text-muted Address
                  | {{form.employeedetails.address}}
                .col-md
                  .text-muted City
                  | {{form.employeedetails.city}}
                .col-md
                  .text-muted State
                  | {{form.employeedetails.state}}
                .col-md
                  .text-muted Zip
                  | {{form.employeedetails.zipcode}}
          .card.mb-3
            .card-body
              h5.card-title
                i.fas.fa-address-book.mr-2
                | Daily Report Details

              .row
                .col-md-2
                  form-date( :value="form.from_date"
                              name="from_date"
                              label="From Date"
                              :validation="$v.form.from_date"
                              @input="form.from_date = $event; $v.form.from_date.$touch()")
                .col-md-2
                  form-date( :value="form.to_date"
                                  name="to_date"
                                  label="To Date"
                                  :validation="$v.form.to_date"
                                  @input="form.to_date = $event; $v.form.to_date.$touch()" )
              .row
                .col-md
                  .card.border-secondary
                    .card-header.bg-dark.text-white
                      .float-right
                        button.btn.btn-outline-light.btn-sm(type='button' @click="addnewreportitem()")
                          i.fas.fa-plus.mr-1
                          | Add New Entry
                    table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
                      thead.bg-secondary.text-white
                        tr
                          th Arrival Date
                          th Departure Date
                          th Hours
                          th Job Name
                          th Description of Work
                          th Com Y/N
                          th Ticket #
                          th Fuel
                      tbody
                        tr.pointer( v-for="row in form_items" :key="row.id" v-if="loaded" )
                          td
                            span
                            form-time( :value="row.arrival"
                                            name="arrival"
                                            :validation="$v.form_items.arrival"
                                            @input="row.arrival = $event; $v.row.arrival.$touch()")

                          td
                            form-time( :value="row.departure"
                                            name="departure"
                                            :validation="$v.form_items.departure"
                                            @input="row.departure = $event; $v.row.departure.$touch()")
                          td
                            form-input( :value="row.hours"
                                            name="hours"
                                            :validation="$v.form_items.hours"
                                            @input="row.hours = $event; $v.row.hours.$touch()")
                          td
                            form-input( :value="row.job_name"
                                            name="job_name"
                                            :validation="$v.form_items.job_name"
                                            @input="row.job_name = $event; $v.row.job_name.$touch()")
                          td
                            form-input( :value="row.work_description"
                                            name="work_description"
                                            :validation="$v.form_items.work_description"
                                            @input="row.work_description = $event; $v.row.work_description.$touch()")
                          td
                            form-input( :value="row.com_y_n"
                                            name="com_y_n"
                                            :validation="$v.form_items.com_y_n"
                                            @input="row.com_y_n = $event; $v.row.com_y_n.$touch()")
                          td
                            form-input( :value="row.ticket"
                                            name="ticket"
                                            :validation="$v.form_items.ticket"
                                            @input="row.ticket = $event; $v.row.ticket.$touch()")
                          td
                            form-input( :value="row.fuel"
                                            name="fuel"
                                            :validation="$v.form_items.fuel"
                                            @input="row.fuel = $event; $v.row.fuel.$touch()")
                        tr(v-if="form_items.length==0")
                          td(:colspan="4") No Records
              .row
                .col-md
                  form-input( :value="form.employee_signature"
                              name="employee_signature"
                              label="Employee Signature"
                              :validation="$v.form.employee_signature"
                              @input="form.employee_signature = $event; $v.form.employee_signature.$touch()")
                .col-md
                  form-input( :value="form.approved_by"
                              name="approved_by"
                              label="Approved By"
                              :validation="$v.form.approved_by"
                              @input="form.approved_by = $event; $v.form.approved_by.$touch()")
              .row
                .col-md
                  button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

                  button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
                .col-md
                  div( style="position: absolute; right: 1em; bottom: 0;" )
                    i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                    | are
                    |
                    strong required
                    |
                    | fields
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../../shared/form_input'
  import FormTextarea from '../../shared/form_textarea'
  import FormRadio from '../../shared/form_radio'
  import FormSelect from '../../shared/form_select'
  import FormDatetime from '../../shared/form_datetime'
  import FormDate from '../../shared/form_date'
  import FormTime from '../../shared/form_time'
  import parseAddress from 'parse-address'
  import ServiceReports from '../../service_reports/index'

  export default {
    name: 'employee_daily_activity_report_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormDatetime,
      FormDate,
      FormTime,
      ServiceReports
    },

    data() {
      return {
        form: null,
        form_items: [],
        is_editable: false,
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        from_date: { required },
        to_date: { required },
        employee_signature: { required },
        approved_by: { required }
      },
      form_items:{
        report_date:{},
        arrival:{},
        departure:{},
        hours:{},
        job_name:{},
        work_description:{},
        com_y_n:{},
        ticket:{},
        fuel:{}
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'employee_daily_activity_report_new' ) {
          this.exists = false
          url = 'employees/' + this.$route.params.employee_id + '/daily-activity-report/new'
        }
        else if ( this.$route.name === 'employee_self_daily_activity_report_new' ) {
          this.exists = false
          url = 'employees/daily-activity-report/new'
        }
        else {
          this.exists = true
          url = 'employees/' + (this.$route.params.employee_id == null ? 0 : this.$route.params.employee_id) + '/daily-activity-report/' + this.$route.params.report_id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.daily_report
            this.form_items = response.data.daily_report_items
            if(this.form==null)
              this.form = {};
            this.form.employeedetails = response.data.employeedetails
            this.loaded = true
          })

      },
      addnewreportitem(){
        this.form_items.push({
          report_date:'',
          arrival:'',
          departure:'',
          hours:'',
          job_name:'',
          work_description:'',
          com_y_n:'',
          ticket:'',
          fuel:'',
          employee_daily_report_id:this.form.id
      })},

      submit() {
        this.submitting = true
        if ( this.exists ) {
          var reportid = this.form.id;
          if(reportid == null)
            reportid = this.$route.params.report_id
          this.$http.secured.post( 'employees/' + this.form.employeedetails.id + '/daily-activity-report/' + reportid, { daily_report: this.form, daily_report_items:this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Daily Report has been updated successfully!' )
              if(this.$route.params.employee_id != null)
                this.$router.push( { name: 'employee_daily_activity_report_index', params: { employee_id: this.$route.params.employee_id} } )
              else
                this.$router.push( { name: 'employee_daily_activity_report_all_index'} )
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'employees/' + this.$route.params.employee_id + '/daily-activity-report', { daily_report: this.form, daily_report_items:this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Daily Report has been created successfully!' )
              if(this.$route.params.employee_id != null)
                this.$router.push( { name: 'employee_daily_activity_report_index', params: { employee_id: this.$route.params.employee_id } } )
              else
                this.$router.push( { name: 'employee_daily_activity_report_all_index'} )
            })
        }
      },
      downloadPDF() {
        this.$router.push( { name: 'subcontractor_daily_activity_report_print', params: { id: this.form.id,employee_id: this.form.employee_id } } )
      }
    }
  }
</script>

<style scoped>
</style>
