// import Session from './helpers/session'
// Vue.mixin( Session )

import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
Vue.use( VueRouter )

// import store from './store/store'

import app from '../app'
import dashboard from '../dashboard'
import login from '../login'

// Customers
import customers from 'components/customers/index'
import customersForm from 'components/customers/edit'
// Construction
import construction from 'components/construction/index'
import constructionForm from 'components/construction/edit'
import constructionPrint from 'components/construction/print'

//fire inspection worksheet
import fireinspectionworksheet from 'components/fire_inspection_worksheet/index'
import fireinspectionworksheetForm from 'components/fire_inspection_worksheet/edit'
import fireinspectionworksheetPrint from 'components/fire_inspection_worksheet/print'

//subcontractor daily activity report
import subcontractor_daily_activity_report from 'components/subcontractor/daily_activity_report/index'
import subcontractor_daily_activity_report_form from 'components/subcontractor/daily_activity_report/edit'
import subcontractor_daily_activity_report_print from 'components/subcontractor/daily_activity_report/print'

//subcontractor job order
import subcontractor_job_order from 'components/subcontractor/job_order/index'
import subcontractor_job_order_form from 'components/subcontractor/job_order/edit'
import subcontractor_job_order_print from 'components/subcontractor/job_order/print'

// Users
import users from 'components/users/index'
import usersForm from 'components/users/edit'

// Employees
import employees from 'components/employees/index'
import employeesForm from 'components/employees/edit'
import employeesActivityReport from 'components/employees/daily_activity_report/index'
import employeesActivityReportForm from 'components/employees/daily_activity_report/edit'

import employeesWeeklyActivityReport from 'components/employees/weekly_activity_report/index'
import employeesWeeklyActivityReportForm from 'components/employees/weekly_activity_report/edit'

// Timeoff Requests
import timeoffRquests from 'components/timeoff_requests/index'
import timeoffRequestForm from 'components/timeoff_requests/edit'

// Service Tickets
import serviceReportForm from 'components/service_reports/edit'
import repairQuoteForm from 'components/repair_quotes/edit'
import serviceReportPrint from 'components/service_reports/print'
import repairQuotePrint from 'components/repair_quotes/print'
import serviceReportIndex from 'components/service_reports/index'
import repairQuoteIndex from 'components/repair_quotes/index'

// Inspection Quote
import inspectionQuoteForm from 'components/inspection_quotes/edit'
import inspectionQuotePrint from 'components/inspection_quotes/print'
import inspectionQuoteIndex from 'components/inspection_quotes/index'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/',
    component: app,
    meta: { loginRequired: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: dashboard
      },
      {
        path: '/customers',
        name: 'customers',
        component: customers
      },
      {
        path: '/customers/new',
        name: 'customer_new',
        component: customersForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)',
        name: 'customer_edit',
        component: customersForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/construction',
        name: 'construction',
        component: construction
      },
      {
        path: '/construction/new',
        name: 'construction_new',
        component: constructionForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/construction/:id(\\d+)',
        name: 'construction_edit',
        component: constructionForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: 'construction/fireinspectionworksheet',
        name: 'fireinspectionworksheet',
        component: fireinspectionworksheet
      },
      {
        path: '/construction/:construction_id(\\d+)/fireinspectionworksheet/new',
        name: 'fireinspectionworksheet_new',
        component: fireinspectionworksheetForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/construction/:construction_id(\\d+)/fireinspectionworksheet/:id(\\d+)',
        name: 'fireinspectionworksheet_edit',
        component: fireinspectionworksheetForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: 'construction/subcontractor/activityreport',
        name: 'subcontractor_daily_activity_report',
        component: subcontractor_daily_activity_report
      },
      {
        path: '/construction/:construction_id(\\d+)/subcontractor/activityreport/new',
        name: 'subcontractor_daily_activity_report_new',
        component: subcontractor_daily_activity_report_form,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/construction/:construction_id(\\d+)/subcontractor/activityreport/:id(\\d+)',
        name: 'subcontractor_daily_activity_report_edit',
        component: subcontractor_daily_activity_report_form,
        meta: { backOfficeRequired: true }
      },
      {
        path: 'construction/subcontractor/joborder',
        name: 'subcontractor_job_order',
        component: subcontractor_job_order
      },
      {
        path: 'construction/:construction_id(\\d+)/subcontractor/joborder/new',
        name: 'subcontractor_job_order_new',
        component: subcontractor_job_order_form,
        meta: { backOfficeRequired: true }
      },
      {
        path: 'construction/:construction_id(\\d+)/subcontractor/joborder/:id(\\d+)',
        name: 'subcontractor_job_order_edit',
        component: subcontractor_job_order_form,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/delete',
        name: 'customer_delete',
        component: customersForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/users/',
        name: 'users',
        component: users,
        meta: { backOfficeRequired: true }
      },
      
      {
        path: '/users/new',
        name: 'user_new',
        component: usersForm,
        meta: { backOfficeRequired: true }
      },

      {
        path: '/users/:id(\\d+)',
        name: 'user_edit',
        component: usersForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/users/:id(\\d+)/delete',
        name: 'user_delete',
        component: usersForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/',
        name: 'employees',
        component: employees,
        meta: {}
      },
      {
        path: '/employees/new',
        name: 'employee_new',
        component: employeesForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/:id(\\d+)',
        name: 'employee_edit',
        component: employeesForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/dailyreport',
        name: 'employee_daily_activity_report_all_index',
        component: employeesActivityReport,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/dailyreport',
        name: 'employee_daily_activity_report_index',
        component: employeesActivityReport,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/dailyreport/new',
        name: 'employee_daily_activity_report_new',
        component: employeesActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/dailyreport/new',
        name: 'employee_self_daily_activity_report_new',
        component: employeesActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/dailyreport/:report_id(\\d+)',
        name: 'employee_daily_activity_report_edit',
        component: employeesActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/dailyreport/:report_id(\\d+)',
        name: 'employee_daily_activity_report_self_edit',
        component: employeesActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/weeklyreport',
        name: 'employee_weekly_activity_report_all_index',
        component: employeesWeeklyActivityReport,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/weeklyreport',
        name: 'employee_weekly_activity_report_index',
        component: employeesWeeklyActivityReport,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/weeklyreport/new',
        name: 'employee_weekly_activity_report_new',
        component: employeesWeeklyActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/weeklyreport/new',
        name: 'employee_self_weekly_activity_report_new',
        component: employeesWeeklyActivityReportForm,
        meta: {}
      },
      {
        path: '/employees/:employee_id(\\d+)/weeklyreport/:report_id(\\d+)',
        name: 'employee_weekly_activity_report_edit',
        component: employeesWeeklyActivityReportForm,
        meta: {}
      },


      {
        path: '/employees/:id(\\d+)/delete',
        name: 'employee_delete',
        component: employeesForm,
        meta: { backOfficeRequired: true }
      },




      {
        path: '/employees/timeoff_requests',
        name: 'timeoff_requests',
        component: timeoffRquests,
        meta: {}
      },
      {
        path: '/employees/:id(\\d+)/timeoff_requests',
        name: 'employee_timeoff_requests',
        component: timeoffRquests,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/timeoff_requests/new',
        name: 'timeoff_request_new',
        component: timeoffRequestForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/timeoff_requests/:id(\\d+)',
        name: 'timeoff_request_edit',
        component: timeoffRequestForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/employees/timeoff_requests/:id(\\d+)/delete',
        name: 'timeoff_request_delete',
        component: timeoffRequestForm,
        meta: { backOfficeRequired: true }
      },





      {
        path: '/customers/:id(\\d+)/service_ticket/new',
        name: 'service_ticket_new',
        component: serviceReportForm
      },
      {
        path: '/customers/:id(\\d+)/service_ticket/:service_ticket_id(\\d+)',
        name: 'service_ticket_edit',
        component: serviceReportForm
      },
      {
        path: '/service_reports',
        name: 'service_reports',
        component: serviceReportIndex,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/service_ticket/:service_ticket_id(\\d+)/delete',
        name: 'service_ticket_delete',
        component: serviceReportForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/inspection_quote/new',
        name: 'inspection_quote_new',
        component: inspectionQuoteForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/inspection_quote/:inspection_quote_id(\\d+)',
        name: 'inspection_quote_edit',
        component: inspectionQuoteForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/inspection_quotes',
        name: 'inspection_quotes',
        component: inspectionQuoteIndex,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/inspection_quote/:inspection_quote_id(\\d+)/delete',
        name: 'inspection_quote_delete',
        component: inspectionQuoteForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/repair_quote/new',
        name: 'repair_quote_new',
        component: repairQuoteForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/repair_quote/:repair_quote_id(\\d+)',
        name: 'repair_quote_edit',
        component: repairQuoteForm,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/repair_quotes',
        name: 'repair_quotes',
        component: repairQuoteIndex,
        meta: { backOfficeRequired: true }
      },
      {
        path: '/customers/:id(\\d+)/repair_quote/:repair_quote_id(\\d+)/delete',
        name: 'repair_quote_delete',
        component: repairQuoteForm,
        meta: { backOfficeRequired: true }
      },
    ]
  },
  {
    path: '/construction/:id(\\d+)/print',
    name: 'construction_print',
    component: constructionPrint,
    meta: { backOfficeRequired: true }
  },
  {
    path: '/construction/:construction_id(\\d+)/fireinspectionworksheet/:id(\\d+)/print',
    name: 'fireinspectionworksheet_print',
    component: fireinspectionworksheetPrint,
    meta: { backOfficeRequired: true }
  },
  {
    path: '/construction/:construction_id(\\d+)/subcontractor/activityreport/:id(\\d+)/print',
    name: 'subcontractor_daily_activity_report_print',
    component: subcontractor_daily_activity_report_print,
    meta: { backOfficeRequired: true }
  },
  {
    path: '/construction/:construction_id(\\d+)/subcontractor/joborder/:id(\\d+)/print',
    name: 'subcontractor_job_order_print',
    component: subcontractor_job_order_print,
    meta: { backOfficeRequired: true }
  },
  {
    path: '/customers/:id(\\d+)/service_ticket/:service_ticket_id(\\d+)/print',
    name: 'service_ticket_print',
    component: serviceReportPrint
  },
  {
    path: '/customers/:id(\\d+)/inspection_quote/:inspection_quote_id(\\d+)/print',
    name: 'inspection_quote_print',
    component: inspectionQuotePrint,
    meta: { backOfficeRequired: true }
  },
  {
    path: '/customers/:id(\\d+)/repair_quote/:repair_quote_id(\\d+)/print',
    name: 'repair_quote_print',
    component: repairQuotePrint,
    meta: { backOfficeRequired: true }
  }
]

const router = new VueRouter( {
  mode: 'history',
  scrollBehavior( to, from, savedPosition ) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
  routes
} )

router.beforeEach( ( to, from, next ) => {
  if ( to.matched.some( record => record.meta.loginRequired ) ) {
    if ( !router.app.$session.$hasCurrentUser() ) {
      next( { name: 'login', query: { redirect: to.fullPath } } )
    }
    else if (to.matched.some( record => record.meta.employeeRequired ) && router.app.$session.$isEmployee()) {
      next()
    }
    else if ( to.matched.some( record => record.meta.backOfficeRequired ) && !router.app.$session.$isBackOffice()) {
      next(from)
    }
    else {
      next()
    }
  }
  else {
    next()
  }
} )

export default router
