<template lang="pug">
  div.form-group
    label( :for="name" )
      i.fas.fa-asterisk.fa-xs.text-danger.mr-1( v-if="validation.hasOwnProperty( 'required' )" )
      | {{ label }}

    small.form-text.text-muted( v-if="hint" )
      | {{ hint }}
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'required' ) && !validation.required && validation.$error" )
      | can't be blank
    model-list-select(:list="options"
                              v-model="value"
                              option-value="id"
                              option-text="name"
                              :name="name"
                              :id="id"
                              :placeholder="placeholder"
                              @input="onInput")
</template>

<script>
  import 'vue-search-select/dist/VueSearchSelect.css'
  // import VueSearchSelect_css from  'vue-search-select/dist/VueSearchSelect.css'
  import { ModelSelect,ModelListSelect } from 'vue-search-select'
  export default {
    name: "formSearchSelect",
    components: {
      ModelSelect,
      ModelListSelect
    },

    props: {
      value: {
        type: [ Number, String ],
        required: false,
        default: null
      },
      validation: Object,
      id:String,
      name: String,
      placeholder: String,
      label: String,
      hint: String,      
      optid: String,
      optval: String,
      options: {
        type: Array,
        required: false,
        default: null
      },
      trim: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      onInput( event ) {
        this.$emit( 'input', event );
      }
    }
  }
</script>

<style scoped>

</style>
