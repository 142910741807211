<template lang="pug">
    div
      .card.border-secondary
        .card-header.bg-dark.text-white
          .float-right
            router-link.btn.btn-outline-light.btn-sm.mr-1( v-if="this.$route.name === 'employee_timeoff_requests'" :to="{ name: 'employees' }" )
              i.fa.fa-reply.mr-1
              | Employee List
            router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'timeoff_request_new' }" )( v-if="!$session.$isManagement()" )
              i.fa.fa-plus.mr-1
              | New Request
          h4.mb-0
            i.fa.fa-user.mr-2
            | Timeoff Requests
        .card-body
  
          .row.mb-2
            .col-md-1
              select#per-page.form-control( v-model.number="perPage" )
                option( value="1" ) 1
                option( value="10" ) 10
                option( value="25" ) 25
                option( value="50" ) 50
                option( value="100" ) 100
  
            .col-md-5
              .d-flex.h-100.text-muted( v-if="loaded" )
                .align-self-center.pb-2 {{ paginateStatus }}
  
            .col-md-6
              .float-right( v-if="loaded" )
                pagination( :currentPage="page"
                            :total="count"
                            :page-size="perPage"
                            :callback="pageChanged"
                            :options="paginationOptions" )
  
          table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
            thead.bg-secondary.text-white
              tr
                th( @click="changeSort( 'employees.emp_id' )" )
                  | Id
                  i.fa.fas.ml-2( :class="sortClass( 'employees.emp_id' )" )
                th( @click="changeSort( 'employees.first_name' )" )
                  | First Name
                  i.fa.fas.ml-2( :class="sortClass( 'employees.first_name' )" )
                th( @click="changeSort( 'employees.last_name' )" )
                  | Last Name
                  i.fa.fas.ml-2( :class="sortClass( 'employees.last_name' )" )
                th( @click="changeSort( 'requested_from' )" )
                  | From
                  i.fa.fas.ml-2( :class="sortClass( 'requested_from' )" )
                th( @click="changeSort( 'requested_to' )" )
                  | To
                  i.fa.fas.ml-2( :class="sortClass( 'requested_to' )" )
                th( @click="changeSort( 'todays_date' )" )
                  | Todays Date
                  i.fa.fas.ml-2( :class="sortClass( 'todays_date' )" )
                th( @click="changeSort( 'reason' )" )
                  | Reason
                  i.fa.fas.ml-2( :class="sortClass( 'reason' )" )
                th( @click="changeSort( 'comments' )" )
                  | Comments
                  i.fa.fas.ml-2( :class="sortClass( 'comments' )" )
                th( @click="changeSort( 'created_at' )" )
                  | Created Date
                  i.fa.fas.ml-2( :class="sortClass( 'created_at' )" )
                th.action( width="10%" )
              tr
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.emp_id"
                                                        @input="debounceEmpId"
                                                        placeholder="Filter Employee Id" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.emp_id = ''" )
                        i.fas.fa-times 
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.first_name"
                                                        @input="debounceFirstName"
                                                        placeholder="Filter First Name" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.first_name = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.last_name"
                                                        @input="debounceLastName"
                                                        placeholder="Filter Last Name" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.last_name = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.requested_from"
                                                        @input="debounceRequestedFrom"
                                                        placeholder="Filter Requested From" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.requested_from = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.requested_to"
                                                        @input="debounceRequestedTo"
                                                        placeholder="Filter Requested To" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.requested_to = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.todays_date"
                                                        @input="debounceTodaysDate"
                                                        placeholder="Filter Date" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.todays_date = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.reason"
                                                        @input="debounceReason"
                                                        placeholder="Filter Reason" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.reason = ''" )
                        i.fas.fa-times
                th
                th 
                th.action
            tbody
              tr.pointer( v-for="row in data" :key="row.id" v-if="loaded" )
                td {{ row.employee.emp_id }}
                td {{ row.employee.first_name }}
                td {{ row.employee.last_name }}
                td {{ $moment(row.requested_from).format( "DD MMM YYYY" ) }}
                td {{ $moment(row.requested_to).format( "DD MMM YYYY" ) }}
                td {{ $moment(row.todays_date).format( "DD MMM YYYY" ) }}
                td {{ row.reason }}
                td {{ row.comments }}
                td {{ $moment(row.created_at).format( "DD MMM YYYY" ) }}
                td
                  ul.list-unstyled.mb-0
                    li
                      router-link( :to="{ name: 'timeoff_request_edit', params: { id: row.id } }" 
                          @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-pencil-alt.mr-2( v-tooltip="'Edit Timeoff Request'")
                    li
                      router-link( :to="{ name: 'timeoff_request_delete', params: { id: row.id } }"
                           @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-trash-alt.mr-2( v-tooltip="'Delete Timeoff Request'" )
  
              tr( v-if="data.length === 0 && loaded" )
                td( colspan="4" )
                  small.text-muted
                    | (No matching requests)
  
              tr( v-if="loading" )
                td( colspan="4" )
                  i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )
  
          .row
            .col-md-6
              .d-flex.h-100.text-muted( v-if="loaded" )
                .align-self-center.pb-2 {{ paginateStatus }}
            .col-md-6
              .float-right( v-if="loaded" )
                pagination( :currentPage="page"
                            :total="count"
                            :page-size="perPage"
                            :callback="pageChanged"
                            :options="paginationOptions" )
  
  </template>
  
  <script>
    import tableUtils from '../../mixins/table_utils'
    import pagination from '../../mixins/paginate'
  
    export default {
      name: 'TimeffRequestsIndex',
  
      mixins: [ tableUtils ],
  
      components: { pagination },
  
      data() {
        return {
          data:   [],
          loaded: false,
          loading: false,
  
          perPage: 10,
          count:   0,
          page:    1,
  
          filters: {
            first_name:  '',
            last_name:  '',
            requested_from: '',
            requested_to: '',
            emp_id: '',            
            todays_date:  '',
            reason: ''
          },
  
          paginationOptions: {
            offset:             4,
            alwaysShowPrevNext: true
          }
        }
      },
  
      computed: {
        paginateStatus() {
          if ( _.every( _.values( this.filters ), _.isEmpty ) ) {
            let start = ( this.page - 1 ) * this.perPage + 1
            let end = start + this.perPage - 1
            if ( end < this.perPage ) {
              end = this.perPage
            }
            else if ( end > this.count ) {
              end = this.count
            }
            return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Requests'
          }
          else {
            return 'Showing ' + this.data.length + ' of ' + this.count + ' Requests'
          }
        }
      },
  
      watch: {
        perPage: 'fetchData',
        filters: { handler: function() { this.fetchData() }, deep: true }
      },
  
      mounted() {
        this.perPage = parseInt( localStorage.timeoffRequestsIndexPerPage ) || 10
        this.sortColumn = 'created_at'
        this.reverse = true
  
        this.$watch( () => { return this.reverse }, () => this.fetchData() )
        this.$watch( () => { return this.sortColumn }, () => this.fetchData() )
  
        this.fetchData()
      },
  
      methods: {
        fetchData() {
          localStorage.timeoffRequestsIndexPerPage = this.perPage
          this.loaded = false
          this.loading = true

          if( this.$route.name === 'employee_timeoff_requests' )
          {
            console.log(this.$route.params.id);
            this.filters.emp_id = this.$route.params.id
          }
  
          this.$http.secured.get( 'timeoff_requests', { params: {
              page:           this.page,
              per_page:       this.perPage,
              sort_column:    this.sortColumn,
              sort_direction: this.reverse ? 'desc' : 'asc',
              filters:        this.filters
            }})
            .then( ( response ) => {
              this.data = response.data.requests
              console.log(this.data)
              this.count = response.data.count
              this.loaded = true
              this.loading = false
            })
        },
  
        pageChanged( page ) {
          this.page = page
          this.fetchData()
        },
  
        debounceFirstName: _.debounce( function( e ) { this.filters.first_name = e.target.value }, 500 ),
        debounceLastName: _.debounce( function( e ) { this.filters.last_name = e.target.value }, 500 ),
        debounceRequestedFrom: _.debounce( function( e ) { this.filters.requested_from = e.target.value }, 2000 ),
        debounceRequestedTo: _.debounce( function( e ) { this.filters.requested_to = e.target.value }, 2000 ),
        debounceEmpId: _.debounce( function( e ) { this.filters.emp_id = e.target.value }, 500 ),
        debounceTodaysDate: _.debounce( function( e ) { this.filters.todays_date = e.target.value }, 2000 ),
        debounceReason: _.debounce( function( e ) { this.filters.reason = e.target.value }, 500 )
      }
    }
  </script>
  
  <style scoped>
  </style>
  