<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'subcontractor_job_order_new', params: { construction_id: construction_id} }" v-if="construction_id" )
            i.fas.fa-plus.mr-1
            | New Subcontractor Job Order Form
        h4.mb-0
          i.fas.fa-building.mr-2
          | Subcontractor Job Order Forms
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              th( @click="changeSort( 'issues_to' )" )
                | Issues To
                i.fa.fas.ml-2( :class="sortClass( 'issues_to' )" )
              th( @click="changeSort( 'phone' )" )
                | Phone
                i.fa.fas.ml-2( :class="sortClass( 'phone' )" )
              th( @click="changeSort( 'email' )" )
                | Email
                i.fa.fas.ml-2( :class="sortClass( 'email' )" )
              th( @click="changeSort( 'job_order_date' )" )
                | Job Order Date
                i.fa.fas.ml-2( :class="sortClass( 'job_order_date' )" )
              th( @click="changeSort( 'project_name' )" )
                | Project Name
                i.fa.fas.ml-2( :class="sortClass( 'project_name' )" )
              th( @click="changeSort( 'project_address' )" )
                | Project Address
                i.fa.fas.ml-2( :class="sortClass( 'project_address' )" )
              th.action( width="10%" )
            tr
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.issues_to"
                                                      @input="debounce_issues_to"
                                                      placeholder="Filter Issues To" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.issues_to = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.phone"
                                                      @input="debounce_phone"
                                                      placeholder="Filter Phone" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.phone = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.email"
                                                      @input="debounce_email"
                                                      placeholder="Filter Email" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.email = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.job_order_date"
                                                      @input="debounce_job_order_date"
                                                      placeholder="Filter Job Order Date" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.job_order_date = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.project_name"
                                                      @input="debounce_project_name"
                                                      placeholder="Filter Project Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.project_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.project_address"
                                                      @input="debounce_project_address"
                                                      placeholder="Filter Project Address" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.project_address = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.number" @click="gotoCustomer( row )" v-if="loaded" )
              td {{ row.issues_to }}
              td {{ row.phone }}
              td {{ row.email }}
              td {{ row.job_order_date }}
              td {{ row.project_name }}
              td {{ row.project_address }}
              td {{ row.job_order_number }}
              td
                router-link( :to="{ name: 'subcontractor_job_order_edit', params: { id: row.id, construction_id: row.construction_id } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Job Order'" )

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching Job Orders)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../../mixins/table_utils'
  import pagination from '../../../mixins/paginate'

  export default {
    name: 'subcontractor_job_order_index',

    mixins: [ tableUtils ],

    components: { pagination },
    props: {
      construction_id: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,

        filters: {
          issues_to:            '',
          phone:              '',
          email:           '',
          job_order_date:              '',
          project_name:      '',
          project_address: '',
          job_order_number:   '',
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Job Reports'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.sortColumn = 'issues_to'

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        let url
        if ( this.construction_id ) {
          url = 'construction/' + this.construction_id + '/subcontractor/job_order'
        }
        else {
          url = 'subcontractor/job_order'
        }
        localStorage.customerIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        this.$http.secured.get( url, { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.subcontractor_job_order
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoCustomer( customer ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'subcontractor_job_order_edit', params: { id: customer.id, construction_id: customer.construction_id } } )
        }
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounce_issues_to: _.debounce( function( e ) { this.filters.issues_to = e.target.value }, 500 ),
      debounce_phone: _.debounce( function( e ) { this.filters.phone = e.target.value }, 500 ),
      debounce_email: _.debounce( function( e ) { this.filters.email = e.target.value }, 500 ),
      debounce_job_order_date: _.debounce( function( e ) { this.filters.job_order_date = e.target.value }, 500 ),
      debounce_project_name: _.debounce( function( e ) { this.filters.project_name = e.target.value }, 500 ),
      debounce_project_address: _.debounce( function( e ) { this.filters.project_address = e.target.value }, 500 ),
      debounce_job_order_number: _.debounce( function( e ) { this.filters.job_order_number = e.target.value }, 500 )
    }
  }
</script>

<style scoped>
</style>
