<template lang="pug">
    div
      .card.border-secondary
        .card-header.bg-dark.text-white
          .float-right
            router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'employee_new' }" )( v-if="$session.$isManagement()" )
              i.fa.fa-plus.mr-1
              | New Employee
          h4.mb-0
            i.fa.fa-user.mr-2
            | Employees
        .card-body
  
          .row.mb-2
            .col-md-1
              select#per-page.form-control( v-model.number="perPage" )
                option( value="1" ) 1
                option( value="10" ) 10
                option( value="25" ) 25
                option( value="50" ) 50
                option( value="100" ) 100
  
            .col-md-5
              .d-flex.h-100.text-muted( v-if="loaded" )
                .align-self-center.pb-2 {{ paginateStatus }}
  
            .col-md-6
              .float-right( v-if="loaded" )
                pagination( :currentPage="page"
                            :total="count"
                            :page-size="perPage"
                            :callback="pageChanged"
                            :options="paginationOptions" )
  
          table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
            thead.bg-secondary.text-white
              tr
                th( @click="changeSort( 'emp_id' )" )
                  | Id
                  i.fa.fas.ml-2( :class="sortClass( 'emp_id' )" )
                th( @click="changeSort( 'first_name' )" )
                  | First Name
                  i.fa.fas.ml-2( :class="sortClass( 'first_name' )" )
                th( @click="changeSort( 'last_name' )" )
                  | Last Name
                  i.fa.fas.ml-2( :class="sortClass( 'last_name' )" )
                th( @click="changeSort( 'dob' )" )
                  | Date of Birth
                  i.fa.fas.ml-2( :class="sortClass( 'dob' )" )
                th( @click="changeSort( 'address' )" )
                  | Address
                  i.fa.fas.ml-2( :class="sortClass( 'address' )" )
                th( @click="changeSort( 'email_address' )" )
                  | Email
                  i.fa.fas.ml-2( :class="sortClass( 'email_address' )" )
                th( @click="changeSort( 'doj' )" )
                  | Date of Joining
                  i.fa.fas.ml-2( :class="sortClass( 'doj' )" )
                th( @click="changeSort( 'wage_rate' )" )
                  | Wage Rate
                  i.fa.fas.ml-2( :class="sortClass( 'wage_rate' )" )
                th( @click="changeSort( 'created_at' )" )
                  | Created Date
                  i.fa.fas.ml-2( :class="sortClass( 'created_at' )" )
                th.action( width="10%" )
              tr
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.emp_id"
                                                        @input="debounceEmpId"
                                                        placeholder="Filter Employee Id" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.emp_id = ''" )
                        i.fas.fa-times 
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.first_name"
                                                        @input="debounceFirstName"
                                                        placeholder="Filter First Name" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.first_name = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.last_name"
                                                        @input="debounceLastName"
                                                        placeholder="Filter Last Name" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.last_name = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.dob"
                                                        @input="debounceDOB"
                                                        placeholder="Filter Date of Birth" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.dob = ''" )
                        i.fas.fa-times
                th
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.email_address"
                                                        @input="debounceEmailAddress"
                                                        placeholder="Filter Email" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.email_address = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.doj"
                                                        @input="debounceDOJ"
                                                        placeholder="Filter Date of Joining" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.doj = ''" )
                        i.fas.fa-times
                th
                  .input-group
                    input.form-control.form-control-sm( :value="filters.wage_rate"
                                                        @input="debounceWageRate"
                                                        placeholder="Filter Wage Rate" )
                    .input-group-append
                      button.btn.bg-white.btn-sm( @click="filters.wage_rate = ''" )
                        i.fas.fa-times
                th
                th.action
            tbody
              tr.pointer( v-for="row in data" :key="row.id" @click="goto( row )" v-if="loaded" )
                td {{ row.emp_id }}
                td {{ row.first_name }}
                td {{ row.last_name }}
                td {{ $moment(row.dob).format( "DD MMM YYYY" ) }}
                td {{ row.address }}
                td {{ row.email_address }}
                td {{ $moment(row.doj).format( "DD MMM YYYY" ) }}
                td {{ row.wage_rate }}
                td {{ $moment(row.created_at).format( "DD MMM YYYY" ) }}
                td
                  ul.list-unstyled.mb-0
                    li
                      router-link( :to="{ name: 'employee_timeoff_requests', params: { id: row.emp_id } }" 
                          @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-calendar-alt.mr-2( v-tooltip="'View Timeoff Requests'" )

                      router-link( :to="{ name: 'employee_edit', params: { id: row.id } }" 
                          @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-pencil-alt.mr-2( v-tooltip="'Edit Employee'")

                      //- router-link( :to="{ name: 'employee_daily_activity_report_new', params: { employee_id: row.id } }"
                      //-     @click.native="$event.stopImmediatePropagation()")
                      //-   i.fa.fa-calendar-check.mr-2( v-tooltip="'New Daily Activity Report'" )

                      router-link( :to="{ name: 'employee_daily_activity_report_index', params: { employee_id: row.id } }"
                          @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-calendar-check.mr-2( v-tooltip="'Daily Activity Report List'" )

                      router-link( :to="{ name: 'employee_weekly_activity_report_index', params: { employee_id: row.id } }"
                          @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-calendar-check.mr-2( v-tooltip="'Weekly Activity Report List'" )

                      router-link( :to="{ name: 'employee_delete', params: { id: row.id } }"
                           @click.native="$event.stopImmediatePropagation()")
                        i.fa.fa-trash-alt.mr-2( v-tooltip="'Delete Employee'")
  
              tr( v-if="data.length === 0 && loaded" )
                td( colspan="4" )
                  small.text-muted
                    | (No matching users)
  
              tr( v-if="loading" )
                td( colspan="4" )
                  i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )
  
          .row
            .col-md-6
              .d-flex.h-100.text-muted( v-if="loaded" )
                .align-self-center.pb-2 {{ paginateStatus }}
            .col-md-6
              .float-right( v-if="loaded" )
                pagination( :currentPage="page"
                            :total="count"
                            :page-size="perPage"
                            :callback="pageChanged"
                            :options="paginationOptions" )
  
  </template>
  
  <script>
    import tableUtils from '../../mixins/table_utils'
    import pagination from '../../mixins/paginate'
  
    export default {
      name: 'EmployeesIndex',
  
      mixins: [ tableUtils ],
  
      components: { pagination },
  
      data() {
        return {
          data:   [],
          loaded: false,
          loading: false,
  
          perPage: 10,
          count:   0,
          page:    1,
  
          filters: {
            first_name:  '',
            last_name:  '',
            email_address: '',
            dob: '',
            emp_id: '',            
            doj:  '',
            wage_rate: ''
          },
  
          paginationOptions: {
            offset:             4,
            alwaysShowPrevNext: true
          }
        }
      },
  
      computed: {
        paginateStatus() {
          if ( _.every( _.values( this.filters ), _.isEmpty ) ) {
            let start = ( this.page - 1 ) * this.perPage + 1
            let end = start + this.perPage - 1
            if ( end < this.perPage ) {
              end = this.perPage
            }
            else if ( end > this.count ) {
              end = this.count
            }
            return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Employees'
          }
          else {
            return 'Showing ' + this.data.length + ' of ' + this.count + ' Employees'
          }
        }
      },
  
      watch: {
        perPage: 'fetchData',
        filters: { handler: function() { this.fetchData() }, deep: true }
      },
  
      mounted() {
        this.perPage = parseInt( localStorage.usersIndexPerPage ) || 10
        this.sortColumn = 'first_name'
  
        this.$watch( () => { return this.reverse }, () => this.fetchData() )
        this.$watch( () => { return this.sortColumn }, () => this.fetchData() )
  
        this.fetchData()
      },
  
      methods: {
        fetchData() {
          localStorage.customerIndexPerPage = this.perPage
          this.loaded = false
          this.loading = true
  
          this.$http.secured.get( 'employees', { params: {
              page:           this.page,
              per_page:       this.perPage,
              sort_column:    this.sortColumn,
              sort_direction: this.reverse ? 'desc' : 'asc',
              filters:        this.filters
            }})
            .then( ( response ) => {
              this.data = response.data.employees
              this.count = response.data.count
              this.loaded = true
              this.loading = false
            })
        },
  
        goto( model ) {
          this.$router.push( { name: 'employee_edit', params: { id: model.id } } )
        },
  
        pageChanged( page ) {
          this.page = page
          this.fetchData()
        },
  
        debounceFirstName: _.debounce( function( e ) { this.filters.first_name = e.target.value }, 500 ),
        debounceLastName: _.debounce( function( e ) { this.filters.last_name = e.target.value }, 500 ),
        debounceEmailAddress: _.debounce( function( e ) { this.filters.email_address = e.target.value }, 500 ),
        debounceDOB: _.debounce( function( e ) { this.filters.dob = e.target.value }, 2000 ),
        debounceEmpId: _.debounce( function( e ) { this.filters.emp_id = e.target.value }, 500 ),
        debounceDOJ: _.debounce( function( e ) { this.filters.doj = e.target.value }, 2000 ),
        debounceWageRate: _.debounce( function( e ) { this.filters.wage_rate = e.target.value }, 500 )
      }
    }
  </script>
  
  <style scoped>
  </style>
  