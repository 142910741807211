<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'subcontractor_daily_activity_report_new', params: { construction_id: construction_id} }" v-if="construction_id" )
            i.fas.fa-plus.mr-1
            | New Subcontractor Daily Activity Report
        h4.mb-0
          i.fas.fa-building.mr-2
          | Subcontractor Daily Activity Reports
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              th( @click="changeSort( 'sub_contractor_name' )" )
                | Subcontractor Name
                i.fa.fas.ml-2( :class="sortClass( 'sub_contractor_name' )" )
              th( @click="changeSort( 'start_date' )" )
                | Start Date
                i.fa.fas.ml-2( :class="sortClass( 'start_date' )" )
              th( @click="changeSort( 'end_date' )" )
                | End Date
                i.fa.fas.ml-2( :class="sortClass( 'end_date' )" )
              th( @click="changeSort( 'sub_contractor_signature' )" )
                | Subcontractor Signature
                i.fa.fas.ml-2( :class="sortClass( 'sub_contractor_signature' )" )
              th( @click="changeSort( 'approved_by' )" )
                | Approved By
                i.fa.fas.ml-2( :class="sortClass( 'approved_by' )" )
              th( @click="changeSort( 'created_at' )" )
                | Created At
                i.fa.fas.ml-2( :class="sortClass( 'created_at' )" )
              th( @click="changeSort( 'updated_at' )" )
                | Updated At
                i.fa.fas.ml-2( :class="sortClass( 'updated_at' )" )
              th.action( width="10%" )
            tr
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.sub_contractor_name"
                                                      @input="debounce_sub_contractor_name"
                                                      placeholder="Filter Subcontractor Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.sub_contractor_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.start_date"
                                                      @input="debounce_start_date"
                                                      placeholder="Filter Start Date" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.start_date = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.end_date"
                                                      @input="debounce_end_date"
                                                      placeholder="Filter End Date" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.end_date = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.sub_contractor_signature"
                                                      @input="debounce_sub_contractor_signature"
                                                      placeholder="Filter Subcontractor Signature" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.sub_contractor_signature = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.approved_by"
                                                      @input="debounce_approved_by"
                                                      placeholder="Filter Approved By" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.approved_by = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.created_at"
                                                      @input="debounce_created_at"
                                                      placeholder="Filter Created At" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.created_at = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.updated_at"
                                                      @input="debounce_updated_at"
                                                      placeholder="Filter Updated At" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.updated_at = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.id" @click="gotoCustomer( row )" v-if="loaded" )
              td {{ row.sub_contractor_name }}
              td {{ row.start_date }}
              td {{ row.end_date }}
              td {{ row.sub_contractor_signature }}
              td {{ row.approved_by }}
              td {{ row.created_at }}
              td {{ row.updated_at }}
              td
                router-link( :to="{ name: 'subcontractor_daily_activity_report_edit', params: { id: row.id,construction_id:row.construction_id } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Daily Report'" )

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching Reports)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../../mixins/table_utils'
  import pagination from '../../../mixins/paginate'

  export default {
    name: 'subcontractor_daily_activity_report_index',

    mixins: [ tableUtils ],

    components: { pagination },
    props: {
      construction_id: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,

        filters: {
          sub_contractor_name:'',
          start_date:'',
          end_date:'',
          sub_contractor_signature:'',
          approved_by:'',
          created_at:'',
          updated_at:'',
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Daily Reports'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.sortColumn = 'sub_contractor_name'

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        let url
        if ( this.construction_id ) {
          url = 'construction/' + this.construction_id + '/subcontractor/daily_activity_report'
        }
        else {
          url = 'subcontractor/daily_activity_report'
        }
        localStorage.customerIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        this.$http.secured.get( url, { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.daily_report
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoCustomer( customer ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'subcontractor_daily_activity_report_edit', params: { id: customer.id,construction_id: customer.construction_id } } )
        }
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounce_sub_contractor_name: _.debounce( function( e ) { this.filters.sub_contractor_name = e.target.value }, 500 ),
      debounce_start_date: _.debounce( function( e ) { this.filters.start_date = e.target.value }, 500 ),
      debounce_end_date: _.debounce( function( e ) { this.filters.end_date = e.target.value }, 500 ),
      debounce_sub_contractor_signature: _.debounce( function( e ) { this.filters.sub_contractor_signature = e.target.value }, 500 ),
      debounce_approved_by: _.debounce( function( e ) { this.filters.approved_by = e.target.value }, 500 ),
      debounce_created_at: _.debounce( function( e ) { this.filters.approved_by = e.target.value }, 500 ),
      debounce_updated_at: _.debounce( function( e ) { this.filters.created_at = e.target.value }, 500 ),
      debounce_updated_at: _.debounce( function( e ) { this.filters.updated_at = e.target.value }, 500 )
    }
  }
</script>

<style scoped>
</style>
