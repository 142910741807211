<template lang="pug">
  div
    label
      i.fas.fa-asterisk.fa-xs.text-danger.mr-1( v-if="validation.hasOwnProperty( 'required' )" )
      | {{ label }}
    br/
    template( v-for="option in idOptions" )
      div.form-check.form-check-inline
        input.form-input-check( type="radio"
                                :value="option.value"
                                @input="onInput"
                                :id="option.idName"
                                :name="name"
                                :checked="value === option.value"
                                :class="{ 'is-invalid': validation.$error }" )
        label.form-check-label.pl-1( :for="option.idName" ) {{ option.label }}

      small.form-text.text-muted( v-if="hint" )
        | {{ hint }}
      span.invalid-feedback( v-if="validation.hasOwnProperty( 'required' ) && !validation.required && validation.$error" )
        | can't be blank
</template>

<script>
  export default {
    name: "formRadio",

    props: {
      options: Array,
      validation: Object,
      name: String,
      label: String,
      hint: String,
      value: {
        type: [ Boolean, String ],
        required: false,
        default: null
      }
    },

    data() {
      return {
        idOptions: []
      }
    },

    mounted() {
      this.idOptions = this.options
      _.each( this.idOptions, ( option ) => {
        this.$set( option, 'idName', _.uniqueId( _.kebabCase( option.label ) + '_' ) )
      } )
    },

    methods: {
      onInput( event ) {
        const value = event.target.value;

        this.$emit( 'input', value );
      }
    }

  }
</script>

<style scoped>
</style>
