import axios from 'axios'
import toastr from 'toastr'
import router from './router'
import NProgress from 'nprogress'

const API_URL = '/api/v1/'

const securedAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token'
})

securedAxiosInstance.interceptors.request.use( ( config ) => {
  config.url = config.url + '.json'
  NProgress.start()
  $( '#app-spinner' ).show()
  return config
})

securedAxiosInstance.interceptors.response.use(
  ( response ) => {
    NProgress.done()
    $( '#app-spinner' ).hide()
    return response
  },
  ( error ) => {
    if ( error.response && error.response.status === 401 ) {
      localStorage.removeItem( 'kfp-user' )
      NProgress.done()
      $( '#app-spinner' ).hide()
      router.replace( { name: 'login' } )
    }
    if ( error.response && error.response.status === 422 ) {
      NProgress.done()
      $( '#app-spinner' ).hide()
    }
    else {
      toastr.error( 'There has been an error handling your request: ' + error.response.error )
    }
    return Promise.reject( error )
  })

const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

plainAxiosInstance.interceptors.request.use( ( config ) => {
  config.url = config.url + '.json'

  NProgress.start()
  $( '#app-spinner' ).show()
  return config
})

plainAxiosInstance.interceptors.response.use( ( response ) => {
  NProgress.done()
  $( '#app-spinner' ).hide()
  return response
})

export { securedAxiosInstance, plainAxiosInstance }
