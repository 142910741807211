<template lang="pug">
  .container-fluid.application-padding
    .row
      .offset-md-4.col-md-4
        .card.mt-3.border-secondary
          .card-header.bg-dark.text-white
            h4.mb-0
              i.fa.fa-user.mr-2
              | KFP Login
          .card-body
            form( name="login" @submit.prevent="login" )

              form-input( :value="form.email"
                          name="email"
                          label="Username or Email"
                          :validation="$v.form.email"
                          @input="form.email = $event; $v.form.email.$touch()" )

              form-input( :value="form.password"
                          name="password"
                          label="Password"
                          type="password"
                          :validation="$v.form.password"
                          @input="form.password = $event; $v.form.password.$touch()" )

              button.btn.btn-primary.mr-2( type="submit" :disabled="$v.$invalid || submitting" )
                i.fa.fa-sign-in.mr-1
                | Login
              i.fas.fa-spinner.fa-spin.fa-lg( v-if="submitting" )

</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import FormInput from './components/shared/form_input'
  import NProgress from 'nprogress'

  export default {
    name: "Login",

    components: {
      FormInput
    },

    data() {
      return {
        submitting: false,
        form: {
          email: null,
          password: null
        }
      }
    },

    validations: {
      form: {
        email:    { required },
        password: { required }
      }
    },

    mounted () {
      this.checkSignedIn()
    },

    methods: {
      login() {
        this.$http.plain.post( 'sessions', { credentials: this.form } )
          .then( response => this.signinSuccessful( response ) )
          .catch( error => this.signinFailed( error ) )
      },

      signinSuccessful( response ) {
        this.$session.$setCurrentUser( response.data )

        if ( this.$route.query.hasOwnProperty( 'redirect' ) ) {
          this.$router.replace( { path: this.$route.query.redirect } )
        }
        else {
          this.$router.replace( { name: 'dashboard' } )
        }
      },

      signinFailed( error ) {
        this.$session.$logout()
        this.error = ( error.response && error.response.data && error.response.data.error ) || ''
        this.$toastr.error( 'We were unable to log you in.' )
        NProgress.done()
        $( '#app-spinner' ).hide()

        this.form.email = ''
        this.form.password = ''
        this.$v.$reset()
      },

      checkSignedIn() {
        if ( this.$session.$hasCurrentUser ) {
          this.$router.replace( { name: 'dashboard' } )
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
