<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .main-body
      .row.mb-3
        .col
          .float-left
            img( src='~images/kodiak.jpg' width="100" height="100" )
          .float-right( style="width:100px; height:100px" ) &nbsp;
          .text-center
            h1
              u
                strong.text-danger K
                | odiak
                |
                strong.text-danger F
                | ire
                |
                strong.text-danger P
                | rotection, Inc
            h3.mb-0
              | 507 Prestige Cr. Allen, Texas 75002
              br/
              | Phone: (469) 342-6819
              | &#9679;
              | Fax: (800) 335-6449
              br/
              | TXACR-3354
              | &#9679;
              | TXSCR-1751231
              | &#9679;
              | TXECR-166290
              br/
              span.text-danger www.kodiakfiretexas.com

      .row.mb-3
        .col.text-center
          h2.mb-0
            strong Fire Inspection Worksheet
      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Customer Name:
            .col-6.pl-0 {{form.constructiondetails.customer_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Customer Address:
            .col-6.pl-0 {{form.constructiondetails.customer_address}}
          .row
            .col-6.text-right.text-muted.pr-1 City:
            .col-6.pl-0 {{form.constructiondetails.customer_city}}
          .row
            .col-6.text-right.text-muted.pr-1 State:
            .col-6.pl-0 {{form.constructiondetails.customer_state}}
          .row
            .col-6.text-right.text-muted.pr-1 Zip:
            .col-6.pl-0 {{form.constructiondetails.customer_zip}}

        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Project Number:
            .col-6.pl-0 {{ form.city }}
          .row
            .col-6.text-right.text-muted.pr-1 Email:
            .col-6.pl-0 {{form.constructiondetails.project_email}}
          .row
            .col-6.text-right.text-muted.pr-1 Phone:
            .col-6.pl-0 {{form.constructiondetails.project_phone}}

      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Inspection Date Time:
            .col-6.pl-0 {{ form.date_inspected | datetime}}
          .row
            .col-6.text-right.text-muted.pr-1 Project Name:
            .col-6.pl-0 {{ form.project_name }}
          .row
            .col-6.text-right.text-muted.pr-1 Billing Number:
            .col-6.pl-0 {{ form.billing_number }}
          .row
            .col-6.text-right.text-muted.pr-1 Address:
            .col-6.pl-0 {{ form.address }}

        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 City:
            .col-6.pl-0 {{ form.city }}
          .row
            .col-6.text-right.text-muted.pr-1 State:
            .col-6.pl-0 {{ form.state }}
          .row
            .col-6.text-right.text-muted.pr-1 Zip:
            .col-6.pl-0 {{ form.zip }}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Contractor:
            .col-6.pl-0 {{ form.contractor }}
          .row
            .col-6.text-right.text-muted.pr-1 Phone Number:
            .col-6.pl-0 {{ form.phone_number }}
          .row
            .col-6.text-right.text-muted.pr-1 City Inspector:
            .col-6.pl-0 {{ form.city_inspector }}
          .row
            .col-6.text-right.text-muted.pr-1 Kodiak Technician:
            .col-6.pl-0 {{ form.kodiak_technician }}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Fire Alarm Inspection:
            .col-6.pl-0 {{ form.fire_alarm_inspection ?'Yes' : 'No' }}
          .row
            .col-6.text-right.text-muted.pr-1 Fire Sprinkler Inspection:
            .col-6.pl-0 {{ form.fire_sprinkler_inspection ?'Yes' : 'No' }}
          .row
            .col-6.text-right.text-muted.pr-1 Did Fire Alarm Pass:
            .col-6.pl-0 {{ form.did_fire_alarm_pass ?'Yes' : 'No' }}
          .row
            .col-6.text-right.text-muted.pr-1 Did Fire Sprinkler Pass:
            .col-6.pl-0 {{ form.did_fire_sprinkler_pass ?'Yes' : 'No' }}
          .row
            .col-6.text-right.text-muted.pr-1 If system did not pass explain why:
            .col-6.pl-0 {{ form.system_did_not_pass_reason ?'Yes' : 'No'}}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Is system taken off Test and put back on-line?:
            .col-6.pl-0 {{ form.is_system_taken_off_test_and_put_back_on_line ?'Yes' : 'No' }}
          .row
            .col-6.text-right.text-muted.pr-1 Technician Signature:
            .col-6.pl-0 {{ form.technician_signature }}
          .row
            .col-6.text-right.text-muted.pr-1 City Inspector Name:
            .col-6.pl-0 {{ form.city_inspector_signature }}
          .row
            .col-6.text-right.text-muted.pr-1 Contractor Signature:
            .col-6.pl-0 {{ form.contractor_signature }}
    .row.mb-4.bordered.footer
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1
</template>

<script>
  import '../../images/kodiak.jpg'

  export default {
    name: 'fireInspectionPrint',

    data() {
      return {
        form: null,
        loaded: false
      }
    },

    mounted() {
      const url = '/construction/' + this.$route.params.construction_id + '/fire_inspection_worksheet/' + this.$route.params.id

      console.log( url );

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data.fireinspection
          this.form.constructiondetails = response.data.constructiondetails
          this.loaded = true
        })
    }

  }
</script>

<style scoped lang="scss">
.footer{
  bottom: 20px;
}
.main-body{
  min-height: 600px;;
}
  .signature {
    border: 1px solid;
    border-radius: 4px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>