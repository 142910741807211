<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .main-body
      .row.mb-3
        .col
          .float-left
            img( src='~images/kodiak.jpg' width="100" height="100" )
          .float-right( style="width:100px; height:100px" ) &nbsp;
          .text-center
            h1
              u
                strong.text-danger K
                | odiak
                |
                strong.text-danger F
                | ire
                |
                strong.text-danger P
                | rotection, Inc
            h3.mb-0
              | 507 Prestige Cr. Allen, Texas 75002
              br/
              | Phone: (469) 342-6819
              | &#9679;
              | Fax: (800) 335-6449
              br/
              | TXACR-3354
              | &#9679;
              | TXSCR-1751231
              | &#9679;
              | TXECR-166290
              br/
              span.text-danger www.kodiakfiretexas.com

      .row.mb-3
        .col.text-center
          h2.mb-0
            strong Construction Details
      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Customer Name:
            .col-6.pl-0 {{form.customer_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Customer Address:
            .col-6.pl-0 {{form.customer_address}}
          .row
            .col-6.text-right.text-muted.pr-1 City:
            .col-6.pl-0 {{form.customer_city}}
          .row
            .col-6.text-right.text-muted.pr-1 State:
            .col-6.pl-0 {{form.customer_state}}
          .row
            .col-6.text-right.text-muted.pr-1 Zip:
            .col-6.pl-0 {{form.customer_zip}}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Project Number:
            .col-6.pl-0 {{form.project_number}}
          .row
            .col-6.text-right.text-muted.pr-1 Project Manager Name:
            .col-6.pl-0 {{form.pm_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Email:
            .col-6.pl-0 {{form.project_email}}
          .row
            .col-6.text-right.text-muted.pr-1 Phone:
            .col-6.pl-0 {{form.project_phone}}
      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Job Name:
            .col-6.pl-0 {{form.job_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Job Address:
            .col-6.pl-0 {{form.job_address}}
          .row
            .col-6.text-right.text-muted.pr-1 Job City:
            .col-6.pl-0 {{form.job_city}}
          .row
            .col-6.text-right.text-muted.pr-1 Job State:
            .col-6.pl-0 {{form.job_state}}
          .row
            .col-6.text-right.text-muted.pr-1 Job Zip:
            .col-6.pl-0 {{form.job_zip}}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Superintendent:
            .col-6.pl-0 {{form.superintendent_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Superintendent Email:
            .col-6.pl-0 {{form.superintendent_email}}
          .row
            .col-6.text-right.text-muted.pr-1 Superintendent Phone:
            .col-6.pl-0 {{form.superintendent_phone}}

    .row.mb-4.bordered.footer
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1
</template>

<script>
  import '../../images/kodiak.jpg'

  export default {
    name: 'constructionPrint',

    data() {
      return {
        form: null,
        loaded: false
      }
    },

    mounted() {
      const url = '/construction/' + this.$route.params.id

      console.log( url );

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data.construction
          this.loaded = true
        })
    }

  }
</script>

<style scoped lang="scss">
.footer{
  bottom: 20px;
}
.main-body{
  min-height: 600px;;
}
  .signature {
    border: 1px solid;
    border-radius: 4px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>