<template lang="pug">
    div( v-if="loaded" )
      .row
        .offset-md-3.col-md-6
  
          .card.border-secondary
            .card-header.bg-dark.text-white
              h4.mb-0
                i.fa.fa-user.mr-2
                template( v-if="exists" )
                  | Edit Employee - {{ form.first_name + ' ' + form.last_name }}
                template( v-else )
                  | New Employee
            .card-body
  
              form( novalidate @submit.prevent="submit" )
  
                .card.mb-3
                  .card-body
                    h5.card-title
                      i.fa.fa-user.mr-2
                      | Employee Information
                    .row
                      .col-md-6
                        form-input( :value="form.emp_id"
                                    name="emp_id"
                                    label="Employee Id"
                                    :validation="$v.form.emp_id"
                                    @input="form.emp_id = $event; $v.form.emp_id.$touch()" )
                      .col-md-6
                        form-input( :value="form.socialSecurityNumber"
                                    name="socialSecurityNumber"
                                    label="Social Security #"
                                    :validation="$v.form.socialSecurityNumber"
                                    @input="form.socialSecurityNumber = $event; $v.form.socialSecurityNumber.$touch()" )
                    .row
                      .col-md-6
                        form-input( :value="form.first_name"
                                    name="first_name"
                                    label="First Name"
                                    :validation="$v.form.first_name"
                                    @input="form.first_name = $event; $v.form.first_name.$touch()" )
                      .col-md
                        form-input( :value="form.last_name"
                                    name="last_name"
                                    label="Last Name"
                                    :validation="$v.form.last_name"
                                    @input="form.last_name = $event; $v.form.last_name.$touch()" )
                    .row
                      .col-md
                        form-input( :value="form.email_address"
                                    name="email_address"
                                    label="Email Address"
                                    :validation="$v.form.email_address"
                                    @input="form.email_address = $event; $v.form.email_address.$touch()" )                      
                      .col-md-6
                        form-input( :value="form.phone"
                                    name="phone"
                                    label="Phone"
                                    :validation="$v.form.phone"
                                    @input="form.phone = $event; $v.form.phone.$touch()" )
                    .row
                      .col-md
                        form-input( :value="form.username"
                                    name="username"
                                    label="Username"
                                    :validation="$v.form.username"
                                    @input="form.username = $event; $v.form.username.$touch()" )                      
                      .col-md
                        form-input( :value="form.password"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    :validation="$v.form.password"
                                    @input="form.password = $event; $v.form.password.$touch()" )                      
                      .col-md
                        form-input( :value="form.password_confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    label="Confirm Password"
                                    :validation="$v.form.password_confirmation"
                                    @input="form.password_confirmation = $event; $v.form.password_confirmation.$touch()" )                   
                      .col-md
                        form-select( :value="form.role"
                                     name="role"
                                     :options="options"
                                     label="User Role"
                                     :validation="$v.form.role"
                                     @input="form.role = $event; $v.form.role.$touch()" )
                    .row
                      .col-md
                        form-input( :value="form.emergencyContactName"
                                    name="emergencyContactName"
                                    label="Emergency Contact Name"
                                    :validation="$v.form.emergencyContactName"
                                    @input="form.emergencyContactName = $event; $v.form.emergencyContactName.$touch()" )                      
                      .col-md-6
                        form-input( :value="form.emergencyContactNumber"
                                    name="emergencyContactNumber"
                                    label="Emergency Contact Number"
                                    :validation="$v.form.emergencyContactNumber"
                                    @input="form.emergencyContactNumber = $event; $v.form.emergencyContactNumber.$touch()" )
                    .row
                      .col-md-6
                        form-date( :value="form.dob"
                                    name="dob"
                                    label="Date of Birth"
                                    :validation="$v.form.dob"
                                    @input="form.dob = $event; $v.form.dob.$touch()" )
                      
                      .col-md
                        form-date( :value="form.doj"
                                    name="doj"
                                    label="Date of Joining"
                                    :validation="$v.form.doj"
                                    @input="form.doj = $event; $v.form.doj.$touch()" ).row
                    .row
                      .col-md-12
                        form-input( :value="form.address"
                                    name="address"
                                    label="Address"
                                    :validation="$v.form.address"
                                    @input="form.address = $event; $v.form.address.$touch()" )
                    .row
                      .col-md-4
                        form-input( :value="form.state"
                                    name="state"
                                    label="State"
                                    :validation="$v.form.state"
                                    @input="form.state = $event; $v.form.state.$touch()" )
                      .col-md
                        form-input( :value="form.city"
                                    name="City"
                                    label="city"
                                    :validation="$v.form.city"
                                    @input="form.city = $event; $v.form.city.$touch()" )
                      .col-md
                        form-input( :value="form.zipcode"
                                    name="zipcode"
                                    label="Zip"
                                    :validation="$v.form.zipcode"
                                    @input="form.zipcode = $event; $v.form.zipcode.$touch()" )
                    .row
                      .col-md
                        form-input( :value="form.wage_rate"
                                    name="wage_rate"
                                    label="Wage Rate"
                                    :validation="$v.form.wage_rate"
                                    @input="form.wage_rate = $event; $v.form.wage_rate.$touch()" )
  
                
                .row
                  .col-md
                    button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
  
                    button.btn.btn-link( @click.prevent="$router.go( -1 )" ) Cancel
                  .col-md
                    div( style="position: absolute; right: 1em; bottom: 0;" )
                      i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                      | are
                      |
                      strong required
                      |
                      | fields
  
</template>
  
  <script>
    import { required, email } from 'vuelidate/lib/validators'
    import FormInput from '../shared/form_input'
    import FormSelect from '../shared/form_select'
    import FormDate from '../shared/form_date'
  
    export default {
      name: 'EmployeeEdit',
  
      components: {
        FormInput,
        FormSelect,
        FormDate
      },
  
      data() {
        return {
          form: null,
          loaded: false,
          exists: false,
          submitting: false,
          options: ['Management','Back Office','Tech','Construction' ]
        }
      },
  
      computed: {
        saveLabel() {
          return this.exists ? 'Save' : 'Create'
        }
      },
  
      validations: {
        form: {
          first_name:       { required },
          last_name:        { required },
          dob:              { required },
          address:          { required },
          emp_id:           { required },
          email_address:    { required, email },
          phone:            { required},
          username:            { required},
          password:            { required},
          password_confirmation:            { required},
          role:            { required},
          state:            { required},
          city:             { required},
          zipcode:            { required},
          emergencyContactName:            { required},
          emergencyContactNumber:            { required},
          socialSecurityNumber:            { required},
          doj:              { required },
          wage_rate:        { required }
        }
      },
  
      mounted() {
        this.fetchData()
      },
  
      methods: {
        fetchData() {
          let url = null
          if ( this.$route.name === 'employee_new' ) {
            this.exists = false
            url = 'employees/new'
          }
          else {
            this.exists = true
            url = 'employees/' + this.$route.params.id
          }
  
          if (this.$route.name !== 'employee_delete'){
            this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data
              this.loaded = true
            })
          }else{
            if(confirm('Are you sure you want to delete employee?')) {
                this.$http.secured.delete( url )
                .then( response => {
                    this.$toastr.success( 'The employee has been deleted successfully!' )                    
                })
            }
            this.$router.push( { name: 'employees' } )
          }
        },
  
        submit() {
          this.submitting = true
          if ( this.exists ) {
            this.$http.secured.patch( 'employees/' + this.$route.params.id, { employee: this.form } )
              .then( ( response ) => {
                this.form = response.data
                this.$toastr.success( 'The Employee has been updated successfully!' )
                this.submitting = false
                this.$scrollToTop()
              }, error => this.handleHttpErrors(error))
          }
          else {
            this.$http.secured.post( 'employees', { employee: this.form }  )
              .then( response => {
                this.$toastr.success( 'The Employee has been created successfully!' )
                this.$router.push( { name: 'employees' } )
              }, error => this.handleHttpErrors(error))
          }
  
        },
        handleHttpErrors(error){
          if(error.response.status == 422) {
            console.log(error.response)
            let error_details = ''
            for (let key in error.response.data.errors) {
                error_details += `${error.response.data.errors[key]}<br/>`
            }
            this.$toastr.error( error_details )
          }
          this.submitting = false
          this.$scrollToTop()
        }
      }
    }
  </script>
  
  <style scoped>
  </style>
  