<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .row.mb-3
      .col
        .float-left
          img( src='~images/kodiak.jpg' width="100" height="100" )
        .float-right( style="width:100px; height:100px" ) &nbsp;
        .text-center
          h1
            u
              strong.text-danger K
              | odiak
              |
              strong.text-danger F
              | ire
              |
              strong.text-danger P
              | rotection, Inc
          h3.mb-0
            | 507 Prestige Cr. Allen, Texas 75002
            br/
            | Phone: (469) 342-6819
            | &#9679;
            | Fax: (800) 335-6449
            br/
            | TXACR-3354
            | &#9679;
            | TXSCR-1751231
            | &#9679;
            | TXECR-166290
            br/
            span.text-danger www.kodiakfiretexas.com

    .row
      .col.text-right
        h2.mb-0
          strong.mr-1 No:
          strong.text-danger {{ form.number }}

    .row.mb-3
      .col.text-center
        h2.mb-0
          strong Service Report

    .row.mb-3
      .col-6
        .row
          .col-4.text-right.text-muted.pr-1 Acct:
          .col-8.pl-0 {{ form.customer.number }}
        .row
          .col-4.text-right.text-muted.pr-1 Fire Alarm Acct #:
          .col-8.pl-0 {{ form.customer.fire_alarm_number }}
        .row
          .col-4.text-right.text-muted.pr-1 Client Name:
          .col-8.pl-0 {{ form.customer.name }}
        .row
          .col-4.text-right.text-muted.pr-1 Client Address:
          .col-8.pl-0 {{ form.customer.address_formatted }}

      .col-6
        .row
          .col-4.text-right.text-muted.pr-1 Date:
          .col-8.pl-0 {{ form.service_date | datetime }}
        .row
          .col-4.text-right.text-muted.pr-1 Phone:
          .col-8.pl-0 {{ form.customer.phone_number }}
        .row
          .col-4.text-right.text-muted.pr-1 Contact:
          .col-8.pl-0 {{ form.contact }}


    .row.mb-3
      .col-12
        .row
          .col
            i.far.mr-1( :class="form.fire_alarm ? 'fa-check-square' : 'fa-square'" )
            | Fire Alarm
          .col
            i.far.mr-1( :class="form.fire_riser_backflow ? 'fa-check-square' : 'fa-square'" )
            | Fire Riser Backflow
          .col
            i.far.mr-1( :class="form.antifreeze_systems ? 'fa-check-square' : 'fa-square'" )
            | Antifreeze System
          .col
            .row
              .col-auto
                i.far.mr-1( :class="form.quote ? 'fa-check-square' : 'fa-square'" )
                | Quote:
              .col-auto.pl-0
                | {{ form.quote_id }}

        .row
          .col
            i.far.mr-1( :class="form.fire_sprinkler ? 'fa-check-square' : 'fa-square'" )
            | Fire Sprinkler
          .col
            i.far.mr-1( :class="form.irrigation_backflow ? 'fa-check-square' : 'fa-square'" )
            | Irrigation Backflow
          .col
            i.far.mr-1( :class="form.warranty ? 'fa-check-square' : 'fa-square'" )
            | Warranty
          .col
            .row
              .col-auto
                i.far.mr-1( :class="form.charge ? 'fa-check-square' : 'fa-square'" )
                | Charge:
              .col-auto.pl-0
                | {{ form.charge_amount }}
        .row
          .col
            i.far.mr-1( :class="form.fire_extinguishers ? 'fa-check-square' : 'fa-square'" )
            | Fire Extinguisher
          .col
            i.far.mr-1( :class="form.domestic_backflow ? 'fa-check-square' : 'fa-square'" )
            | Domestic Backflow
          .col
            i.far.mr-1( :class="form.service_agreement ? 'fa-check-square' : 'fa-square'" )
            | Service Agreement
          .col
            .row
              .col-auto
                  i.far.mr-1( :class="form.other ? 'fa-check-square' : 'fa-square'" )
                  | Other:
              .col-auto.pl-0
                | {{ form.other_details }}


    .row.mb-3
      .col
        table.table.table.table-bordered.table-sm.mb-0
          thead
            tr
              th( width="10%" ) Units
              th Description
              th( width="10%" ) Amount

            tr( v-for="row in form.service_report_items_attributes" )
              td {{ row.units }}
              td {{ row.description }}
              td
                i.fa.fa-dollar-sign
                | {{ row.cost | price }}

    .row.mb-3
      .col
        span.text-muted.mr-1 Service Requested:
        span(v-html="formattedServiceRequested")

    .row.mb-3
      .col
        span.text-muted.mr-1 Service Work Performed:
        span(v-html="formattedServicePerformed")

    .row.mb-3
      .col
        ul.list-inline.my-0.mr-2
          li.list-inline-item
            span.text-muted Service Tag Color:
          li.list-inline-item
            i.far.mr-1( :class="form.white_tag ? 'fa-check-square' : 'fa-square'" )
            | White
          li.list-inline-item
            i.far.mr-1( :class="form.red_tag ? 'fa-check-square' : 'fa-square'" )
            | Red
          li.list-inline-item
            i.far.mr-1( :class="form.yellow_tag ? 'fa-check-square' : 'fa-square'" )
            | Yellow
          li.list-inline-item
            i.far.mr-1( :class="form.no_tag ? 'fa-check-square' : 'fa-square'" )
            | None

      .col.text-right
        ul.list-inline.mb-0
          li.list-inline-item
            i.far.mr-1( :class="form.need_to_return ? 'fa-check-square' : 'fa-square'" )
            | Need to Return

    hr/
    .row
      .col-5
        div( v-if="form.signature_url" )
          h3 Customer Signature:
          img.signature.img-fluid( :src="form.signature_url" )

      .col-3
        .text-muted Assigned Techs:
        template( v-for="t in form.service_report_techs_attributes" )
          | {{ t.tech.name }}
          template( v-if="t.primary" )
            i.fa.fa-asterisk.ml-1.fa-sm
          br/

      .col-4
        table.table.table.table-bordered.table-sm
          tbody
            tr
              td.text-muted.text-right Subtotal:
              td
                i.fa.fa-dollar-sign
                | {{ form.subtotal | price }}
            //tr
              td.text-muted.text-right Tax (8.25%):
              td
                i.fa.fa-dollar-sign
                | {{ form.tax | price }}
            tr
              td.text-muted.text-right Total:
              td
                i.fa.fa-dollar-sign
                | {{ form.total | price }}

    hr/
    .row.mb-4
      .col
        .text-center.mb-1.
          Signature indicated customer's satisfaction with the work performed.
          30-days warranty on service performed.
        .text-center.mb-3.
          There will be a 15% late fee added to all invoices that are 45-days past the invoice date.

    .row.mb-4.bordered
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1



</template>

<script>
  import '../../images/kodiak.jpg'

  export default {
    name: 'ServiceTicketPrint',

    data() {
      return {
        form: null,
        loaded: false
      }
    },

    computed: {
      formattedServicePerformed() {
        if (this.form && this.form.service_performed) {
          return this.form.service_performed.replace(/\n/g, '<br>');
        }
        return '';
      },
      formattedServiceRequested() {
        if (this.form && this.form.service_requested) {
          return this.form.service_requested.replace(/\n/g, '<br>');
        }
        return '';
      },
    },

    mounted() {
      const url = 'customers/' + this.$route.params.id + '/service_reports/' + this.$route.params.service_ticket_id

      console.log( url );

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data
          this.loaded = true
        })
    }

  }
</script>

<style scoped lang="scss">
  .signature {
    border: 1px solid;
    border-radius: 4px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>
