<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fa.fa-calendar-check.mr-2
          template( v-if="exists" )
            | Edit Annual Inspection Ticket {{ form.inspection_number }}
          template( v-else )
            | New Annual Inspection Ticket
      .card-body

        form( novalidate @submit.prevent="submit" )

          .card.mb-3
            .card-body
              .float-right
                span.lead( v-if="exists" ) No: {{ form.inspection_number }}
                span.lead( v-else v-tooltip="'The ID will be filled in after creation'" )
                  | No: A - {{ $moment().format( 'YY' ) }}-
                  i.fa.fa-question-circle.text-muted
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information

              .row.mb-2
                .col-md
                  .text-muted Customer Number
                  | {{ form.customer.number }}
                .col-md
                  .text-muted Customer Name
                  | {{ form.customer.name }}
                .col-md
                  .text-muted Customer Phone
                  | {{ form.customer.phone_number }}
                .col-md
                  .text-muted Fire Account #
                  | {{ form.customer.fire_alarm_number }}
              .row
                .col-md-6
                  .text-muted Customer Address
                  | {{ form.customer.address_formatted }}
                .col-md
                  .text-muted Manager Name
                  | {{ form.customer.manager_name }}
                .col-md
                  .text-muted Manager Email
                  | {{ form.customer.manager_email }}


          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-tools.mr-2
                | Inspection Requested

              .row
                .col-md-3
                  label
                    | Month due
                  select.form-control(v-model="form.month_due")
                    option( v-for="month in monthes" :value="month.id" )
                      | {{ month.name }}
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.bldgs"
                                  name="bldgs"
                                  label="Bldgs"
                                  :validation="$v.form.bldgs"
                                  @input="form.bldgs = $event; $v.form.bldgs.$touch()" )
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.units"
                                  name="units"
                                  label="Units"
                                  :validation="$v.form.units"
                                  @input="form.units = $event; $v.form.units.$touch()" )
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.extinguisher"
                                  name="extinguisher"
                                  label="Extinguisher"
                                  :validation="$v.form.extinguisher"
                                  @input="form.extinguisher = $event; $v.form.extinguisher.$touch()" )
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.fire_hydrants"
                                  name="fire_hydrants"
                                  label="Fire Hydrants"
                                  :validation="$v.form.fire_hydrants"
                                  @input="form.fire_hydrants = $event; $v.form.fire_hydrants.$touch()" )

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-tools.mr-2
                | Inspection to be Performed

              .row
                .col-md
                  form-checkbox( :value="form.fire_alarm"
                                 name="fire_alarm"
                                 label="Fire Alarm"
                                 @input="form.fire_alarm = $event; $v.form.fire_alarm.$touch()" )
                .col-md
                  form-checkbox( :value="form.fire_sprinkler"
                                 name="fire_sprinkler"
                                 label="Fire Sprinkler"
                                 @input="form.fire_sprinkler = $event; $v.form.fire_sprinkler.$touch()" )
                .col-md
                  form-checkbox( :value="form.fire_riser_backflows"
                                 name="fire_riser_backflows"
                                 label="Fire Riser Backflow"
                                 @input="form.fire_riser_backflows = $event; $v.form.fire_riser_backflows.$touch()" )
                .col-md
                  form-checkbox( :value="form.test_tamper_swithes"
                                 name="test_tamper_swithes"
                                 label="Test Tamper Swithes"
                                 @input="form.test_tamper_swithes = $event; $v.form.test_tamper_swithes.$touch()" )

              .row
                .col-md
                  form-checkbox( :value="form.test_waterflow_switches"
                                 name="test_waterflow_switches"
                                 label="Test Waterflow Switches"
                                 @input="form.test_waterflow_switches = $event; $v.form.test_waterflow_switches.$touch()" )
                .col-md
                  form-checkbox( :value="form.test_antifreeze_system"
                                 name="test_antifreeze_system"
                                 label="Test Antifreeze System"
                                 @input="form.test_antifreeze_system = $event; $v.form.test_antifreeze_system.$touch()" )
                .col-md
                  form-checkbox( :value="form.unit_sprinkler_head"
                                 name="unit_sprinkler_head"
                                 label="Unit Sprinkler Head Inspection"
                                 @input="form.unit_sprinkler_head = $event; $v.form.unit_sprinkler_head.$touch()" )
                .col-md
                  form-checkbox( :value="form.fire_extinguisher"
                                 name="fire_extinguisher"
                                 label="Fire Extinguisher"
                                 @input="form.fire_extinguisher = $event; $v.form.fire_extinguisher.$touch()" )
              .row
                .col-md
                  form-checkbox( :value="form.fire_pump"
                                 name="fire_pump"
                                 label="Fire Pump"
                                 @input="form.fire_pump = $event; $v.form.fire_pump.$touch()" )
                .col-md
                  form-checkbox( :value="form.fire_hydrant"
                                 name="fire_hydrant"
                                 label="Fire Hydrant"
                                 @input="form.fire_hydrant = $event; $v.form.fire_hydrant.$touch()" )
                .col-md
                  form-checkbox( :value="form.domestic_backflow"
                                 name="domestic_backflow"
                                 label="Domestic Backflow"
                                 @input="form.domestic_backflow = $event; $v.form.domestic_backflow.$touch()" )
                .col-md
                  form-checkbox( :value="form.pool_backflow"
                                 name="pool_backflow"
                                 label="Pool Backflow"
                                 @input="form.pool_backflow = $event; $v.form.pool_backflow.$touch()" )
              .row
                .col-md
                  form-checkbox( :value="form.irrigation_backflow"
                                 name="irrigation_backflow"
                                 label="Irrigation Backflow"
                                 @input="form.irrigation_backflow = $event; $v.form.irrigation_backflow.$touch()" )
                .col-md
                  form-checkbox( :value="form.hood_suppression_system"
                                 name="hood_suppression_system"
                                 label="Hood Suppression System"
                                 @input="form.hood_suppression_system = $event; $v.form.hood_suppression_system.$touch()" )

          .card.mb-3
            .card-body
              .row
                .col-md-4
                  label
                    | Price for Annual Inspection
                  input.form-control( type="number" :value="form.annual_inspection_price"
                  :disabled="$session.$isTech() && exists"
                  @change="setServiceItemValue( form, $event.target.value )" )

                .col-md-4
                  form-input( :value="form.backflow_inspection_city_fee"
                                 name="backflow_inspection_city_fee"
                                 label="City Fee for Backflow Inspections"
                                 :validation="$v.form.backflow_inspection_city_fee"
                                 @input="form.backflow_inspection_city_fee = $event; $v.form.backflow_inspection_city_fee.$touch()" )
                .col-md-4
                  form-input( :value="form.backflow_number"
                                 name="backflow_number"
                                 label="Backflow Number"
                                 :validation="$v.form.backflow_number"
                                 @input="form.backflow_number = $event; $v.form.backflow_number.$touch()" )

          .row
            .col-md-8
              button.btn.btn-primary.mr-1( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
              button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
              button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                i.fa.fa-file-pdf.mr-1
                | View Report
              button.btn.btn-secondary.mr-5( @click.prevent="sentEmail" v-if="exists" )
                i.far.fa-envelope.mr-1
                | Email Report
              button.btn.btn-danger.mr-5( @click.prevent="deleteInspectionquote" v-if="exists" )
                i.fa.fa-trash.mr-1
                | Delete Report
            .col-md-4
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields

</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormInputWithoutLabel from '../shared/form_input_without_label'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import FormCheckbox from '../shared/form_checkbox'
  import FormDatetime from '../shared/form_datetime'
  import VueSignaturePad from 'vue-signature-pad';

  // import Dinero from 'dinero.js'

  import 'bootstrap/js/dist/modal'

  export default {
    name: 'ServiceTicketEdit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormCheckbox,
      FormInputWithoutLabel,
      FormDatetime,
      VueSignaturePad
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false,
        serviceItemPrototype: null,
        serviceTechPrototype: null,
        techs: [],
        selectedMonth: null,
        signatureRequired: false,
        monthes: [
          {id: "1", name: "January" },
          {id: "2", name: "February" },
          {id: "3", name: "March" },
          {id: "4", name: "April" },
          {id: "5", name: "May" },
          {id: "6", name: "June" },
          {id: "7", name: "July" },
          {id: "8", name: "August" },
          {id: "9", name: "September" },
          {id: "10", name: "October" },
          {id: "11", name: "November" },
          {id: "12", name: "December" },
        ],
      }
    },

    computed: {
      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },

      service_report_items_not_destroyed() {
        return this.loaded ? _.filter( this.form.service_report_items_attributes, ( item ) => { return item._destroy !== '1' } ) : []
      },

      service_report_techs_not_destroyed() {
        return _.filter( this.form.service_report_techs_attributes, ( item ) => { return item._destroy !== '1' } )
      },

      availableTechs() {
        const assignedTechs = _.map( _.map( this.service_report_techs_not_destroyed, ( tech ) => tech.tech_id ),
                                     ( t ) => _.find( this.techs, [ 'id', t ] ) )
        return _.without( this.techs, ...assignedTechs )
      },

    },

    watch: {
      service_report_items_not_destroyed: {
        handler: function() {
          if ( this.service_report_items_not_destroyed.length > 0 ) {
            this.form.subtotal = _.reduce( this.service_report_items_not_destroyed, ( acc, item ) => {
              return item.cost ? acc + ( item.cost * item.units ) : acc
            }, 0 )

            // this.form.tax = Dinero( { amount: this.form.subtotal } ).multiply( 0.0825 ).getAmount()
            this.form.total = this.form.subtotal// + this.form.tax
          }
        },
        deep: true
      }
    },

    validations: {
      form: {
        inspection_number:             {},
        month_due:                     {},
        bldgs:                         {},
        units:                         {},
        extinguisher:                  {},
        fire_alarm:                    {},
        fire_sprinkler:                {},
        fire_riser_backflows:          {},
        test_tamper_swithes:           {},
        test_waterflow_switches:       {},
        test_antifreeze_system:        {},
        unit_sprinkler_head:           {},
        fire_extinguisher:             {},
        fire_hydrants:                 {},
        annual_inspection_price:       {},
        backflow_inspection_city_fee:  {},
        backflow_number:               {},
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = 'customers/' + this.$route.params.id + '/inspection_quotes/'
        if ( this.$route.name === 'inspection_quote_new' ) {
          this.exists = false
          url += 'new'
        }
        else {
          this.exists = true
          url += this.$route.params.inspection_quote_id
        }

        if (this.$route.name !== 'inspection_quote_delete'){
          this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data
              this.loaded = true
            })
        }else{
          this.$http.secured.delete( url )
            .then( response => {
              this.$toastr.success( 'The Annual Inspection quote has been deleted successfully!' )
              this.$router.push( { name: 'inspection_quotes' } )
          })
        }

      },

      submit() {
        this.submitting = true
        let url = 'customers/' + this.$route.params.id  + '/inspection_quotes'
        if ( this.exists ) {
          url += '/' + this.$route.params.inspection_quote_id
          this.$http.secured.patch( url, { inspection_quote: this.form } )
            .then( ( response ) => {
              this.form = response.data
              this.$toastr.success( 'The Inspection Quote has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( url, { inspection_quote: this.form } )
            .then( ( response ) => {
              console.log(response);
              this.$toastr.success( 'The Inspection Quote has been created successfully!' )
              this.$router.push( {
                name: 'inspection_quote_edit',
                params: { id: this.$route.params.id, inspection_quote_id: response.data.id }
              } )
            })
        }
      },
      deleteInspectionquote() {
        let url = 'customers/' + this.$route.params.id + '/inspection_quotes/' + this.$route.params.inspection_quote_id
        this.$http.secured.delete( url )
          .then( response => {
            this.$toastr.success( 'The Annual Inspection quote has been deleted successfully!' )
            this.$router.push( { name: 'inspection_quotes' } )
        })
      },
      downloadPDF() {
        this.$router.push( { name: 'inspection_quote_print', params: { id: this.form.customer_id, inspection_quote_id: this.form.id } } )
      },

      setServiceItemValue( form, price ) {
        form.annual_inspection_price = (Math.round(price * 100) / 100).toFixed(2)
      },
      sentEmail() {
        this.$http.secured
          .post( 'customers/' + this.form.customer_id + '/inspection_quotes/' + this.form.id + '/sent_email', {} )
          .then( ( response ) => {
            this.form = response.data
            this.$toastr.success( 'Inspection Quote Email' + this.form.inspection_number + ' has been set to ' + this.form.customer.manager_email )
          })
      }


    }
  }
</script>

<style scoped lang="scss">
  #signatureDialog .modal-body div, .signature {
    border: 1px solid;
    border-radius: 4px;
  }
</style>
