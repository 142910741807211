<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'construction_new' }" v-if="$session.$isBackOffice()" )
            i.fas.fa-plus.mr-1
            | New Construction
        h4.mb-0
          i.fas.fa-building.mr-2
          | Constructions
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              //- th( @click="changeSort( 'number' )" )
              //-   | Customer Number
              //-   i.fa.fas.ml-2( :class="sortClass( 'number' )" )
              th( @click="changeSort( 'customer_id' )" )
                | Customer Name
                i.fa.fas.ml-2( :class="sortClass( 'customer_id' )" )
              th( @click="changeSort( 'customer_address' )" )
                | Customer Address
                i.fa.fas.ml-2( :class="sortClass( 'customer_address' )" )
              th( @click="changeSort( 'customer_city' )" )
                | Customer City
                i.fa.fas.ml-2( :class="sortClass( 'customer_city' )" )
              th( @click="changeSort( 'pm_name' )" )
                | Project Manager Name
                i.fa.fas.ml-2( :class="sortClass( 'pm_name' )" )
              th( @click="changeSort( 'job_name' )" )
                | Job Name
                i.fa.fas.ml-2( :class="sortClass( 'job_name' )" )
              th( @click="changeSort( 'superintendent_name' )" )
                | Superintendent Name
                i.fa.fas.ml-2( :class="sortClass( 'superintendent_name' )" )
              th.action( width="10%" )
            tr
              //- th
              //-   .input-group
              //-     input.form-control.form-control-sm( :value="filters.number"
              //-                                         @input="debounceNumber"
              //-                                         placeholder="Filter Number" )
              //-     .input-group-append
              //-       button.btn.bg-white.btn-sm( @click="filters.number = ''" )
              //-         i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.customer_name"
                                                      @input="debounceCustomer_name"
                                                      placeholder="Filter Customer Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.customer_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.customer_address"
                                                      @input="debounceCustomer_address"
                                                      placeholder="Filter Customer Address" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.customer_address = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.customer_city"
                                                      @input="debounceCustomer_city"
                                                      placeholder="Filter Customer City" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.customer_city = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.pm_name"
                                                      @input="debouncePM_name"
                                                      placeholder="Filter Project Manager Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.pm_name  = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.job_name"
                                                      @input="debounceJob_name"
                                                      placeholder="Filter Job Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.job_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.superintendent_name"
                                                      @input="debounceSuperintendent_name"
                                                      placeholder="Filter Superintendent Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.superintendent_name = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.number" @click="gotoCustomer( row )" v-if="loaded" )
              td {{ row.customer_name }}
              td {{ row.customer_address }}
              td {{ row.customer_city }}
              td {{ row.pm_name }}
              td {{ row.job_name }}
              td {{ row.superintendent_name }}
              td
                router-link( :to="{ name: 'construction_edit', params: { id: row.id} }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Construction'" )

                router-link( :to="{ name: 'fireinspectionworksheet_new', params: { construction_id: row.id} }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-fire-alt.mr-2( v-tooltip="'New Fire Inspection'" )
                
                router-link( :to="{ name: 'subcontractor_daily_activity_report_new', params: { construction_id: row.id} }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-calendar-check.mr-2( v-tooltip="'New Subcontractor Daily Activity Report'" )
                
                router-link( :to="{ name: 'subcontractor_job_order_new', params: { construction_id: row.id} }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-arrows-alt.mr-2( v-tooltip="'New Subcontractor Job Order'" )

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching construction)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../mixins/table_utils'
  import pagination from '../../mixins/paginate'

  export default {
    name: 'ConstructionsIndex',

    mixins: [ tableUtils ],

    components: { pagination },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,

        filters: {
          customer_name:            '',
          customer_address:              '',
          customer_city:           '',
          pm_name:              '',
          job_name:      '',
          superintendent_name: ''
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Construction'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.sortColumn = 'customer_name'

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        localStorage.customerIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        this.$http.secured.get( 'construction', { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.constructions
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoCustomer( customer ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'construction_edit', params: { id: customer.id } } )
        }
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounceCustomer_name: _.debounce( function( e ) { this.filters.customer_name = e.target.value }, 500 ),
      debounceCustomer_address: _.debounce( function( e ) { this.filters.customer_address = e.target.value }, 500 ),
      debounceCustomer_city: _.debounce( function( e ) { this.filters.customer_city = e.target.value }, 500 ),
      debouncePM_name: _.debounce( function( e ) { this.filters.pm_name = e.target.value }, 500 ),
      debounceJob_name: _.debounce( function( e ) { this.filters.job_name = e.target.value }, 500 ),
      debounceSuperintendent_name: _.debounce( function( e ) { this.filters.superintendent_name = e.target.value }, 500 )
    }
  }
</script>

<style scoped>
</style>
