import Vue from 'vue/dist/vue.esm'
let session = new Vue({
  methods: {
    $hasCurrentUser() {
      return this.$currentUser() !== null
    },

    $currentUser() {
      return JSON.parse( localStorage.getItem( 'kfp-user' ) )
    },

    $setCurrentUser( user ) {
      localStorage.setItem( 'kfp-user', JSON.stringify( user ) )
    },

    $logout() {
      localStorage.removeItem( 'kfp-user' )
    },

    $isTech() {
      return this.$currentUser().role === 'Tech'
    },
    $isConstruction() {
      return this.$currentUser().role === 'Construction'
    },
    
    $is_fire_inspection_worksheet() {
      return this.$currentUser().role === 'Construction'
    },

    $is_subcontractor_daily_activity_report() {
      return this.$currentUser().role === 'Construction'
    },
    $is_subcontractor_job_order() {
      return this.$currentUser().role === 'Construction'
    },
    $isBackOffice() {
      return this.$currentUser().role === 'Back Office' || this.$currentUser().role === 'Management'
    },
    $isManagement() {
      return this.$currentUser().role === 'Management'
    },
    $isEmployee() {
      return this.$currentUser().role === 'Employee'
    }
  }
})

export default {
  install: function(Vue) {
    Vue.prototype.$session = session
  }
}
