<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fa.fa-building.mr-2
          template( v-if="exists" )
            | Edit Customer {{ form.number }}
          template( v-else )
            | New Customer
      .card-body

        .row.mb-3( v-if="exists" )
          .col
            router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'service_ticket_new', params: { id: $route.params.id } }" )
              i.fa.fa-plus.mr-1
              | Service Ticket
            router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'repair_quote_new', params: { id: $route.params.id } }" )
              i.fa.fa-plus.mr-1
              | Repair Quote
            router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'inspection_quote_new', params: { id: $route.params.id } }" )
              i.fa.fa-plus.mr-1
              | Annual Insp Quote
            button.btn.btn-outline-secondary.mr-1( disabled )
              i.fa.fa-plus.mr-1
              | Annual FS Quote
            button.btn.btn-outline-secondary.mr-1( disabled )
              i.fa.fa-plus.mr-1
              | Annual FA Quote
            button.btn.btn-outline-secondary.mr-1( disabled )
              i.fa.fa-plus.mr-1
              | Backflow Report
            input#upload-documents.form-control-file(type="file" multiple @change="handleFileUpload" style="display: none;")
            button.btn.btn-outline-secondary.mr-1(@click.prevent="openFileManager")
              i.fa.fa-plus.mr-1
              | Upload Documents
            button.btn.btn-outline-secondary.mr-1(type="button" @click.prevent="downloadSignedAgreement")( v-if="this.documents.some(doc => doc.name === 'Monitoring_Agreement.docx')" )
              i.fa.fa-plus.mr-1
              | View Monitoring Agreement
            button.btn.btn-outline-secondary.mr-1(type="button" @click.prevent="signAgreement")( v-if="!this.documents.some(doc => doc.name === 'Monitoring_Agreement.docx')" )
              i.fa.fa-plus.mr-1
              | Monitoring Agreement
            button.btn.btn-warning.mr-1( @click.prevent="markFlag('yellow')" v-if="exists" id="set-yellow")
              | {{ form.is_yellow ? "✔" : "&nbsp;" }}
            button.btn.btn-danger.mr-1( @click.prevent="markFlag('red')" v-if="exists" id="set-red")
              | {{ form.is_red ? "✔" : "&nbsp;" }}


        form( novalidate @submit.prevent="submit" )

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Property Information

              .row
                .col-md-6
                  form-input( :value="form.name"
                              name="name"
                              label="Customer Name"
                              :validation="$v.form.name"
                              @input="form.name = $event; $v.form.name.$touch()" )
                .col-md
                  form-input( :value="form.number"
                              name="number"
                              label="Customer Number"
                              :validation="$v.form.number"
                              @input="form.number = $event; $v.form.number.$touch()"
                              hint="The customer number was originally generated as <year>-<street address><building type>. You can change it to be anything you would like."
                              v-if="exists" )
                  .form-group( v-else )
                    label.control-label( for="customer-number" ) Customer Number
                    input#customer-number.form-control-plaintext( type="text" readonly :value="customerNumber" disabled="true" )
                    small.form-text.text-muted.
                      This will be automatically populated based on values filled in on this form when creating a customer.
                      You can change this Customer Number after the Customer is created.
                .col-md
                  .card( v-if="exists" )
                    .card-body.bg-light.p-2
                      table.table-sm
                        tbody
                          tr
                            td.text-right.text-muted.pb-0 Created At:
                            td.pb-0 {{ form.created_at | datetime }}
                          tr
                            td.text-right.text-muted.pb-0 Updated At:
                            td.pb-0 {{ form.updated_at | datetime }}

              .row
                .col-md
                  form-input( :value="form.address"
                              name="address"
                              label="Address"
                              :validation="$v.form.address"
                              @input="form.address = $event; $v.form.address.$touch()" )
                .col-md
                  .row
                    .col-md-6
                      form-input( :value="form.city"
                                  name="city"
                                  label="City"
                                  :validation="$v.form.city"
                                  @input="form.city = $event; $v.form.city.$touch()" )
                    .col-md-2
                      form-input( :value="form.state"
                                  name="state"
                                  label="State"
                                  :validation="$v.form.state"
                                  @input="form.state = $event; $v.form.state.$touch()" )
                    .col-md-4
                      form-input( :value="form.zip"
                                  name="zip"
                                  label="Zip"
                                  :validation="$v.form.zip"
                                  @input="form.zip = $event; $v.form.zip.$touch()" )

              .row
                .col-md
                  form-radio( :value="form.building_type"
                              :options="[ { label: 'Multifamily', value: 'Multifamily'  }, { label: 'Commercial', value: 'Commercial' }, { label: 'Single Family', value: 'Single Family' }, { label: 'Hotel', value: 'Hotel' } ]"
                              name="building_type"
                              label="Building Type"
                              :validation="$v.form.building_type"
                              @input="form.building_type = $event; $v.form.building_type.$touch()" )

                .col-md
                  .row
                    .col-md
                      form-input( :value="form.phone_number"
                                  name="phone_number"
                                  label="Phone Number"
                                  :validation="$v.form.phone_number"
                                  @input="form.phone_number = $event; $v.form.phone_number.$touch()" )
                    .col-md
                      form-input( :value="form.fax_number"
                                  name="fax_number"
                                  label="Fax Number"
                                  :validation="$v.form.fax_number"
                                  @input="form.fax_number = $event; $v.form.fax_number.$touch()" )

              .row
                .col-md
                  form-input( :value="form.manager_name"
                              name="manager_name"
                              label="Manager Name"
                              :validation="$v.form.manager_name"
                              @input="form.manager_name = $event; $v.form.manager_name.$touch()" )
                .col-md
                  form-input( :value="form.manager_email"
                              name="manager_email"
                              label="Manager Email"
                              :validation="$v.form.manager_email"
                              @input="form.manager_email = $event; $v.form.manager_email.$touch()" )


                .col-md
                  form-input( :value="form.maintenance_supervisor"
                              name="maintenance_supervisor"
                              label="Maintenance Supervisor"
                              :validation="$v.form.maintenance_supervisor"
                              @input="form.maintenance_supervisor = $event; $v.form.maintenance_supervisor.$touch()" )
                .col-md
                  form-input( :value="form.maintenance_supervisor_email"
                              name="maintenance_supervisor_email"
                              label="Maintenance Supervisor Email"
                              :validation="$v.form.maintenance_supervisor_email"
                              @input="form.maintenance_supervisor_email = $event; $v.form.maintenance_supervisor_email.$touch()" )

              .row
                .col-md
                  form-input( :value="form.main_facp_type_location"
                              name="main_facp_type_location"
                              label="Main FACP Type Location"
                              :validation="$v.form.main_facp_type_location"
                              @input="form.main_facp_type_location = $event; $v.form.main_facp_type_location.$touch()" )
                .col-md
                  form-input( :value="form.sub_panel_types"
                              name="sub_panel_types"
                              label="Sub Panel Types"
                              :validation="$v.form.sub_panel_types"
                              @input="form.sub_panel_types = $event; $v.form.sub_panel_types.$touch()" )
                .col-md
                  form-input( :value="form.fire_alarm_number"
                              name="fire_alarm_number"
                              label="Fire Alarm Acct #"
                              :validation="$v.form.fire_alarm_number"
                              @input="form.fire_alarm_number = $event; $v.form.fire_alarm_number.$touch()" )
                .col-md
                  form-input( :value="form.security_number"
                              name="security_number"
                              label="Security Acct #"
                              :validation="$v.form.security_number"
                              @input="form.security_number = $event; $v.form.security_number.$touch()" )
              .row
                .col-md
                  form-input( :value="form.management_company"
                              name="management_company"
                              label="Management Company"
                              :validation="$v.form.management_company"
                              @input="form.management_company = $event; $v.form.management_company.$touch()" )
                .col-md
                  form-input( :value="form.gate_code"
                              name="gate_code"
                              label="Gate Code"
                              :validation="$v.form.gate_code"
                              @input="form.gate_code = $event; $v.form.gate_code.$touch()" )
                .col-md-6
                  form-textarea( :value="form.hear_about_us"
                                 name="hear_about_us"
                                 label="How did they hear about us?"
                                 :validation="$v.form.hear_about_us"
                                 rows="3"
                                 @input="form.hear_about_us = $event; $v.form.hear_about_us.$touch()" )

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-fire-extinguisher.mr-2
                | Fire Protection Information

              .row
                .col-md
                  .row
                    .col-md
                      form-input( type="number"
                                  :value="form.number_of_buildings"
                                  name="number_of_buildings"
                                  label="# Buildings"
                                  :validation="$v.form.number_of_buildings"
                                  @input="form.number_of_buildings = $event; $v.form.number_of_buildings.$touch()" )
                    .col-md
                      form-input( type="number"
                                  :value="form.number_of_backflows"
                                  name="number_of_backflows"
                                  label="# Backflows"
                                  :validation="$v.form.number_of_backflows"
                                  @input="form.number_of_backflows = $event; $v.form.number_of_backflows.$touch()" )
                    .col-md
                      form-input( type="number"
                                  :value="form.number_of_units"
                                  name="number_of_units"
                                  label="# Units"
                                  :validation="$v.form.number_of_units"
                                  @input="form.number_of_units = $event; $v.form.number_of_units.$touch()" )
                    .col-md
                      form-input( type="number"
                                  :value="form.number_of_extinguishers"
                                  name="number_of_extinguishers"
                                  label="# Extinguishers"
                                  :validation="$v.form.number_of_extinguishers"
                                  @input="form.number_of_extinguishers = $event; $v.form.number_of_extinguishers.$touch()" )
                    .col-md
                      form-input( type="number"
                                  :value="form.number_of_hydrants"
                                  name="number_of_hydrants"
                                  label="# Hydrants"
                                  :validation="$v.form.number_of_hydrants"
                                  @input="form.number_of_hydrants = $event; $v.form.number_of_hydrants.$touch()" )
                .col-md
                  .row
                    .col-md
                      form-radio( :value="form.wet_pipe_systems"
                                  :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false  } ]"
                                  name="wet_pipe_systems"
                                  label="Wet Pipe Systems?"
                                  :validation="$v.form.wet_pipe_systems"
                                  @input="form.wet_pipe_systems = $event; $v.form.wet_pipe_systems.$touch()" )
                    .col-md
                      form-radio( :value="form.anti_freeze_loops"
                                  :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false  } ]"
                                  name="anti_freeze_loops"
                                  label="Anti Freeze Loops?"
                                  :validation="$v.form.anti_freeze_loops"
                                  @input="form.anti_freeze_loops = $event; $v.form.anti_freeze_loops.$touch()" )
                    .col-md
                      form-radio( :value="form.dry_pipe_systems"
                                  :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false  } ]"
                                  name="dry_pipe_systems"
                                  label="Dry Pipe Systems?"
                                  :validation="$v.form.dry_pipe_systems"
                                  @input="form.dry_pipe_systems = $event; $v.form.dry_pipe_systems.$touch()" )
                    .col-md
                      form-radio( :value="form.fire_pumps"
                                  :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false  } ]"
                                  name="fire_pumps"
                                  label="Fire Pumps?"
                                  :validation="$v.form.fire_pumps"
                                  @input="form.fire_pumps = $event; $v.form.fire_pumps.$touch()" )

              .row
                .col-md
                  form-select( :value="form.alarm_inspection_due"
                               name="alarm_inspection_due"
                               label="Alarm Inspection Due"
                               :validation="$v.form.alarm_inspection_due"
                               :options="$moment.months()"
                               @input="form.alarm_inspection_due = $event; $v.form.alarm_inspection_due.$touch()" )
                .col-md
                  form-select( :value="form.sprinkler_inspection_due"
                               name="sprinkler_inspection_due"
                               label="Sprinkler Inspection Due"
                               :validation="$v.form.sprinkler_inspection_due"
                               :options="$moment.months()"
                               @input="form.sprinkler_inspection_due = $event; $v.form.sprinkler_inspection_due.$touch()" )
                .col-md
                  form-select( :value="form.backflow_inspection_due"
                               name="backflow_inspection_due"
                               label="Backflow Inspection Due"
                               :validation="$v.form.backflow_inspection_due"
                               :options="$moment.months()"
                               @input="form.backflow_inspection_due = $event; $v.form.backflow_inspection_due.$touch()" )
                .col-md
                  form-select( :value="form.extinguisher_inspection_due"
                               name="extinguisher_inspection_due"
                               label="Extinguisher Inspection Due"
                               :validation="$v.form.extinguisher_inspection_due"
                               :options="$moment.months()"
                               @input="form.extinguisher_inspection_due = $event; $v.form.extinguisher_inspection_due.$touch()" )
                .col-md
                  form-select( :value="form.hydrant_inspection_due"
                               name="hydrant_inspection_due"
                               label="Hydrant Inspection Due"
                               :validation="$v.form.hydrant_inspection_due"
                               :options="$moment.months()"
                               @input="form.hydrant_inspection_due = $event; $v.form.hydrant_inspection_due.$touch()" )


          .card.mb-3(ref="monitoringSection")( v-if="this.exists" )
            .card-body
              h5.card-title
                i.fa.fa-file-pdf.mr-2
                | Monitoring Agreement

              .row
                .col-md
                  .row
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.acc_no"
                                  name="monitoring_agreement_acc_no"
                                  label="Acc No #"
                                  :validation="$v.form.monitoring_agreement.acc_no"
                                  @input="form.monitoring_agreement.acc_no = $event; $v.form.monitoring_agreement.acc_no.$touch()"
                                )

                    .col-md
                      form-select( :value="form.monitoring_agreement.agreement_month"
                                  name="monitoring_agreement_month"
                                  label="Agreement Month"
                                  :validation="$v.form.monitoring_agreement.agreement_month"
                                  :options="$moment.months()"
                                  @input="form.monitoring_agreement.agreement_month = $event; $v.form.monitoring_agreement.agreement_month.$touch()"
                                )

                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.subscriber"
                                  name="subscriber"
                                  label="Subscriber"
                                  :validation="$v.form.monitoring_agreement.subscriber"
                                  @input="form.monitoring_agreement.subscriber = $event; $v.form.monitoring_agreement.subscriber.$touch()"
                                )

                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.monitoring_fee"
                                  name="monitoring_fee"
                                  label="Monitoring Fee"
                                  :validation="$v.form.monitoring_agreement.monitoring_fee"
                                  @input="form.monitoring_agreement.monitoring_fee = $event; $v.form.monitoring_agreement.monitoring_fee.$touch()"
                                )

                  .row
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.street"
                                  name="monitoring_agreement_street"
                                  label="Street"
                                  :validation="$v.form.monitoring_agreement.street"
                                  @input="form.monitoring_agreement.street = $event; $v.form.monitoring_agreement.street.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.city"
                                  name="monitoring_agreement_city"
                                  label="City"
                                  :validation="$v.form.monitoring_agreement.city"
                                  @input="form.monitoring_agreement.city = $event; $v.form.monitoring_agreement.city.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.state"
                                  name="monitoring_agreement_state"
                                  label="State"
                                  :validation="$v.form.monitoring_agreement.state"
                                  @input="form.monitoring_agreement.state = $event; $v.form.monitoring_agreement.state.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.zip"
                                  name="monitoring_agreement_zip"
                                  label="Zip"
                                  :validation="$v.form.monitoring_agreement.zip"
                                  @input="form.monitoring_agreement.zip = $event; $v.form.monitoring_agreement.zip.$touch()"
                                )
                  .row
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.name"
                                  name="monitoring_agreement_name"
                                  label="Name"
                                  :validation="$v.form.monitoring_agreement.name"
                                  @input="form.monitoring_agreement.name = $event; $v.form.monitoring_agreement.name.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_name"
                                  name="monitoring_agreement_premise_name"
                                  label="Premise Name"
                                  :validation="$v.form.monitoring_agreement.premise_name"
                                  @input="form.monitoring_agreement.premise_name = $event; $v.form.monitoring_agreement.premise_name.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_manager"
                                  name="monitoring_agreement_premise_manager"
                                  label="Premise Manager"
                                  :validation="$v.form.monitoring_agreement.premise_manager"
                                  @input="form.monitoring_agreement.premise_manager = $event; $v.form.monitoring_agreement.premise_manager.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_phone"
                                  name="monitoring_agreement_premise_phone"
                                  label="Premise Phone"
                                  :validation="$v.form.monitoring_agreement.premise_phone"
                                  @input="form.monitoring_agreement.premise_phone = $event; $v.form.monitoring_agreement.premise_phone.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_city"
                                  name="monitoring_agreement_premise_city"
                                  label="Premise City"
                                  :validation="$v.form.monitoring_agreement.premise_city"
                                  @input="form.monitoring_agreement.premise_city = $event; $v.form.monitoring_agreement.premise_city.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_fax"
                                  name="monitoring_agreement_premise_fax"
                                  label="Premise Fax"
                                  :validation="$v.form.monitoring_agreement.premise_fax"
                                  @input="form.monitoring_agreement.premise_fax = $event; $v.form.monitoring_agreement.premise_fax.$touch()"
                                )
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.premise_email"
                                  name="monitoring_agreement_premise_email"
                                  label="Premise Email"
                                  :validation="$v.form.monitoring_agreement.premise_email"
                                  @input="form.monitoring_agreement.premise_email = $event; $v.form.monitoring_agreement.premise_email.$touch()"
                                )

                  .row
                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.customer_account_number"
                                  name="customer_account_number"
                                  label="Account Number"
                                  :validation="$v.form.monitoring_agreement.customer_account_number"
                                  @input="form.monitoring_agreement.customer_account_number = $event; $v.form.monitoring_agreement.customer_account_number.$touch()"
                                )

                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.customer_account_type"
                                  name="customer_account_type"
                                  label="Account Type"
                                  :validation="$v.form.monitoring_agreement.customer_account_type"
                                  @input="form.monitoring_agreement.customer_account_type = $event; $v.form.monitoring_agreement.customer_account_type.$touch()"
                                )

                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.customer_account_panel"
                                  name="customer_account_panel"
                                  label="Account Panel Type"
                                  :validation="$v.form.monitoring_agreement.customer_account_panel"
                                  @input="form.monitoring_agreement.customer_account_panel = $event; $v.form.monitoring_agreement.customer_account_panel.$touch()"
                                )

                    .col-md
                      form-input(
                                  :value="form.monitoring_agreement.customer_account_pass"
                                  name="customer_account_pass"
                                  label="Fire Alarm Password"
                                  :validation="$v.form.monitoring_agreement.customer_account_pass"
                                  @input="form.monitoring_agreement.customer_account_pass = $event; $v.form.monitoring_agreement.customer_account_pass.$touch()"
                                )

                  .row
                    .col-md
                      h5.card-title
                        | Emergency Contacts

                  .table.table.table-sm.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive.border-none
                    .tr
                      td
                        form-input(
                          :value="form.monitoring_agreement.emergency1name"
                          name="monitoring_agreement_emergency1name"
                          label="Name"
                          :validation="$v.form.monitoring_agreement.emergency1name"
                          @input="form.monitoring_agreement.emergency1name = $event; $v.form.monitoring_agreement.emergency1name.$touch()"
                        )
                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency1position"
                                  name="monitoring_agreement_emergency1position"
                                  label="Position"
                                  :validation="$v.form.monitoring_agreement.emergency1position"
                                  @input="form.monitoring_agreement.emergency1position = $event; $v.form.monitoring_agreement.emergency1position.$touch()"
                                )

                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency1cell"
                                  name="monitoring_agreement_emergency1cell"
                                  label="Phone"
                                  :validation="$v.form.monitoring_agreement.emergency1cell"
                                  @input="form.monitoring_agreement.emergency1cell = $event; $v.form.monitoring_agreement.emergency1cell.$touch()"
                                )
                    .tr
                      td
                        form-input(
                          :value="form.monitoring_agreement.emergency2name"
                          name="monitoring_agreement_emergency2name"
                          :validation="$v.form.monitoring_agreement.emergency2name"
                          @input="form.monitoring_agreement.emergency2name = $event; $v.form.monitoring_agreement.emergency2name.$touch()"
                        )

                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency2position"
                                  name="monitoring_agreement_emergency2position"
                                  :validation="$v.form.monitoring_agreement.emergency2position"
                                  @input="form.monitoring_agreement.emergency2position = $event; $v.form.monitoring_agreement.emergency2position.$touch()"
                                )

                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency2cell"
                                  name="monitoring_agreement_emergency2cell"
                                  :validation="$v.form.monitoring_agreement.emergency2cell"
                                  @input="form.monitoring_agreement.emergency2cell = $event; $v.form.monitoring_agreement.emergency2cell.$touch()"
                                )

                    .tr
                      td
                        form-input(
                          :value="form.monitoring_agreement.emergency3name"
                          name="monitoring_agreement_emergency3name"
                          :validation="$v.form.monitoring_agreement.emergency3name"
                          @input="form.monitoring_agreement.emergency3name = $event; $v.form.monitoring_agreement.emergency3name.$touch()"
                        )

                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency3position"
                                  name="monitoring_agreement_emergency3position"
                                  :validation="$v.form.monitoring_agreement.emergency3position"
                                  @input="form.monitoring_agreement.emergency3position = $event; $v.form.monitoring_agreement.emergency3position.$touch()"
                                )

                      td
                        form-input(
                                  :value="form.monitoring_agreement.emergency3cell"
                                  name="monitoring_agreement_emergency3cell"
                                  :validation="$v.form.monitoring_agreement.emergency3cell"
                                  @input="form.monitoring_agreement.emergency3cell = $event; $v.form.monitoring_agreement.emergency3cell.$touch()"
                                )
                  .row
                    .col-md
                      button.btn.btn-outline-secondary.mr-1.align-items-left(type="button" @click.prevent="downloadSignedAgreement")( v-if="this.documents.some(doc => doc.name === 'Monitoring_Agreement.docx')" )
                        | View Agreement
                      button.btn.btn-outline-secondary.mr-1.align-items-left(type="button" @click.prevent="signAgreement")
                        | Sign Agreement

          .card.mb-3( v-if="this.showViewData()")
            .card-body
              h5.card-title
                i.fa.fa-file-pdf.mr-2
                | View Documents

                .row.mt-3
                  .col-md
                    .row
                      .col-md
                        button.btn.btn-secondary.mr-5.mt-3(type="button" v-for="document in documents.filter(doc => doc.name !== 'Monitoring_Agreement.docx')" :key="document.id" @click="downloadDocument(document)")
                          i.fa.fa-file-pdf.mr-1
                          | {{ document.name }}
          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-file-invoice-dollar.mr-2
                | Billing Information

              .row
                .col-md
                  form-textarea( :value="form.billing_information"
                                 name="billing_information"
                                 label="Billing Information"
                                 :validation="$v.form.billing_information"
                                 rows="6"
                                 @input="form.billing_information = $event; $v.form.billing_information.$touch()" )

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-sticky-note.mr-2
                | Comments

              .row
                .col-md
                  form-textarea( :value="form.comments"
                                 name="comments"
                                 label="Comments"
                                 :validation="$v.form.comments"
                                 rows="6"
                                 @input="form.comments = $event; $v.form.comments.$touch()" )

          .row
            .col-md
              button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

              button.btn.btn-link( @click.prevent="$router.go( -1 )" ) Cancel
              //button.btn.btn-danger.mr-5( @click.prevent="deleteCustomer" v-if="exists" )
                i.fa.fa-trash.mr-1
                | Delete Customer
            .col-md
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields

          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Service Tickets
              service-reports( :original-customer-id="form.id" )
          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Rapair Quotes
              repair-quotes( :original-customer-id="form.id" )
          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Annual Inspection Quotes
              inspection-quotes( :original-customer-id="form.id" )




</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import parseAddress from 'parse-address'
  import ServiceReports from '../service_reports/index'
  import RepairQuotes from '../repair_quotes/index'
  import InspectionQuotes from '../inspection_quotes/index'

  export default {
    name: 'CustomerEdit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      ServiceReports,
      RepairQuotes,
      InspectionQuotes
    },

    data() {
      return {
        form: {
          monitoring_agreement: {
            acc_no:                   '',
            agreement_month:          '',
            subscriber:               '',
            street:                   '',
            state:                    '',
            city:                     '',
            zip:                      '',
            fire_or_security:         '',
            name:                     '',
            premise_name:             '',
            premise_manager:          '',
            premise_phone:            '',
            premise_city:             '',
            premise_fax:              '',
            premise_email:            '',
            emergency1name:           '',
            emergency1position:       '',
            emergency1cell:           '',
            emergency2name:           '',
            emergency2position:       '',
            emergency2cell:           '',
            emergency3name:           '',
            emergency3position:       '',
            emergency3cell:           '',
            monitoring_fee:           '',
            customer_account_number:  '',
            customer_account_type:    '',
            customer_account_pass:    '',
            customer_account_panel:   '',
          }
        },
        loaded: false,
        exists: false,
        submitting: false,
        documents: null,
        systemTypes: ["Fire Alarm", "Security Alarm"],
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        name:                         { required },
        number:                       { required },
        phone_number:                 { required },
        fax_number:                   {},
        address:                      { required },
        city:                         { required },
        state:                        { required },
        zip:                          { required },
        manager_name:                 { required },
        manager_email:                { required, email },
        maintenance_supervisor:       { },
        maintenance_supervisor_email: { email },
        hear_about_us:                {},
        management_company:           {},
        gate_code:                    {},
        main_facp_type_location:      { required },
        sub_panel_types:              { },
        number_of_buildings:          { required },
        number_of_backflows:          { required },
        number_of_units:              { required },
        number_of_extinguishers:      { required },
        number_of_hydrants:           { required },
        wet_pipe_systems:             { required },
        anti_freeze_loops:            { required },
        dry_pipe_systems:             { required },
        fire_pumps:                   { required },
        alarm_inspection_due:         {},
        sprinkler_inspection_due:     {},
        backflow_inspection_due:      {},
        extinguisher_inspection_due:  {},
        hydrant_inspection_due:       {},
        contracted_rate_first:        {},
        contracted_rate_rest:         {},
        billing_information:          {},
        comments:                     {},
        fire_alarm_number:            {},
        security_number:              {},
        building_type:                {},
        monitoring_agreement:         {
          acc_no:                     {},
          agreement_month:            {},
          subscriber:                 {},
          street:                     {},
          city:                       {},
          state:                      {},
          zip:                        {},
          fire_or_security:           {},
          name:                       {},
          premise_name:               {},
          premise_manager:            {},
          premise_phone:              {},
          premise_city:               {},
          premise_fax:                {},
          premise_email:              {},
          emergency1name:             {},
          emergency1position:         {},
          emergency1cell:             {},
          emergency2name:             {},
          emergency2position:         {},
          emergency2cell:             {},
          emergency3name:             {},
          emergency3position:         {},
          emergency3cell:             {},
          monitoring_fee:             {},
          customer_account_number:    {},
          customer_account_type:      {},
          customer_account_pass:      {},
          customer_account_panel:     {},
        }
      }
    },

    mounted() {
      this.fetchData()
      if (this.exists) {
        this.fetchDocuments();
      }
    },

    methods: {
      showViewData() {
        if (this.documents === null ) {
          return false;
        }
        if ( this.documents.length > 0 ) {
          if ( this.documents.length === 1 ) {
            if ( this.documents[0].name === 'Monitoring_Agreement.docx' ) {
              return false;
            } else {
              return true;
            }
          }
          else {
            return true;
          }
        }
      },
      scrollToSection(refName) {
        const section = this.$refs[refName];
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },
      downloadSignedAgreement() {
        this.downloadDocument(this.documents.find(doc => doc.name === 'Monitoring_Agreement.docx'))
      },
      signAgreement() {
        console.log("signing started")
        this.$http.secured.get(`customers/${this.$route.params.id}/sign_monitoring_agreement`)
          .then(response => {
            if ( response.data.message === "Signed successfully" ) {
              this.$toastr.success('Monitoring Agreement signed successfully')
            }
            else if ( response.data.message === "unfilled" ) {
              this.$toastr.error("Please fill and save first the necessary details in the respective section to proceed")
              this.scrollToSection("monitoringSection")
            }
            else {
              this.$toastr.error("Something went wrong please check your Monitoring Agreement Data")
            }
          })
          .catch(error => {
            this.$toastr.error('Failed to sign Monitoring Agreement')
          })
      },
      fetchDocuments() {
        this.$http.secured.get(`customers/${this.$route.params.id}/get_documents`)
          .then(response => {
            this.documents = response.data.docs;
          })
          .catch(error => {
            this.$toastr.error('Failed to fetch documents.');
            console.error('Fetch documents error:', error);
          });
      },
      downloadDocument(doc) {
        console.log(doc)
        const byteCharacters = atob(doc.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: doc.content_type });
        const url = window.URL.createObjectURL(blob);
        if (['image/png', 'image/jpeg', 'application/pdf', 'image/bmp', 'image/tiff', 'image/svg+xml', 'image/webp'].includes(doc.content_type)) {
          window.open(url, '_blank');
          window.URL.revokeObjectURL(url);
          this.$http.secured.post(`customers/${this.$route.params.id}/download_documents`)
            .then(response => {
              this.$toastr.success("File Viewing");
            })
            .catch(error => {
              this.$toastr.error('Something went wrong');
              console.error('documents error:', error);
            });
        } else {
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.$http.secured.post(`customers/${this.$route.params.id}/download_documents`)
            .then(response => {
              this.$toastr.success("File Downloaded");
            })
            .catch(error => {
              this.$toastr.error('Something went wrong');
              console.error('documents error:', error);
            });

        }
      },
      handleFileUpload(event) {
        let uploadedFiles = event.target.files;
        console.log("Files uploaded:", uploadedFiles);
        this.uploadDocuments(uploadedFiles);
      },

      openFileManager() {
        document.getElementById('upload-documents').click();
      },

      uploadDocuments(uploadedFiles) {
        let formData = new FormData();
        Array.from(uploadedFiles).forEach(file => {
          formData.append('documents[]', file);
        });

        this.$http.secured.post(`customers/${this.$route.params.id}/upload_documents`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          console.log(response.data)
          if ( response.data.message === "file not permittable" ) {
            this.$toastr.error('File named Monitoring_Agreement.docx is not permittable to upload manually')
          }
          else if ( response.data.message === "file already present" ) {
            this.$toastr.error('File Already Present. Please upload with a different name')
          }
          else {
            this.$toastr.success('Documents uploaded successfully!');
          }
        }).catch(error => {
          this.$toastr.error('Failed to upload documents.');
        });
      },
      fetchData() {
        let url = null
        if ( this.$route.name === 'customer_new' ) {
          this.exists = false
          url = 'customers/new'
        }
        else {
          this.exists = true
          url = 'customers/' + this.$route.params.id
        }
        if (this.$route.name !== 'customer_delete'){
          this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data
              console.log(response.data)
              this.loaded = true
            })
        }else{
          this.$http.secured.delete( url )
            .then( response => {
              let is_active = this.$route.params.active == true ? "disable" : "enable"
              this.$toastr.success( 'The Customer has been '+ is_active +' successfully!' )
              this.$router.push( { name: 'customers' } )
          })
        }

      },

      submit() {
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( 'customers/' + this.$route.params.id, { customer: this.form } )
            .then( ( response ) => {
              this.form = response.data
              this.$toastr.success( 'The Customer has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'customers', { customer: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The Customer has been created successfully!' )
              this.$router.push( { name: 'customer_edit', params: { id: response.data.id } } )
            })
        }

      },
      deleteCustomer() {
        let url = 'customers/' + this.$route.params.id
        this.$http.secured.delete( url )
          .then( response => {
            this.$toastr.success( 'The Customer has been deleted successfully!' )
            this.$router.push( { name: 'customers' } )
        })
      },
      markFlag(type) {
        let msg = null
        if(type == 'red')
          msg = 'flag account on hold'

        if(type == 'yellow')
          msg = 'account flag due invoices'

        this.$http.secured.post( 'customers/' + this.$route.params.id+"/set_flag", { flag: type } )
        //this.$http.secured.delete( url )
          .then( response => {
            this.$toastr.success( 'The Customer '+msg+' successfully!' )
            this.$router.push( { name: 'customer_edit', params: { id: this.$route.params.id } } )
            this.$router.go()
        })
      },
    }
  }
</script>

<style scoped>
  .border-none {
    border: none;
  }

  .border-none td {
    border: none;
  }
</style>
