<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .main-body
      .row.mb-3
        .col
          .float-left
            img( src='~images/kodiak.jpg' width="100" height="100" )
          .float-right( style="width:100px; height:100px" ) &nbsp;
          .text-center
            h1
              u
                strong.text-danger K
                | odiak
                |
                strong.text-danger F
                | ire
                |
                strong.text-danger P
                | rotection, Inc
            h3.mb-0
              | 507 Prestige Cr. Allen, Texas 75002
              br/
              | Phone: (469) 342-6819
              | &#9679;
              | Fax: (800) 335-6449
              br/
              | TXACR-3354
              | &#9679;
              | TXSCR-1751231
              | &#9679;
              | TXECR-166290
              br/
              span.text-danger www.kodiakfiretexas.com
      .row.mb-3
        .col.text-center
          h2.mb-0
            strong subcontractor Daily Activity Report
      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Customer Name:
            .col-6.pl-0 {{form.constructiondetails.customer_name}}
          .row
            .col-6.text-right.text-muted.pr-1 Customer Address:
            .col-6.pl-0 {{form.constructiondetails.customer_address}}
          .row
            .col-6.text-right.text-muted.pr-1 City:
            .col-6.pl-0 {{form.constructiondetails.customer_city}}
          .row
            .col-6.text-right.text-muted.pr-1 State:
            .col-6.pl-0 {{form.constructiondetails.customer_state}}
          .row
            .col-6.text-right.text-muted.pr-1 Zip:
            .col-6.pl-0 {{form.constructiondetails.customer_zip}}
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Project Number:
            .col-6.pl-0 {{ form.city }}
          .row
            .col-6.text-right.text-muted.pr-1 Email:
            .col-6.pl-0 {{form.constructiondetails.project_email}}
          .row
            .col-6.text-right.text-muted.pr-1 Phone:
            .col-6.pl-0 {{form.constructiondetails.project_phone}}

      .row.mb-3
        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Subcontractor Name:
            .col-6.pl-0 {{ form.sub_contractor_name }}
          .row
            .col-6.text-right.text-muted.pr-1 Start Date:
            .col-6.pl-0 {{ form.start_date | datetime }}
          .row
            .col-6.text-right.text-muted.pr-1 End Date:
            .col-6.pl-0 {{ form.end_date | datetime }}

        .col-6
          .row
            .col-6.text-right.text-muted.pr-1 Subcontractor Signature:
            .col-6.pl-0 {{ form.sub_contractor_signature }}
          .row
            .col-6.text-right.text-muted.pr-1 Approval Date:
            .col-6.pl-0 {{ form.approved_by | datetime }}
      .row
        .col-md
          table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
            thead.bg-secondary.text-white
              tr
                th Arrival Date
                th Departure Date
                th Hours
                th Description of Work
            tbody
              tr.pointer( v-for="row in form_items" :key="row.id" v-if="loaded" )
                td {{row.report_item_arrival_date | datetime}}
                td {{row.report_item_departure_date | datetime}}
                td {{row.report_item_hours}}
                td {{row.description_of_work}}
              tr(v-if="form_items.length==0")
                td(:colspan="3") No Records 
    .row.mb-4.bordered.footer
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1
</template>

<script>
  import '../../../images/kodiak.jpg'

  export default {
    name: 'fireInspectionPrint',

    data() {
      return {
        form: null,
        form_items:[],
        loaded: false
      }
    },

    mounted() {
      const url = 'construction/' + this.$route.params.construction_id + '/subcontractor/daily_activity_report/' + this.$route.params.id

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data.daily_report
          this.form_items = response.data.daily_report_items
          this.form.constructiondetails = response.data.constructiondetails
          this.loaded = true
        })
    }

  }
</script>

<style scoped lang="scss">
.footer{
  bottom: 20px;
}
.main-body{
  min-height: 600px;;
}
  .signature {
    border: 1px solid;
    border-radius: 4px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>