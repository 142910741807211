<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'customer_new' }" v-if="$session.$isBackOffice()" )
            i.fas.fa-plus.mr-1
            | New Customer
          button.btn.btn-outline-light.btn-sm.ml-1(@click.prevent="inActiveCustomer")
            | {{ this.active ? "Inactive Customers" : "Active Customers" }}
          button.btn.btn-warning.btn-sm.ml-1(@click.prevent="FlagYellow()", id="btn-yellow")
            | Past Due Invoices
          button.btn.btn-danger.btn-sm.ml-1(@click.prevent="FlagRed()", id="btn-red"  :class="isActive ? 'active' : ''")
            | Account on Hold
        h4.mb-0
          i.fa.fa-building.mr-2
          | Customers
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              th( @click="changeSort( 'number' )" )
                | Customer Number
                i.fa.fas.ml-2( :class="sortClass( 'number' )" )
              th( @click="changeSort( 'name' )" )
                | Customer Name
                i.fa.fas.ml-2( :class="sortClass( 'name' )" )
              th( @click="changeSort( 'address' )" )
                | Street Address
                i.fa.fas.ml-2( :class="sortClass( 'address' )" )
              th( @click="changeSort( 'city' )" )
                | City
                i.fa.fas.ml-2( :class="sortClass( 'city' )" )
              th( @click="changeSort( 'manager_name' )" )
                | Manager Name
                i.fa.fas.ml-2( :class="sortClass( 'manager_name' )" )
              th( @click="changeSort( 'fire_alarm_number' )" )
                | Fire Alarm Acct #
                i.fa.fas.ml-2( :class="sortClass( 'fire_alarm_number' )" )
              th( @click="changeSort( 'management_company' )" )
                | Management Company
                i.fa.fas.ml-2( :class="sortClass( 'management_company' )" )
              th.action( width="10%" )
            tr
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.number"
                                                      @input="debounceNumber"
                                                      placeholder="Filter Number" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.number = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.name"
                                                      @input="debounceName"
                                                      placeholder="Filter Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.address"
                                                      @input="debounceAddress"
                                                      placeholder="Filter Address" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.address = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.city"
                                                      @input="debounceCity"
                                                      placeholder="Filter City" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.city = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.manager_name"
                                                      @input="debounceManagerName"
                                                      placeholder="Filter Manager Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.manager_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.fire_alarm_number"
                                                      @input="debounceFireAlarmNumber"
                                                      placeholder="Filter Fire Alarm Acct #" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.fire_alarm_number = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.management_company"
                                                      @input="debounceManagementCompany"
                                                      placeholder="Filter Management Company" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.management_company = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.number" @click="gotoCustomer( row )" v-if="loaded" )
              td {{ row.number }}
              td {{ row.name }}
              td {{ row.address }}
              td {{ row.city }}
              td {{ row.manager_name }}
              td {{ row.fire_alarm_number }}
              td {{ row.management_company }}
              td
                router-link( :to="{ name: 'customer_edit', params: { id: row.id } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fa.fa-pencil-alt.mr-2( v-tooltip="'Edit Customer'" )
                router-link( :to="{ name: 'service_ticket_new', params: { id: row.id } }"
                             @click.native="$event.stopImmediatePropagation()" )
                  i.fa.fa-calendar-check.mr-2( v-tooltip="'Create Service Ticket'" )
                router-link( :to="{ name: 'customer_delete', params: { id: row.id, active: row.is_active } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fa.fa-lock.mr-2( v-tooltip="'Disable Customer'" v-if="row.is_active")
                  i.fa.fa-unlock-alt.mr-2( v-tooltip="'Enable Customer'" v-if="!row.is_active")

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching customers)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../mixins/table_utils'
  import pagination from '../../mixins/paginate'

  export default {
    name: 'CustomersIndex',

    mixins: [ tableUtils ],

    components: { pagination },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,
        active: true,
        is_yellow: false,
        is_red:false,

        filters: {
          number:            '',
          name:              '',
          address:           '',
          city:              '',
          manager_name:      '',
          fire_alarm_number: '',
          management_company: '',
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Customers'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.active = localStorage.customerIndexActive
      this.active = this.active === "true"
      this.sortColumn = 'name'
      this.$watch( () => { return this.active }, () => this.fetchData() )
      this.$watch( () => { return this.is_yellow }, () => this.fetchData() )
      this.$watch( () => { return this.is_red }, () => this.fetchData() )
      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        localStorage.customerIndexPerPage = this.perPage
        localStorage.customerIndexActive = this.active
        this.loaded = false
        this.loading = true

        this.$http.secured.get( 'customers', { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            active:         this.active,
            is_yellow:      this.is_yellow,
            is_red:         this.is_red,
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.customers
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoCustomer( customer ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'customer_edit', params: { id: customer.id } } )
        }
      },

      removeCustomer( row ) {
        let uri = 'customers/' + row.id
        this.$http.secured.delete(uri)
          .then(response => {
            this.data.splice(this.data.indexOf(row), 1)
        })
        .catch(error => this.setError(error, 'Cannot delete artist'))
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      inActiveCustomer() {
        this.active = !this.active
      },

      FlagYellow() {
        this.is_yellow = !this.is_yellow
        this.is_red = false
      },
      FlagRed()
      {
        this.is_red = !this.is_red
        this.is_yellow = false
      },

      debounceNumber: _.debounce( function( e ) { this.filters.number = e.target.value }, 500 ),
      debounceName: _.debounce( function( e ) { this.filters.name = e.target.value }, 500 ),
      debounceAddress: _.debounce( function( e ) { this.filters.address = e.target.value }, 500 ),
      debounceCity: _.debounce( function( e ) { this.filters.city = e.target.value }, 500 ),
      debounceManagerName: _.debounce( function( e ) { this.filters.manager_name = e.target.value }, 500 ),
      debounceFireAlarmNumber: _.debounce( function( e ) { this.filters.fire_alarm_number = e.target.value }, 500 ),
      debounceManagementCompany: _.debounce( function( e ) { this.filters.management_company = e.target.value }, 500 ),
    }
  }
</script>

<style scoped>
</style>
