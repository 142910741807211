<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fas.fa-building.mr-2
          template( v-if="exists" )
            | Edit Construction {{ form.number }}
          template( v-else )
            | New Construction
      .card-body
        .row.mb-3( v-if="exists" )
            .col
              router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'fireinspectionworksheet_new', params: { construction_id: $route.params.id } }" )
                i.fa.fa-plus.mr-1
                | Fire Inspection Worksheet
              router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'subcontractor_daily_activity_report_new', params: { construction_id: $route.params.id } }" )
                i.fa.fa-plus.mr-1
                | Subcontractor Daily Activity Report
              router-link.btn.btn-outline-secondary.mr-1( :to="{ name: 'subcontractor_job_order_new', params: { construction_id: $route.params.id } }" )
                i.fa.fa-plus.mr-1
                | Subcontractor Job Order
        form( novalidate @submit.prevent="submit" )
          .row
            .col-md-6
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-address-book.mr-2
                    | Customer Information
                  .row
                    .col-md-6
                      form-input(:value="form.customer_name"
                                  name="customer_name"
                                  label="Customer Name"
                                  :validation="$v.form.customer_name"
                                  @input="form.customer_name = $event; $v.form.customer_name.$touch()" )
                    .col-md-12
                      form-input( :value="form.customer_address"
                                name="customer_address"
                                label="Address"
                                :validation="$v.form.customer_address"
                                @input="form.customer_address = $event; $v.form.customer_address.$touch()" )
                    .col-md-12
                      .row
                        .col-md
                          form-input( :value="form.customer_city"
                                      name="customer_city"
                                      label="City"
                                      :validation="$v.form.customer_city"
                                      @input="form.customer_city = $event; $v.form.customer_city.$touch()" )
                        .col-md
                          form-input( :value="form.customer_state"
                                      name="customer_state"
                                      label="State"
                                      :validation="$v.form.customer_state"
                                      @input="form.customer_state = $event; $v.form.customer_state.$touch()" )
                        .col-md
                          form-input( :value="form.customer_zip"
                                      name="customer_zip"
                                      label="Zip"
                                      :validation="$v.form.customer_zip"
                                      @input="form.customer_zip = $event; $v.form.customer_zip.$touch()" )
            .col-md-6
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-address-book.mr-2
                    | Project Information
                  .row
                    .col-md-6
                      form-input( :value="projectNumber"
                                  name="project_number"
                                  label="Project Number"
                                  :validation="$v.form.project_number"
                                  @input="form.project_number = $event; $v.form.project_number.$touch()")
                  .row
                    .col-md-6
                      form-input( :value="form.pm_name"
                                  name="pm_name"
                                  label="Project Manager Name"
                                  :validation="$v.form.pm_name"
                                  @input="form.pm_name = $event; $v.form.pm_name.$touch()" )
                    .col-md-6
                      form-input( :value="form.project_email"
                                  name="project_email"
                                  label="Email"
                                  :validation="$v.form.project_email"
                                  @input="form.project_email = $event; $v.form.project_email.$touch()")
                    .col-md-6
                      form-input( :value="form.project_phone"
                                  name="project_phone"
                                  label="Phone"
                                  :validation="$v.form.project_phone"
                                  @input="form.project_phone = $event; $v.form.project_phone.$touch()")
                    //- .col-md-12
                    //-   form-input( :value="form.pm_address"
                    //-             name="pm_address"
                    //-             label="Address"
                    //-             :validation="$v.form.pm_address"
                    //-             @input="form.pm_address = $event; $v.form.pm_address.$touch()" )
                    //- .col-md-12
                    //-   .row
                    //-     .col-md
                    //-       form-input( :value="form.pm_city"
                    //-                   name="pm_city"
                    //-                   label="City"
                    //-                   :validation="$v.form.pm_city"
                    //-                   @input="form.pm_city = $event; $v.form.pm_city.$touch()" )
                    //-     .col-md
                    //-       form-input( :value="form.pm_state"
                    //-                   name="pm_state"
                    //-                   label="State"
                    //-                   :validation="$v.form.pm_state"
                    //-                   @input="form.pm_state = $event; $v.form.pm_state.$touch()" )
                    //-     .col-md
                    //-       form-input( :value="form.pm_zip"
                    //-                   name="pm_zip"
                    //-                   label="Zip"
                    //-                   :validation="$v.form.pm_zip"
                    //-                   @input="form.pm_zip = $event; $v.form.pm_zip.$touch()" )
         
            .col-md-6
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-address-book.mr-2
                    | Job Information
                  .row
                    .col-md-6
                      form-input( :value="form.job_name"
                                  name="job_name"
                                  label="Job Name"
                                  :validation="$v.form.job_name"
                                  @input="form.job_name = $event; $v.form.job_name.$touch()" )
                    .col-md-12
                      form-input( :value="form.job_address"
                                name="job_address"
                                label="Address"
                                :validation="$v.form.job_address"
                                @input="form.job_address = $event; $v.form.job_address.$touch()" )
                    .col-md-12
                      .row
                        .col-md
                          form-input( :value="form.job_city"
                                      name="job_city"
                                      label="City"
                                      :validation="$v.form.job_city"
                                      @input="form.job_city = $event; $v.form.job_city.$touch()" )
                        .col-md
                          form-input( :value="form.job_state"
                                      name="job_state"
                                      label="State"
                                      :validation="$v.form.job_state"
                                      @input="form.job_state = $event; $v.form.job_state.$touch()" )
                        .col-md
                          form-input( :value="form.job_zip"
                                      name="job_zip"
                                      label="Zip"
                                      :validation="$v.form.job_zip"
                                      @input="form.job_zip = $event; $v.form.job_zip.$touch()" )
            .col-md-6
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-address-book.mr-2
                    | Superintendent Information
                  .row
                    .col-md-12
                      form-input( :value="form.superintendent_name"
                                  name="superintendent_name"
                                  label="Superintendent"
                                  :validation="$v.form.superintendent_name"
                                  @input="form.superintendent_name = $event; $v.form.superintendent_name.$touch()" )
                    .col-md
                      form-input( :value="form.superintendent_email"
                                name="superintendent_email"
                                label="Email"
                                :validation="$v.form.superintendent_email"
                                @input="form.superintendent_email = $event; $v.form.superintendent_email.$touch()" )
                    .col-md
                      form-input( :value="form.superintendent_phone"
                                  name="superintendent_phone"
                                  label="Phone"
                                  :validation="$v.form.superintendent_phone"
                                  @input="form.superintendent_phone = $event; $v.form.superintendent_phone.$touch()" )
              .col-md
                form-radio( :value="form.building_type"
                            :options="[ { label: 'Multifamily', value: 'Multifamily'  }, { label: 'Commercial', value: 'Commercial' }, { label: 'Single Family', value: 'Single Family' }, { label: 'Hotel', value: 'Hotel' } ]"
                            name="building_type"
                            label="Building Type"
                            :validation="$v.form.building_type"
                            @input="form.building_type = $event; $v.form.building_type.$touch()" )
            .col-md-12
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-fire-extinguisher.mr-2
                    | Fire Protection Information

                  .row
                    .col-md
                      .row
                        .col-md
                          form-input( type="number"
                                      :value="form.number_of_buildings"
                                      name="number_of_buildings"
                                      label="# Buildings"
                                      :validation="$v.form.number_of_buildings"
                                      @input="form.number_of_buildings = $event; $v.form.number_of_buildings.$touch()" )
                        .col-md
                          form-input( type="number"
                                      :value="form.number_of_backflows"
                                      name="number_of_backflows"
                                      label="# Backflows"
                                      :validation="$v.form.number_of_backflows"
                                      @input="form.number_of_backflows = $event; $v.form.number_of_backflows.$touch()" )
                        .col-md
                          form-input( type="number"
                                      :value="form.number_of_units"
                                      name="number_of_units"
                                      label="# Units"
                                      :validation="$v.form.number_of_units"
                                      @input="form.number_of_units = $event; $v.form.number_of_units.$touch()" )
                        .col-md
                          form-input( type="number"
                                      :value="form.number_of_extinguishers"
                                      name="number_of_extinguishers"
                                      label="# Extinguishers"
                                      :validation="$v.form.number_of_extinguishers"
                                      @input="form.number_of_extinguishers = $event; $v.form.number_of_extinguishers.$touch()" )
                    .col-md
                      .row
                        .col-md
                          form-radio( :value="form.wet_pipe_systems"
                                      :options="[ { label: 'Yes', value: 'true'  }, { label: 'No', value: 'false'  } ]"
                                      name="wet_pipe_systems"
                                      label="Wet Pipe Systems?"
                                      :validation="$v.form.wet_pipe_systems"
                                      @input="form.wet_pipe_systems = $event; $v.form.wet_pipe_systems.$touch()" )
                        .col-md
                          form-radio( :value="form.anti_freeze_loops"
                                      :options="[ { label: 'Yes', value: 'true'  }, { label: 'No', value: 'false'  } ]"
                                      name="anti_freeze_loops"
                                      label="Anti Freeze Loops?"
                                      :validation="$v.form.anti_freeze_loops"
                                      @input="form.anti_freeze_loops = $event; $v.form.anti_freeze_loops.$touch()" )
                        .col-md
                          form-radio( :value="form.dry_pipe_systems"
                                      :options="[ { label: 'Yes', value: 'true'  }, { label: 'No', value: 'false'  } ]"
                                      name="dry_pipe_systems"
                                      label="Dry Pipe Systems?"
                                      :validation="$v.form.dry_pipe_systems"
                                      @input="form.dry_pipe_systems = $event; $v.form.dry_pipe_systems.$touch()" )
                        .col-md
                          form-radio( :value="form.fire_pumps"
                                      :options="[ { label: 'Yes', value: 'true'  }, { label: 'No', value: 'false'  } ]"
                                      name="fire_pumps"
                                      label="Fire Pumps?"
                                      :validation="$v.form.fire_pumps"
                                      @input="form.fire_pumps = $event; $v.form.fire_pumps.$touch()" )

              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-fire-extinguisher1.mr-2
                    | Accounting Department
                  .row
                    .col-md-2
                      form-checkbox( :value="form.acd_signed_contract"
                                    name="acd_signed_contract"
                                    label="Signed Contract"
                                    @input="form.acd_signed_contract = $event; $v.form.acd_signed_contract.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.acd_signed_contract_date"
                                      name="acd_signed_contract_date"
                                      :validation="$v.form.acd_signed_contract_date"
                                      @input="form.acd_signed_contract_date = $event; $v.form.acd_signed_contract_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.acd_insurance_certificate"
                                    name="acd_insurance_certificate"
                                    label="Insurance Certificate"
                                    @input="form.acd_Insurance_Certificate = $event; $v.form.acd_Insurance_Certificate.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.acd_insurance_certificate_date"
                                        name="acd_insurance_certificate_date"
                                        :validation="$v.form.acd_insurance_certificate_date"
                                        @input="form.acd_insurance_certificate_date = $event; $v.form.acd_insurance_certificate_date.$touch()" )

                    .col-md-2
                      form-checkbox( :value="form.acd_entered_into_quickbooks"
                                    name="acd_entered_into_quickbooks"
                                    label="Entered Into Quickbooks"
                                    @input="form.acd_entered_into_quickbooks = $event; $v.form.acd_entered_into_quickbooks.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.acd_entered_into_quickbooks_date"
                                      name="acd_entered_into_quickbooks_date"
                                      :validation="$v.form.acd_entered_into_quickbooks_date"
                                      @input="form.acd_entered_into_quickbooks_date = $event; $v.form.acd_entered_into_quickbooks_date.$touch()" )
                                      
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-fire-extinguisher1.mr-2
                    | Construction Department
                  .row
                    .col-md-2
                      form-checkbox( :value="form.cd_draw_schedule"
                                    name="cd_draw_schedule"
                                    label="Draw Schedule"
                                    @input="form.cd_draw_schedule = $event; $v.form.cd_draw_schedule.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.cd_draw_schedule_date"
                                      name="cd_draw_schedule_date"
                                      :validation="$v.form.cd_draw_schedule_date"
                                      @input="form.cd_draw_schedule_date = $event; $v.form.cd_draw_schedule_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.cd_mobilization_draw_turned_on"
                                    name="cd_mobilization_draw_turned_on"
                                    label="Mobilization Draw Turned On"
                                    @input="form.acd_mobilization_draw_turned_on = $event; $v.form.acd_mobilization_draw_turned_on.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.cd_mobilization_draw_turned_on_date"
                                      name="cd_mobilization_draw_turned_on_date"
                                      :validation="$v.form.cd_mobilization_draw_turned_on_date"
                                      @input="form.cd_mobilization_draw_turned_on_date = $event; $v.form.cd_mobilization_draw_turned_on_date.$touch()" )
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-fire-extinguisher1.mr-2
                    | Fire Alarm
                  .row
                    .col-md-2
                      form-checkbox( :value="form.fa_cad_pdf_files_requested"
                                    name="fa_cad_pdf_files_requested"
                                    label="CAD/PDF Files Requested"
                                    @input="form.fa_cad_pdf_files_requested = $event; $v.form.fa_cad_pdf_files_requested.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_cad_pdf_files_requested_date"
                                      name="fa_cad_pdf_files_requested_date"
                                      :validation="$v.form.fa_cad_pdf_files_requested_date"
                                      @input="form.fa_cad_pdf_files_requested_date = $event; $v.form.fa_cad_pdf_files_requested_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_job_information_form_sent_to_supplier"
                                    name="fa_job_information_form_sent_to_supplier"
                                    label="Job Information Form Sent To Supplier"
                                    @input="form.fa_job_information_form_sent_to_supplier = $event; $v.form.fa_job_information_form_sent_to_supplier.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_job_information_form_sent_to_supplier_date"
                                      name="fa_job_information_form_sent_to_supplier_date"
                                      :validation="$v.form.fa_job_information_form_sent_to_supplier_date"
                                      @input="form.fa_job_information_form_sent_to_supplier_date = $event; $v.form.fa_job_information_form_sent_to_supplier_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_files_sent_to_designer"
                                    name="fa_files_sent_to_designer"
                                    label="Files Sent to Designer"
                                    @input="form.fa_files_sent_to_designer = $event; $v.form.fa_files_sent_to_designer.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_files_sent_to_designer_date"
                                      name="fa_files_sent_to_designer_date"
                                      :validation="$v.form.fa_files_sent_to_designer_date"
                                      @input="form.fa_files_sent_to_designer_date = $event; $v.form.fa_files_sent_to_designer_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_material_quote_received"
                                    name="fa_material_quote_received"
                                    label="Material Quote Received"
                                    @input="form.fa_material_quote_received = $event; $v.form.fa_material_quote_received.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_material_quote_received_date"
                                      name="fa_material_quote_received_date"
                                      :validation="$v.form.fa_material_quote_received_date"
                                      @input="form.fa_material_quote_received_date = $event; $v.form.fa_material_quote_received_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_design_received_from_designer"
                                    name="fa_design_received_from_designer"
                                    label="Design Received from Designer"
                                    @input="form.fa_design_received_from_designer = $event; $v.form.fa_design_received_from_designer.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_design_received_from_designer_date"
                                      name="fa_design_received_from_designer_date"
                                      :validation="$v.form.fa_design_received_from_designer_date"
                                      @input="form.fa_design_received_from_designer_date = $event; $v.form.fa_design_received_from_designer_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_Others"
                                    name="fa_Others"
                                    label="Others"
                                    @input="form.fa_Others = $event; $v.form.fa_Others.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_Others_date"
                                      name="fa_Others_date"
                                      :validation="$v.form.fa_Others_date"
                                      @input="form.fa_Others_date = $event; $v.form.fa_Others_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_date_submitted_to_fm"
                                    name="fa_date_submitted_to_fm"
                                    label="Date Submitted to FM"
                                    @input="form.fa_date_submitted_to_fm = $event; $v.form.fa_date_submitted_to_fm.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_date_submitted_to_fm_date"
                                      name="fa_date_submitted_to_fm_date"
                                      :validation="$v.form.fa_date_submitted_to_fm_date"
                                      @input="form.fa_date_submitted_to_fm_date = $event; $v.form.fa_date_submitted_to_fm_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fa_date_resubmitted_to_fm"
                                    name="fa_date_resubmitted_to_fm"
                                    label="Date Resubmitted to FM"
                                    @input="form.fa_date_resubmitted_to_fm = $event; $v.form.fa_date_resubmitted_to_fm.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fa_date_resubmitted_to_fm_date"
                                      name="fa_date_resubmitted_to_fm_date"
                                      :validation="$v.form.fa_date_resubmitted_to_fm_date"
                                      @input="form.fa_date_resubmitted_to_fm_date = $event; $v.form.fa_date_resubmitted_to_fm_date.$touch()" )
              .card.mb-3
                .card-body
                  h5.card-title
                    i.fas.fa-fire-extinguisher1.mr-2
                    | Fire Sprinkler
                  .row
                    .col-md-2
                      form-checkbox( :value="form.fs_cad_pdf_files_requested"
                                    name="fs_cad_pdf_files_requested"
                                    label="CAD/PDF Files Requested"
                                    @input="form.fs_cad_pdf_files_requested = $event; $v.form.fs_cad_pdf_files_requested.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_cad_pdf_files_requested_date"
                                      name="fs_cad_pdf_files_requested_date"
                                      :validation="$v.form.fs_cad_pdf_files_requested_date"
                                      @input="form.fs_cad_pdf_files_requested_date = $event; $v.form.fs_cad_pdf_files_requested_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_date_submitted_to_fm"
                                    name="fs_date_submitted_to_fm"
                                    label="Date Submitted to FM"
                                    @input="form.fs_date_submitted_to_fm = $event; $v.form.fs_date_submitted_to_fm.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_date_submitted_to_fm_date"
                                      name="fs_date_submitted_to_fm_date"
                                      :validation="$v.form.fs_date_submitted_to_fm_date"
                                      @input="form.fs_date_submitted_to_fm_date = $event; $v.form.fs_date_submitted_to_fm_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_date_resubmitted_to_fm"
                                    name="fs_date_resubmitted_to_fm"
                                    label="Date Resubmitted to FM"
                                    @input="form.fs_date_resubmitted_to_fm = $event; $v.form.fs_date_resubmitted_to_fm.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_date_resubmitted_to_fm_date"
                                      name="fs_date_resubmitted_to_fm_date"
                                      :validation="$v.form.fs_date_resubmitted_to_fm_date"
                                      @input="form.fs_date_resubmitted_to_fm_date = $event; $v.form.fs_date_resubmitted_to_fm_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_files_sent_to_designer"
                                    name="fs_files_sent_to_designer"
                                    label="Files Sent To Designer"
                                    @input="form.fs_files_sent_to_designer = $event; $v.form.fs_files_sent_to_designer.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_files_sent_to_designer_date"
                                      name="fs_files_sent_to_designer_date"
                                      :validation="$v.form.fs_files_sent_to_designer_date"
                                      @input="form.fs_files_sent_to_designer_date = $event; $v.form.fs_files_sent_to_designer_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_flow_test_scheduled"
                                    name="fs_flow_test_scheduled"
                                    label="Flow Test Scheduled"
                                    @input="form.fs_flow_test_scheduled = $event; $v.form.fs_flow_test_scheduled.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_flow_test_scheduled_date"
                                      name="fs_flow_test_scheduled_date"
                                      :validation="$v.form.fs_flow_test_scheduled_date"
                                      @input="form.fs_flow_test_scheduled_date = $event; $v.form.fs_flow_test_scheduled_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_job_informationSent_to_supplier"
                                    name="fs_job_informationSent_to_supplier"
                                    label="Job InformationSent To Supplier"
                                    @input="form.fs_job_informationSent_to_supplier = $event; $v.form.fs_job_informationSent_to_supplier.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_job_informationSent_to_supplier_date"
                                      name="fs_job_informationSent_to_supplier_date"
                                      :validation="$v.form.fs_job_informationSent_to_supplier_date"
                                      @input="form.fs_job_informationSent_to_supplier_date = $event; $v.form.fs_job_informationSent_to_supplier_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_flow_test_sent_to_designer"
                                    name="fs_flow_test_sent_to_designer"
                                    label="Flow Test Sent to Designer"
                                    @input="form.fs_flow_test_sent_to_designer = $event; $v.form.fs_flow_test_sent_to_designer.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_flow_test_sent_to_designer_date"
                                      name="signed_contract_date"
                                      :validation="$v.form.fs_flow_test_sent_to_designer_date"
                                      @input="form.fs_flow_test_sent_to_designer_date = $event; $v.form.fs_flow_test_sent_to_designer_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_material_quote_received"
                                    name="fs_material_quote_received"
                                    label="Material Quote Received"
                                    @input="form.fs_material_quote_received = $event; $v.form.fs_material_quote_received.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_material_quote_received_date"
                                      name="signed_contract_date"
                                      :validation="$v.form.fs_material_quote_received_date"
                                      @input="form.fs_material_quote_received_date = $event; $v.form.fs_material_quote_received_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_designs_received_from_designer"
                                    name="fs_designs_received_from_designer"
                                    label="Designs Received From Designer"
                                    @input="form.fs_designs_received_from_designer = $event; $v.form.fs_designs_received_from_designer.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_designs_received_from_designer_date"
                                      name="fs_designs_received_from_designer_date"
                                      :validation="$v.form.fs_designs_received_from_designer_date"
                                      @input="form.fs_designs_received_from_designer_date = $event; $v.form.fs_designs_received_from_designer_date.$touch()" )
                    .col-md-2
                      form-checkbox( :value="form.fs_others"
                                    name="fs_others"
                                    label="Others"
                                    @input="form.fs_others = $event; $v.form.fs_others.$touch()" )                  
                    .col-md-2
                      form-datetime( :value="form.fs_others_date"
                                      name="fs_others_date"
                                      :validation="$v.form.fs_others_date"
                                      @input="form.fs_others_date = $event; $v.form.fs_others_date.$touch()" )
          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-file-invoice-dollar.mr-2
                | Billing Information
              .row
                .col-md
                  form-textarea( :value="form.billing_information"
                                name="billing_information"
                                label="Billing Information"
                                :validation="$v.form.billing_information"
                                rows="6"
                                @input="form.billing_information = $event; $v.form.billing_information.$touch()" )

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-sticky-note.mr-2
                | Comments

              .row
                .col-md
                  form-textarea( :value="form.comments"
                                 name="comments"
                                 label="Comments"
                                 :validation="$v.form.comments"
                                 rows="6"
                                 @input="form.comments = $event; $v.form.comments.$touch()" )


          .row
            .col-md
              button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

              button.btn.btn-link( @click.prevent="$router.go( -1 )" ) Cancel
              
              button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                i.fa.fa-file-pdf.mr-1
                | View Report
            .col-md
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields
        
          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Fire Inspection
              fire-inspection( :construction_id="form.id" )
          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Subcontractor Activity Report
              subcontractor-activity-report( :construction_id="form.id" )
          .row.mt-3( v-if="exists" )
            .col-md
              ul.nav.nav-tabs.mb-2
                li.nav-item
                  a.nav-link.active( data-toggle="tab" )
                    i.fa.fa-calendar-check.mr-2
                    | Subcontractor Job Order
              subcontractor-job-order( :construction_id="form.id" )
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import FormSelectJson from '../shared/form_select_json'
  import FormSearchSelect from '../shared/form_search_select'
  import FormCheckbox from '../shared/form_checkbox'
  import FormDatetime from '../shared/form_datetime'
  import parseAddress from 'parse-address'
  
  import FireInspection from '../fire_inspection_worksheet/index'
  import SubcontractorActivityReport from '../subcontractor/daily_activity_report'
  import SubcontractorJobOrder from '../subcontractor/job_order'

  export default {
    name: 'construction_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormSelectJson,
      FormSearchSelect,
      FormCheckbox,
      FormDatetime,
      FireInspection,
      SubcontractorActivityReport,
      SubcontractorJobOrder,
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      projectNumber() {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.job_address ) ) {
            const p = parseAddress.parseLocation( this.form.job_address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.project_number = n

          return n
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        customer_name:                         { required },
        customer_address:                       { required },
        customer_city:                 { required },
        customer_state:                   {required},
        customer_zip:                      { required },

        pm_name:                       { },
        project_number:                         { },
        project_email:                         { },
        project_phone:                         { },
        
        job_name: { required },
        job_address:                {required},
        job_city:           {required},
        job_state:                    {required},
        job_zip:      { required },
        superintendent_name:              { },
        superintendent_email:          { },
        superintendent_phone:          { },
        building_type:                {},

        number_of_buildings:              {required },
        number_of_backflows:      {required },
        number_of_units:             { required },
        number_of_extinguishers:            { required },
        wet_pipe_systems:             { required },
        anti_freeze_loops:                   { required },
        dry_pipe_systems:         {required},
        fire_pumps:     {required},
        
        acd_signed_contract:      {},
        acd_signed_contract_date:  {},
        acd_insurance_certificate:        {},
        acd_insurance_certificate_date:         {},
        acd_entered_into_quickbooks:          {},
        acd_entered_into_quickbooks_date:                     {},

        cd_draw_schedule:            {},
        cd_draw_schedule_date:              {},
        cd_mobilization_draw_turned_on:         {},
        cd_mobilization_draw_turned_on_date:         {},

        fa_cad_pdf_files_requested:         {},
        fa_cad_pdf_files_requested_date:         {},
        fa_job_information_form_sent_to_supplier:         {},
        fa_job_information_form_sent_to_supplier_date:         {},
        fa_files_sent_to_designer:         {},
        fa_files_sent_to_designer_date:         {},
        fa_material_quote_received:         {},
        fa_material_quote_received_date:         {},
        fa_design_received_from_designer:         {},
        fa_design_received_from_designer_date:         {},
        fa_Others:         {},
        fa_Others_date:         {},
        fa_date_submitted_to_fm:         {},
        fa_date_submitted_to_fm_date:         {},
        fa_date_resubmitted_to_fm:         {},
        fa_date_resubmitted_to_fm_date:         {},

        fs_cad_pdf_files_requested:         {},
        fs_cad_pdf_files_requested_date:         {},
        fs_date_submitted_to_fm:         {},
        fs_date_submitted_to_fm_date:         {},
        fs_date_resubmitted_to_fm:         {},
        fs_date_resubmitted_to_fm_date:         {},
        fs_files_sent_to_designer:         {},
        fs_files_sent_to_designer_date:         {},
        fs_flow_test_scheduled:         {},
        fs_flow_test_scheduled_date:         {},
        fs_job_informationSent_to_supplier:         {},
        fs_job_informationSent_to_supplier_date:         {},
        fs_flow_test_sent_to_designer:         {},
        fs_flow_test_sent_to_designer_date:         {},
        fs_material_quote_received:         {},
        fs_material_quote_received_date:         {},
        fs_designs_received_from_designer:         {},
        fs_designs_received_from_designer_date:         {},
        fs_others:         {},
        fs_others_date:         {},
        billing_information:         {},
        comments:         {},
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'construction_new' ) {
          this.exists = false
          url = 'construction/new'
        }
        else {
          this.exists = true
          url = 'construction/' + this.$route.params.id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.construction
            // this.form.fireinspection = response.data.fireinspection
            // this.form.job_order = response.data.job_order
            // this.form.daily_report = response.data.daily_report
            this.loaded = true
          })

      },

      submit() {
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( 'construction/' + this.$route.params.id, { construction: this.form } )
            .then( ( response ) => {
              this.form = response.data.construction
              this.$toastr.success( 'The construction has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'construction', { construction: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The construction has been created successfully!' )
              this.$router.push( { name: 'construction'} )
            })
        }

      },
      downloadPDF() {
        this.$router.push( { name: 'construction_print', params: { id: this.form.id} } )
      }
    }
  }
</script>

<style scoped>
</style>
