<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right( v-if="!$session.$isEmployee()" )
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'employee_daily_activity_report_new' }" v-if = this.employee_id )
            i.fas.fa-plus.mr-1
            | New Employee Daily Activity Report
        .float-right( v-if="$session.$isEmployee()" )
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'employee_self_daily_activity_report_new' }")
            i.fas.fa-plus.mr-1
            | New Employee Daily Activity Report
        h4.mb-0
          i.fas.fa-building.mr-2
          | Employee Daily Activity Reports
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              th( @click="changeSort( 'first_name' )" )
                | Employee First Name
                i.fa.fas.ml-2( :class="sortClass( 'first_name' )" )

              th( @click="changeSort( 'last_name' )" )
                | Employee Last Name
                i.fa.fas.ml-2( :class="sortClass( 'last_name' )" )

              th( @click="changeSort( 'from_date' )" )
                | From Date
                i.fa.fas.ml-2( :class="sortClass( 'from_date' )" )

              th( @click="changeSort( 'to_date' )" )
                | To Date
                i.fa.fas.ml-2( :class="sortClass( 'to_date' )" )

              th( @click="changeSort( 'approved_by' )" )
                | Approved By
                i.fa.fas.ml-2( :class="sortClass( 'approved_by' )" )

              th( @click="changeSort( 'created_at' )" )
                | Created
                i.fa.fas.ml-2( :class="sortClass( 'created_at' )" )

              th( @click="changeSort( 'updated_at' )" )
                | Updated
                i.fa.fas.ml-2( :class="sortClass( 'updated_at' )" )
              th.action( width="10%" )
            tr
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.first_name"
                                                      @input="debounce_first_name"
                                                      placeholder="Filter Employee First Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.first_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.last_name"
                                                      @input="debounce_last_name"
                                                      placeholder="Filter Employee Last Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.start_date = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.from_date"
                                                      @input="debounce_from_date"
                                                      placeholder="Filter From Date" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.end_date = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.to_date"
                                                      @input="debounce_to_date"
                                                      placeholder="Filter To Date" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.sub_contractor_signature = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.approved_by"
                                                      @input="debounce_approved_by"
                                                      placeholder="Filter Approved By" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.approved_by = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.created_at"
                                                      @input="debounce_created_at"
                                                      placeholder="Filter Created At" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.created_at = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.updated_at"
                                                      @input="debounce_updated_at"
                                                      placeholder="Filter Updated At" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.updated_at = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.id" @click="gotoCustomer( row )" v-if="loaded" )
              td {{ row.first_name }}
              td {{ row.last_name }}
              td {{ row.from_date | date }}
              td {{ row.to_date | date }}
              td {{ row.approved_by }}
              td {{ row.created_at | datetime }}
              td {{ row.updated_at | datetime }}
              td
                router-link( :to="{ name: 'employee_daily_activity_report_edit', params: { report_id: row.id,employee_id:row.employee_id } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Daily Report'" )
                router-link( :to="{ name: 'employee_daily_activity_report_self_edit', params: { report_id: row.id} }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isEmployee()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Daily Report'" )

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching Reports)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>
<script>
  import tableUtils from '../../../mixins/table_utils'
  import pagination from '../../../mixins/paginate'

  export default {
    name: 'employee_daily_activity_report_index',

    mixins: [ tableUtils ],

    components: { pagination },
    props: {
      construction_id: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,

        filters: {
          first_name:'',
          last_name:'',
          from_date:'',
          to_date:'',
          approved_by:'',
          created_at:'',
          updated_at:'',
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },
    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Daily Reports'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.sortColumn = 'first_name'

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        let url
        if ( this.$route.params.employee_id) {
          url = 'employees/' + this.$route.params.employee_id + '/daily-activity-report'
        }
        else {
          url = 'employees/daily-activity-report'
        }
        localStorage.customerIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        this.$http.secured.get( url, { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.daily_report
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoCustomer( customer ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'subcontractor_daily_activity_report_edit', params: { id: customer.id,construction_id: customer.construction_id } } )
        }
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounce_first_name: _.debounce( function( e ) { this.filters.first_name = e.target.value }, 500 ),
      debounce_last_name: _.debounce( function( e ) { this.filters.last_name = e.target.value }, 500 ),
      debounce_from_date: _.debounce( function( e ) { this.filters.from_date = e.target.value }, 500 ),
      debounce_to_date: _.debounce( function( e ) { this.filters.to_date = e.target.value }, 500 ),
      debounce_approved_by: _.debounce( function( e ) { this.filters.approved_by = e.target.value }, 500 ),
      debounce_created_at: _.debounce( function( e ) { this.filters.created_at = e.target.value }, 500 ),
      debounce_updated_at: _.debounce( function( e ) { this.filters.updated_at = e.target.value }, 500 ),
    }
  }
</script>

<style scoped>
</style>
