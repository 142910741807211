<template lang="pug">
    div( v-if="loaded" )
      .row
        .offset-md-3.col-md-6
  
          .card.border-secondary
            .card-header.bg-dark.text-white
              h4.mb-0
                i.fa.fa-user.mr-2                
                  | New Timeoff Request 
            .card-body  
              form( novalidate @submit.prevent="submit" )  
                .card.mb-3
                  .card-body
                    .row.md
                      .col-md
                        .text-muted Name
                        | {{this.employeeDetails.first_name}} {{this.employeeDetails.last_name}}
                    .row
                      .col-md
                        form-date( :value="form.todays_date"
                                    name="todays_date"
                                    label="Todays Date"
                                    :validation="$v.form.todays_date"
                                    @input="form.todays_date = $event; $v.form.todays_date.$touch()" )
                      .col-md
                        form-date( :value="form.requested_from"
                                    name="requested_from"
                                    label="Requested From"
                                    :validation="$v.form.requested_from"
                                    @input="form.requested_from = $event; $v.form.requested_from.$touch()" )
                      .col-md
                        form-date( :value="form.requested_to"
                                    name="requested_to"
                                    label="Requested To"
                                    :validation="$v.form.requested_to"
                                    @input="form.requested_to = $event; $v.form.requested_to.$touch()" )                    
                    .row
                      .col-md
                        form-select( :value="form.reason"
                                  name="reason"
                                  :options="reason_options"
                                  label="Reason"
                                  :validation="$v.form.reason"
                                  @input="form.reason = $event; $v.form.reason.$touch()" )
                      .col-md
                        form-input( :value="form.employee_comments"
                                  name="employee_comments"
                                  label="Employee Comments"
                                  :validation="$v.form.employee_comments"
                                  @input="form.employee_comments = $event; $v.form.employee_comments.$touch()")                    
                    .row
                      .col-md
                        form-input( :value="form.employee_signature"
                                  name="employee_signature"
                                  :options="employee_signature"
                                  label="Employee Signature"
                                  :validation="$v.form.employee_signature"
                                  @input="form.employee_signature = $event; $v.form.employee_signature.$touch()" )
                      .col-md
                        form-date( :value="form.employee_signature_date"
                                  name="employee_signature_date"
                                  label="Date"
                                  :validation="$v.form.employee_signature_date"
                                  @input="form.employee_signature_date = $event; $v.form.employee_signature_date.$touch()")                
                    .row
                      .col-md
                        form-input( :value="form.manager_signature"
                                  name="manager_signature"
                                  :options="manager_signature"
                                  label="Manager Signature"
                                  :validation="$v.form.manager_signature"
                                  @input="form.manager_signature = $event; $v.form.manager_signature.$touch()" )
                      .col-md
                        form-date( :value="form.manager_signature_date"
                                  name="manager_signature_date"
                                  label="Date"
                                  :validation="$v.form.manager_signature_date"
                                  @input="form.manager_signature_date = $event; $v.form.manager_signature_date.$touch()")
                .row
                  .col-md
                    button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
  
                    button.btn.btn-link( @click.prevent="$router.go( -1 )" ) Cancel
                  .col-md
                    div( style="position: absolute; right: 1em; bottom: 0;" )
                      i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                      | are
                      |
                      strong required
                      |
                      | fields
  
</template>  
<script>
    import { required } from 'vuelidate/lib/validators'
    import FormInput from '../shared/form_input'
    import FormSelect from '../shared/form_select'
    import FormSelectJson from '../shared/form_select_json'
    import FormDate from '../shared/form_date'
  
    export default {
      name: 'TimeoffRequestEdit',
      components: {
        FormInput,
        FormSelect,
        FormSelectJson,
        FormDate
      },
  
      data() {
        return {
          form: null,
          loaded: false,
          exists: false,
          submitting: false,
          reason_options: [ 'Illness', 'Vacation','Personal','Jury Duty','Others' ],
          employeeDetails: []
        }
      },
  
      computed: {
        saveLabel() {
          return this.exists ? 'Save' : 'Create'
        }
      },
  
      validations: {
        form: {
          requested_from:     { required },
          requested_to:       { required },
          todays_date:        { required },
          reason:             { required },          
          employee_comments:  { required },
          employee_signature:  { required },
          employee_signature_date:  { required },
          manager_signature:  { required },
          manager_signature_date:  { required }
        }
      },
  
      mounted() {
        this.fetchData()
      },
  
      methods: {
        fetchData() {
          let url = null
          if ( this.$route.name === 'timeoff_request_new' ) {
            this.exists = false
            url = 'timeoff_requests/new'
            this.$http.secured.get(url)
            .then( ( response ) => {
              this.employeeDetails = response.data.employeeDetails;
            })
          }  
          else {
            this.exists = true
            url = 'timeoff_requests/' + this.$route.params.id
          }          

          if (this.$route.name !== 'timeoff_request_delete'){
            this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data.timeoffRequest
              this.employeeDetails = response.data.employeeDetails
              this.loaded = true
            })
          } else{
              if(confirm('Are you sure you want to delete request?')) {
                  this.$http.secured.delete( url )
                  .then( response => {
                      this.$toastr.success( 'Request has been deleted successfully!' )                      
                  })
              }
              this.$router.push( { name: 'timeoff_requests' } )            
            }
        },
  
        submit() {
          this.submitting = true
          if(this.form.id==null)
          {
            this.$http.secured.post( 'timeoff_requests', { timeoff_request: this.form }  )
              .then( response => {
                this.$toastr.success( 'The Timeoff request has been created successfully!' )
                this.$router.push( { name: 'timeoff_requests' } )
              }, error => this.handleHttpErrors(error))
          }
          else
          {
            this.$http.secured.put( 'timeoff_requests/'+this.form.id, { timeoff_request: this.form }  )
              .then( response => {
                this.$toastr.success( 'The Timeoff request has been updated successfully!' )
                this.$router.push( { name: 'timeoff_requests' } )
              }, error => this.handleHttpErrors(error))
          }
        },
        handleHttpErrors(error){
          if(error.response.status == 422) {
            console.log(error.response)
            let error_details = ''
            for (let key in error.response.data.errors) {
                error_details += `${error.response.data.errors[key]}<br/>`
            }
            this.$toastr.error( error_details )
          }
          this.submitting = false
          this.$scrollToTop()
        }
      }
    }
</script>  
<style scoped>
</style>
  