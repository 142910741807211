<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .row.mb-3
      .col
        .float-left
          img( src='~images/kodiak.jpg' width="100" height="100" )
        .float-right( style="width:100px; height:100px" ) &nbsp;
        .text-center
          h1
            u
              strong.text-danger K
              | odiak
              |
              strong.text-danger F
              | ire
              |
              strong.text-danger P
              | rotection, Inc
          h3.mb-0
            | 507 Prestige Cr. Allen, Texas 75002
            br/
            | Phone: (469) 342-6819
            | &#9679;
            | Fax: (800) 335-6449
            br/
            | TXACR-3354
            | &#9679;
            | TXSCR-1751231
            | &#9679;
            | TXECR-166290
            br/
            span.text-danger www.kodiakfiretexas.com

    .row.mb-3
      .col.text-center
        h2.mb-0
          strong Repair Quote

    .row.mb-3
      .col-6
        .row
          .col-4.text-right.text-muted.pr-1 Quote #:
          .col-8.pl-0 {{ form.quote_number }}
        .row
          .col-4.text-right.text-muted.pr-1 Date:
          .col-8.pl-0 {{ form.service_date | datetime }}
        .row
          .col-4.text-right.text-muted.pr-1 Quoted to:
          .col-8.pl-0 {{ form.customer.name }}
        .row
          .col-4.text-right.text-muted.pr-1 Address:
          .col-8.pl-0 {{ form.customer.address }}
        .row
          .col-4.text-right.text-muted.pr-1 City/ST/Zip:
          .col-8.pl-0 {{ form.customer.city_state_zip }}
        .row
          .col-4.text-right.text-muted.pr-1 Telephone:
          .col-8.pl-0 {{ form.customer.phone_number }}
        .row
          .col-4.text-right.text-muted.pr-1 Email:
          .col-8.pl-0 {{ form.customer.manager_email }}

      .col-6
        .row
          .col-4.text-right.text-muted.pr-1 Reference:
          .col-8.pl-0 {{ form.reference_number }}
        .row
          .col-4.text-right.text-muted.pr-1 &nbsp;
          .col-8.pl-0 &nbsp;
        .row
          .col-4.text-right.text-muted.pr-1 &nbsp;
          .col-8.pl-0
            h3
              strong Price good for 15 days.

        .row
          .col-4.text-right.text-muted.pr-1 Note:
          .col-8.pl-0
            strong By State and Local codes Kodiak must notify the Fire Marshal within 24 to 48-hours of any deficiencies. This could result in a 24-hour fire watch and/or a fine.

    .row.mb-3
      .col
        .row
          .col-2.text-right.text-muted.pr-1 Scope of work:
          .col-10.pl-0
            | Price includes labor and material stated
            br/
            span(v-html="formattedScopeOfWork")
    .signature.ml-3.mr-3.mb-3
      .row
        .col-10
          h3
            strong Material & Labor:
        .col-2 $ {{ setServiceItemValue(form.price) }}
      .row
        .col
          |Tax Included
      .row
        .col-10.pr-1
          h3
            strong TOTAL:
        .col-2.pr-1
          h3
            strong $ {{ setServiceItemValue(form.price) }}

    .row.mb-1.ml-1
      .col
        strong Terms: Unless otherwise agreed to by both parties in writing, the terms of this quote are as follows:
    ul
      li Price good for 15 days only.

      li 30 Day Warranty on service performed.

      li Kodiak requires a 50% down payment of ( $ {{ form.price > 2500 ? setServiceItemValue(form.price/2) : 'NA' }} ) and the balance of ($ {{ form.price > 2500 ? setServiceItemValue(form.price/2) : 'NA' }} ) is due upon completion of repairs.  Kodiak will not order material and/or schedule the repair job until the 50% down is received unless otherwise agreed upon in writing from Kodiak. This repair quote will be invoiced upon signed quote approval.

      li Kodiak is not responsible for loss of antifreeze due to draining down the fire sprinkler systems for repairs. Antifreeze needed beyond scope of work will be billed at $35.00 per gallon.

      li Kodiak is not responsible for any electrical work if needed such as wiring and/or repairs to electrical.

      li Kodiak is not responsible for any broken and/or cracked fittings, broken and/or cracked pipe,sheetrock damage, water damage and/or any repairs due to replacing sprinkler heads and/or sprinkler pipe. Kodiak is not responsible for any damages from sprinkler heads being on the antifreeze loops and not being labeled properly.

      li Kodiak will cover up to fifty (50) head adapters and/or escutcheons that are needed during the sprinkler head replacement and customer will be charged for any head adapters and/or escutcheons that are needing replaced exceeding fifty (50). If “pipe dope” is found on sprinkler heads during replacement, there will be an additional charge to the customer of $6.00 per head.

      li This quote does not include any city and state fees that might he required unless otherwise stated under scope of work. This quote is for normal business hours 8am to 5pm Monday - Friday only.

      li Unless stated in “Scope of Work”, the price above does not include any permit fees, designs, plans and/or submittals if required by City.

      li If Kodiak does not have access to units during sprinkler head replacements, there will be a $155.00 charge per hour that the building inspection must be rescheduled.

      li Any expense for unknown damages over the contracted amount listed above, will have a separate quote sent to customer for approval.

      li If Kodiak must reschedule on the day that the repairs and/or inspections arc scheduled for due to customer and/or resident, there will be a $580.00 rescheduling fee.

      li Limitation of Liability; Client understands and agrees that if Kodiak should be found liable for loss or damages due to failure of the equipment properly performing or technician workmanship. Kodiak' s liability shall not exceed a sum equal to Three Hundred and Fifty Dollars ($350.00) and this liability shall be exclusive: and that the provisions of this Section shall apply if loss or damage; irrespective of cause or origin, results directly or indirectly, to persons or property, from performance or nonperformance of the equipment or technician installation. If Client wishes Kodiak to assume greater liability as herein set forth in the agreement; Client may obtain from Kodiak an increased limit of liability by paying an additional charge to Kodiak. If Client exercises this option; a rider shall be attached to this agreement setting forth the terms, conditions and additional charges to Kodiak.
    br/
    br/
    br/

    .row.mb-1.ml-1
      .col
        strong NFPA 13 Code 6.2.6.2.2 – Where sprinklers have had paint applied by other than sprinkler manufacturer, they “SHALL” be replaced with new listed sprinklers of the same characteristics, including orifice size, thermal response, and water distribution.  NAPA 13 Code 3.2.4 – “SHALL” Indicates a mandatory requirement.

    .row.mb-1.ml-1
      .col
        strong Please sign indicating your acceptance of this quote then email or fax it to Kodiak’s office. By submitting this quote and offer, Kodiak does not agree and/or become bound to perform the work indicated above.  This quote will supersede any contracts signed by Kodiak.

    .row.mb-1.ml-1
      .col
        |Sincerely,
    .row.mb-1.ml-1
      .col
        strong KODIAK FIRE PROTECTION, INC.
    .row.mb-1.ml-1
      .col
        |Service Department
    .row.mb-1.ml-1
      .col
        strong ACCEPTANCE CLAUSE:
    .row.mb-3.ml-1
      .col
        |Signature below indicates we hereby agree, accept, and authorize Kodiak Fire Protection, Inc. to proceed with the work described above under the terms and upon the conditions described herein.
    .row.mb-0.ml-1
      .col-6
        |____________________________________
      .col-6
        |____________________________________
    .row.mb-2.ml-1
      .col-6
        |Signature
      .col-6
        |Title
    .row.mb-0.ml-1
      .col
        |____________________________________
    .row.mb-4.ml-1
      .col
        |Date

    .row.mb-3.bordered
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1
    .row
      .col
        .text-right.text-muted.pr-1 Copy Right, All Rights Reserved
    .row
      .col
        .text-right.text-muted.pr-1 Revised 01/28/2020, Repair Bid Proposal



</template>

<script>
  import '../../images/kodiak.jpg'

  export default {
    name: 'RepairQuotePrint',

    data() {
      return {
        form: null,
        loaded: false
      }
    },

    computed: {

      formattedScopeOfWork() {
        if (this.form && this.form.scope_of_work) {
          return this.form.scope_of_work.replace(/\n/g, '<br>');
        }
        return '';
      },
    },

    mounted() {
      const url = 'customers/' + this.$route.params.id + '/repair_quotes/' + this.$route.params.repair_quote_id

      console.log( url );

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data
          this.loaded = true
        })
    },
    methods: {
      setServiceItemValue( price ) {
        return (Math.round(price * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

  }
</script>

<style scoped lang="scss">
  .signature {
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>
