<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fas.fa-building.mr-2
          template( v-if="exists" )
            | Edit Job Order
          template( v-else )
            | New Job Order
      .card-body
        form( novalidate @submit.prevent="submit" )
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information
              .row.mb-2
                .col-md
                  .text-muted Customer Name
                  | {{form.constructiondetails.customer_name}}
                .col-md
                  .text-muted Customer Address
                  | {{form.constructiondetails.customer_address}}
                .col-md
                  .text-muted City
                  | {{form.constructiondetails.customer_city}}
                .col-md
                  .text-muted State
                  | {{form.constructiondetails.customer_state}}
                .col-md
                  .text-muted Zip
                  | {{form.constructiondetails.customer_zip}}
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Project Information
              .row.mb-2
                .col-md
                  .text-muted Project Number
                  |  {{form.constructiondetails.project_number}}
                .col-md
                  .text-muted Email
                  | {{form.constructiondetails.project_email}}
                .col-md
                  .text-muted Customer Phone
                  | {{form.constructiondetails.project_phone}}                
                .col-md-2
                  form-input( :value="form.project_name"
                              name="project_name"
                              label="Project Name"
                              :validation="$v.form.project_name"
                              @input="form.project_name = $event; $v.form.project_name.$touch()" )
                .col-md-4
                  form-input( :value="form.project_address"
                              name="project_address"
                              label="Project Address"
                              :validation="$v.form.project_address"
                              @input="form.project_address = $event; $v.form.project_address.$touch()" )
          .row
            .col-md-6
              form-input( :value="form.issues_to"
                              name="issues_to"
                              label="Issued To"
                              :validation="$v.form.issues_to"
                              @input="form.issues_to = $event; $v.form.issues_to.$touch()" )
            .col-md
              form-input( :value="form.phone"
                          name="phone"
                          label="Phone"
                          :validation="$v.form.phone"
                          @input="form.phone = $event; $v.form.phone.$touch()")
            //- .col-md
            //-   .card( v-if="exists" )
            //-     .card-body.bg-light.p-2
            //-       table.table-sm
            //-         tbody
            //-           tr
            //-             td.text-right.text-muted.pb-0 Created At:
            //-             td.pb-0 {{ form.created_at | datetime }}
            //-           tr
            //-             td.text-right.text-muted.pb-0 Updated At:
            //-             td.pb-0 {{ form.updated_at | datetime }}
            //-           tr
            //-             td.text-right.text-muted.pb-0 Last Updated By:
            //-             td.pb-0 {{ form.updating_user.name }}

          .row
            .col-md
              form-input( :value="form.email"
                          name="email"
                          label="Email"
                          :validation="$v.form.email"
                          @input="form.email = $event; $v.form.email.$touch()" )
            .col-md
              .row
                .col-md-6
                  form-datetime( :value="form.job_order_date"
                              name="job_order_date"
                              label="Job Order Date"
                              :validation="$v.form.job_order_date"
                              @input="form.job_order_date = $event; $v.form.job_order_date.$touch()" )
                .col-md-2
                  form-input( :value="form.project_name"
                              name="project_name"
                              label="Project Name"
                              :validation="$v.form.project_name"
                              @input="form.project_name = $event; $v.form.project_name.$touch()" )
                .col-md-4
                  form-input( :value="form.project_address"
                              name="project_address"
                              label="Project Address"
                              :validation="$v.form.project_address"
                              @input="form.project_address = $event; $v.form.project_address.$touch()" )

          .row
            .col-md
              .row
                .col-md
                  form-input( :value="form.job_order_total"
                              name="job_order_total"
                              label="Total"
                              :validation="$v.form.job_order_total"
                              @input="form.job_order_total = $event; $v.form.job_order_total.$touch()" )
          
              .row
                .col-md
                  .card.border-secondary
                    .card-header.bg-dark.text-white
                      .float-right
                        button.btn.btn-outline-light.btn-sm(type='button' @click="addnewjobreportitem()" v-if="$session.$isBackOffice()" )
                          i.fas.fa-plus.mr-1
                          | Add New Entry
                    table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
                      thead.bg-secondary.text-white
                        tr
                          th Description
                          th Material
                          th Total
                      tbody
                        tr.pointer( v-for="row in form_items" :key="row.id" v-if="loaded" )
                          td
                            form-input( :value="row.order_item_description"
                                            name="order_item_description"
                                            :validation="$v.form_items.order_item_description"
                                            @input="row.order_item_description = $event; $v.row.order_item_description.$touch()")

                          td
                            form-input( :value="row.order_item_material"
                                            name="order_item_material"
                                            :validation="$v.form_items.order_item_material"
                                            @input="row.order_item_material = $event; $v.row.order_item_material.$touch()")
                          td
                            form-input( :value="row.order_item_total"
                                            name="order_item_total"
                                            :validation="$v.form_items.order_item_total"
                                            @input="row.order_item_total = $event; $v.row.order_item_total.$touch()")
                        tr(v-if="form_items.length==0")
                          td(:colspan="3") No Records

              .row
                .col-md
                  form-input( :value="form.sub_contractor_signature"
                              name="sub_contractor_signature"
                              label="Subcontractor Signature"
                              :validation="$v.form.sub_contractor_signature"
                              @input="form.sub_contractor_signature = $event; $v.form.sub_contractor_signature.$touch()" )
                .col-md
                  form-datetime( :value="form.job_order_approval_date"
                              name="job_order_approval_date"
                              label="Approval Date"
                              :validation="$v.form.job_order_approval_date"
                              @input="form.job_order_approval_date = $event; $v.form.job_order_approval_date.$touch()" )

              .row
                .col-md
                  button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

                  button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
                  button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                    i.fa.fa-file-pdf.mr-1
                    | View Report
                .col-md
                  div( style="position: absolute; right: 1em; bottom: 0;" )
                    i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                    | are
                    |
                    strong required
                    |
                    | fields
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../../shared/form_input'
  import FormTextarea from '../../shared/form_textarea'
  import FormRadio from '../../shared/form_radio'
  import FormSelect from '../../shared/form_select'
  import FormDatetime from '../../shared/form_datetime'
  import parseAddress from 'parse-address'
  import ServiceReports from '../../service_reports/index'

  export default {
    name: 'subcontractor_job_order_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormDatetime,
      ServiceReports
    },

    data() {
      return {
        form: null,
        form_items:[],
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },
      jobbOrderNumber() {
          let n = this.$moment().format( 'YY' ) + '-'

          // if ( !_.isEmpty( this.form.job_address ) ) {
          //   const p = parseAddress.parseLocation( this.form.job_address )
          //   if ( p.number ) {
          //     n += p.number

          //     if ( !_.isEmpty( this.form.building_type ) ) {
          //       n += this.form.building_type[0]
          //     }
          //   }
          // }

          this.form.job_order_number = n

          return n
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        issues_to:{ required },
        phone:{ required },
        email:{ required },
        job_order_date:{ required },
        project_name:{ required },
        project_address:{ required },
        // job_order_number:{ required },
        job_order_total:{ required },
        sub_contractor_signature:{ },
        job_order_approval_date:{ }
      },
      form_items:{
        order_item_description:{ },
        order_item_material:{ },
        order_item_total:{  }
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'subcontractor_job_order_new' ) {
          this.exists = false
          url = 'construction/' + this.$route.params.construction_id + '/subcontractor/job_order/new'
        }
        else {
          this.exists = true
          url = 'construction/' + this.$route.params.construction_id + '/subcontractor/job_order/' + this.$route.params.id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.job_order
            this.form_items = response.data.job_order_items
            this.form.constructiondetails = response.data.constructiondetails
            this.loaded = true
          })

      },
      addnewjobreportitem(){
        this.form_items.push({
        order_item_description:'',
        order_item_material:'',
        order_item_total:'',
        subcontractor_job_order_id:this.form.id
      })
      },

      submit() {
        debugger
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( 'construction/'+this.$route.params.construction_id+'/subcontractor/job_order/' + this.$route.params.id, { job_order: this.form,job_order_items: this.form_items} )
            .then( ( response ) => {
              this.form = response.data
              this.form_items = response.data.job_order_items
              this.form.constructiondetails = response.data.constructiondetails
              this.$toastr.success( 'The Job Order has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'construction/'+this.$route.params.construction_id+'/subcontractor/job_order', { job_order: this.form,job_order_items: this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Job Order has been created successfully!' )
              this.$router.push( { name: 'subcontractor_job_order', params: { construction_id: this.$route.params.construction_id }  } )
            })
        }

      },
      downloadPDF() {
        this.$router.push( { name: 'subcontractor_job_order_print', params: { id: this.form.id,construction_id: this.form.construction_id } } )
      }
    }
  }
</script>

<style scoped>
</style>
