<template lang="pug">
  div.form-group
    label( :for="name" )
      i.fas.fa-asterisk.fa-xs.text-danger.mr-1( v-if="validation.hasOwnProperty( 'required' )" )
      | {{ label }}
    input.form-control( :type="type"
                        :value="value"
                        @input="onInput"
                        :disabled="disabled"
                        :id="name"
                        :class="{ 'is-invalid': validation.$error }" )

    small.form-text.text-muted( v-if="hint" )
      | {{ hint }}
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'required' ) && !validation.required && validation.$error" )
      | can't be blank
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'email' ) && !validation.email && validation.$error" )
      | must be a valid email address
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'maxLength' ) && !validation.maxLength && validation.$error" )
      | is too long
</template>

<script>
  export default {
    name: "formInput",

    props: {
      value: {
        type: [ String, Number ],
        required: false,
        default: null
      },
      validation: {
        type: Object,
        default: null,
      },
      name: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null,
      },
      hint: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      trim: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      onInput( event ) {
        const value = event.target.value;

        this.$emit( 'input', this.trim ? value.trim() : value );
      }
    }

  }
</script>

<style scoped>
</style>
