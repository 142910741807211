<template lang="pug">
  div( v-if="loaded" )
    .card.border( :class="originalCustomerId ? '' : 'border-secondary'" )
      .card-header.text-white( :class="originalCustomerId ? 'bg-secondary' : 'bg-dark'" )
        .float-right( v-if="originalCustomerId" )
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'inspection_quote_new' }" )
            i.fa.fa-plus.mr-1
            | New Annual Inspection Quotes
        h4.mb-0
          i.fa.fa-calendar-check.mr-2
          | Annual Inspection Quotes
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              th( @click="changeSort( 'id' )" v-if="!originalCustomerId" ) 
                | Customer
                i.fa.fas.ml-2( :class="sortClass( 'number' )" )
              th
                | Inspection Quote Number
              th
                | Month Due
              th
                | Updated At
              th.action( width="3%" )
            tr
              th( v-if="!originalCustomerId" ).action
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.inspection_number"
                                                      @input="debounceIns"
                                                      placeholder="Filter Inspection Quote Number" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click.prevent.stop="filters.inspection_number = ''" )
                      i.fas.fa-times
              th
              th.action
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.id" @click="goto( row )" v-if="loaded" )
              td( v-if="!originalCustomerId" ) {{ row.customer_name }}
              td {{ row.inspection_number }}
              td {{ selectedMonthName( row ) }}
              td {{ row.updated_at | datetime }}
              td
                router-link( :to="{ name: 'inspection_quote_edit', params: { id: row.customer_id, inspection_quote_id: row.id } }" )
                  i.fa.fa-pencil-alt.mr-2( v-tooltip="'Edit Annual Inspection Quote'" )
                router-link( :to="{ name: 'inspection_quote_delete', params: { id: row.customer_id, inspection_quote_id: row.id } }"
                         @click.native="$event.stopImmediatePropagation()")
                  i.fa.fa-trash-alt.mr-2( v-tooltip="'Delete Annual Inspection Quote'" )

            tr( v-if="loaded && data.length === 0" )
              td( colspan="4" )
                small.text-muted
                  | (No matching Annual Inspection Quotes)

            tr( v-if="loading" )
              td( colspan="4" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )

        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../mixins/table_utils'
  import pagination from '../../mixins/paginate'

  export default {
    name: 'InspectionQuoteIndex',

    mixins: [ tableUtils ],

    components: { pagination },

    props: {
      originalCustomerId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,
        monthes: [
          {id: "1", name: "January" },
          {id: "2", name: "February" },
          {id: "3", name: "March" },
          {id: "4", name: "April" },
          {id: "5", name: "May" },
          {id: "6", name: "June" },
          {id: "7", name: "July" },
          {id: "8", name: "August" },
          {id: "9", name: "September" },
          {id: "10", name: "Octomber" },
          {id: "11", name: "November" },
          {id: "12", name: "December" },
        ],

        filters: {
          inspection_number: ''
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        if ( _.every( _.values( this.filters ), _.isEmpty ) ) {
          let start = ( this.page - 1 ) * this.perPage + 1
          let end = start + this.perPage - 1
          if ( end < this.perPage ) {
            end = this.perPage
          }
          else if ( end > this.count ) {
            end = this.count
          }
          return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Inspection Quotes'
        }
        else {
          return 'Showing ' + this.data.length + ' of ' + this.count + ' Inspection Quotes'
        }
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.usersIndexPerPage ) || 10
      this.reverse = true

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        localStorage.inspectionQuoteIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        let url
        if ( this.originalCustomerId ) {
          url = 'customers/' + this.originalCustomerId + '/inspection_quotes'
        }
        else {
          url = 'inspection_quotes'
        }

        this.$http.secured.get( url, {
          params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.inspect_quotes
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      selectedMonthName( form ) {
        for (let month of this.monthes) {
          if (parseInt(month.id) === form.month_due) {
            return month.name;
          }
        }
      },

      goto( model ) {
        this.$router.push( { name: 'inspection_quote_edit', params: { id: model.customer_id, inspection_quote_id: model.id } } )
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounceIns: _.debounce( function( e ) { this.filters.inspection_number = e.target.value }, 500 ),
    }
  }
</script>

<style scoped>
</style>
