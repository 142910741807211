<template lang="pug">
  .container-fluid.pdf( v-if="loaded" )
    .row.mb-3
      .col
        .float-left
          img( src='~images/kodiak.jpg' width="100" height="100" )
        .float-right( style="width:100px; height:100px" ) &nbsp;
        .text-center
          h1
            u
              strong.text-danger K
              | odiak
              |
              strong.text-danger F
              | ire
              |
              strong.text-danger P
              | rotection, Inc
          h3.mb-0
            | 507 Prestige Cr. Allen, Texas 75002
            br/
            | Phone: (469) 342-6819
            | &#9679;
            | Fax: (800) 335-6449
            br/
            | TXACR-3354
            | &#9679;
            | TXSCR-1751231
            | &#9679;
            | TXECR-166290
            | &#9679;
            br/
            span.text-danger www.kodiakfiretexas.com

    .row.mb-3
      .col.text-center
        h2.mb-0
          strong Annual Inspection Quote
    .row.mb-3.ml-1.mr-1
      .col
        table.table.table.table-bordered.table-sm.mb-0
          thead
          tr
            th( width="10%" ) Quote #:
            th {{ form.inspection_number }}
    .row.mb-3.ml-1.mr-1
      .col
        table.table.table.table-bordered.table-sm.mb-0
          colgroup
            col(style = "width: 141px")
            col(style = "width: 378px")
            col(style = "width: 270px")
            col(style = "width: 56px")
            col(style = "width: 270px")
            col(style = "width: 56px")
            col(style = "width: 300px")
            col(style = "width: 56px")
            col(style = "width: 300px")
            col(style = "width: 56px")
          tr
            td.text-right Property:
            td {{ form.customer.name }}
            td.text-right(:colspan = "3") Month Due:
            td(:colspan = "5") {{ selectedMonthName( form ) }}
          tr
            td.text-right Address:
            td {{ form.customer.address }}
            td.text-right # Bldgs:
            td {{ form.bldgs }}
            td.text-right # Units:
            td {{ form.units }}
            td.text-right # Extinguishers:
            td {{ form.extinguisher }}
            td.text-right # Fire Hydrants:
            td {{ form.fire_hydrants }}
          tr
            td.text-right City/ST/Zip:
            td {{ form.customer.city_state_zip }}
            td.text-center(:colspan = "8")
              strong Inspections to be performed
          tr
            td.text-right Contact:
            td {{ form.customer.phone_number }}
            td.text-right(:colspan = "3") Fire Alarm:
            td(:colspan = "5") {{ yesno(form.fire_alarm) }}
          tr
            td.text-right Email:
            td {{ form.customer.manager_email }}
            td.text-right(:colspan = "3") Fire Sprinkler:
            td(:colspan = "5") {{ yesno(form.fire_sprinkler) }}

          tr
            td(:colspan = "2", :rowspan = "3") This proposal is based on NFPA 72, NFPA 25, IFC 2003, TCEQ, State and Local City codes. Kodiak will provide written test reports and proper tags for the fire alarm and the fire sprinkler systems. If required Kodiak will submit the test reports to the local fire marshal.
            td.text-right(:colspan = "3") Fire Riser Backflows:
            td(:colspan = "5") {{ yesno(form.fire_riser_backflows) }}

          tr
            td.text-right(:colspan = "3") Test Waterflow Switches:
            td(:colspan = "5") {{ yesno(form.test_waterflow_switches) }}
          tr
            td.text-right(:colspan = "3") Test Antifreeze Systems if applicable:
            td(:colspan = "5") {{ yesno(form.test_antifreeze_system) }}
          tr
            td(:colspan = "2", :rowspan = "3") Per State and Local Codes Kodiak must notify the Local Fire Marshal of any deficiencies (Yellow Tags and/or Red Tags) on the fire alarm and/or fire sprinkler systems with-in 24 to 48-hours. This could lead to the Fire Marshal putting the property on fire watch until the deficiencies are corrected.
            td.text-right(:colspan = "3") Unit Sprinkler Head Inspection:
            td(:colspan = "5") {{ yesno(form.unit_sprinkler_head) }}
          tr
            td.text-right(:colspan = "3") Fire Extinguishers:
            td(:colspan = "5") {{ yesno(form.fire_extinguisher) }}

          tr
            td.text-right(:colspan = "3") Fire Pump:
            td(:colspan = "5") {{ yesno(form.fire_pump) }}
          tr
            td.text-right(:colspan = "5") Fire Hydrant:
            td(:colspan = "5") {{ yesno(form.fire_hydrant) }}
          tr
            td.text-right(:colspan = "5") Domestic Backflow:
            td(:colspan = "5") {{ yesno(form.domestic_backflow) }}
          tr
            td.text-right(:colspan = "5") Pool Backflow:
            td(:colspan = "5") {{ yesno(form.pool_backflow) }}
          tr
            td.text-right(:colspan = "5") Irrigation Backflow:
            td(:colspan = "5") {{ yesno(form.irrigation_backflow) }}

          tr
            td(:colspan = "10")
          tr
            td(:colspan = "2", :rowspan = "2")
              Strong Price for Annual Inspection:
              br/
              | This fee is for inspection only. Any repairs that are necessary will be sent to customer for approval prior to repairs being made.
            td.text-center(:colspan = "8", :rowspan = "2")
              span(style = "font-weight:bold;color:rgb(253, 104, 100);padding:15px")
                h1 $ {{ setServiceItemValue(form.annual_inspection_price) }}  Tax Included
          tr
          tr
            td(:colspan = "10")
          tr
            td(:colspan = "2")
              Strong City Fee for Backflow Inspections:
              br/
              | This is a mandatory fee charged by the City and payable to the City.  Inspection reports cannot be submitted to the City without this being paid.
            td.text-center.pt-3(:colspan = "3")
              span(style = "font-weight:bold")
                h1 {{ form.backflow_inspection_city_fee }}
            td.text-center(:colspan = "5")
              span(style = "font-weight:bold;color:rgb(253, 104, 100)") Number of Backflows:
              br/
              .pt-1
                h1 {{ form.backflow_number }}

    .row.mb-3
      .col.text-center
        h2.mb-0
          strong Property Management Approval:
    .row.mb-0.ml-1
      .col-6
        |____________________________________
      .col-6
        |____________________________________
    .row.mb-2.ml-1
      .col-6
        |Signature
      .col-6
        |Title
    .row.mb-0.ml-1
      .col-6
        |____________________________________
      .col-6
        |____________________________________
    .row.mb-2.ml-1
      .col-6
        |Print
      .col-6
        |Date
    br/
    br/
    br/
    br/
    .row.mb-3.ml-1.mr-1
      .col
        | It is the customer’s responsibility to send out notices to all the residences notifying them of the date(s) that we will be out on property to perform the fire alarm, fire sprinkler and unit sprinkler head inspections.  If customer must reschedule any of the inspections there will be a $145.00 charge.


    .row.mb-3.bordered
      .col-1
      .col-2.text-danger.text-center Service
      .col-2.text-danger.text-center Sales
      .col-2.text-danger.text-center Inspections
      .col-2.text-danger.text-center Design
      .col-2.text-danger.text-center Installation
      .col-1
    .row
      .col
        .text-right.text-muted.pr-1 Copy Right, All Rights Reserved
    .row.mb-3
      .col
        .text-right.text-muted.pr-1 Revised 01/28/2020, Repair Bid Proposal

    .row.mb-3.ml-1.mr-1
      .col-6
        .row
          .col
            strong NFPA 72 – FIRE ALARM INSPECTIONS
        .row
          .col
            strong Inspect/Test/Tag:
        ul
          li System conditions
          li Identify compromises to system performance
          li System changes and/or modifications
          li Fire alarm control panels and related equipment
          li Tampers and waterflow switches
          li Low pressure alarms
          li Output Relays and test activation
          li Supervised control valves and switches
          li Audible/Visual Devices
          li Pull Stations
          li Elevator recall (if applicable)
          li Door Holders, smoke detectors and duct detectors
          li Ground fault detection circuitry and field wiring
          li Load test and inspection stand-by batteries
          li Verify monitoring
          li Tag panels as required per NFPA 72
          .row
            .col
              strong Report/Repairs:
          li Comprehensive report w/deficiencies
          li Familiarize client with proper use of equipment
          li Repair deficiencies will be sent on separate quote

      .col-6
        .row
          .col
            strong NFPA 72 – FIRE SPRINKLER INSPECTIONS
        .row
          .col
            strong Inspect/Test/Tag:
        ul
          li Inspect system condition
          li Identify compromises to system performance
          li Visual inspection of sprinkler heads
          li Inspect control valves accessibility and condition
          li Perform waterflow test for each system
          li Inspect all FDC’s
          li Inspect and Test all alarm components for proper operation
          li Inspect sprinkler pipe hangers and pipe where visual
          li Inspect riser gauges
          li Inspect and test air compressors (if applicable)
          li Test antifreeze system readings (if applicable)
          li Tag sprinkler risers as required per NFPA 25
          .row
            .col
              strong Report/Repairs:
          li Comprehensive report w/deficiencies
          li Familiarize client with proper use of equipment
          li Repair deficiencies will be sent on separate quote

    .row.mb-3.ml-1.mr-1
      .col-6
        .row
          .col
            strong NFPA 25 – FIRE PUMP INSPECTIONS
        .row
          .col
            strong Inspect/Test/Tag:
        ul
          li Inspect fire pump to determine satisfactory condition
          li Identify potentially compromises to performance
          li Inspect electrical components
          li Test performance
          li Inspect automatic pump test header
          li Inspect automatic alarm components
          li Check general condition of pump valves
          li Check general condition of pump drain
          li Check condition of gauges and equipment
          li Conduct the annual performance flow test
          li Tag devises as required per NFPA 25
          .row
            .col
              strong Report/Repairs:
          li Comprehensive report w/deficiencies
          li Familiarize client with proper use of equipment
          li Repair deficiencies will be sent on separate quote

      .col-6
        .row
          .col
            strong NFPA 25 – BACKFLOW INSPECTIONS
        .row
          .col
            strong Inspect/Test/Tag:
        ul
          li Inspect backflows for proper position and condition
          li Identify potential performance of mechanical components
          li Inspect for proper drainage of backflow
          li Conduct annual performance test and compile readings
          .row
            .col
              strong Report/Repairs:
          li Complete all inspection reports per City requirements
          li Turn in all inspection reports as required by City
          li Repair deficiencies will be sent on separate quote

    .row.mb-3.ml-1.mr-1
      .col
        .row
          .col
            strong NFPA 10 – FIRE EXTINGUISHER INSPECTIONS
        .row
          .col
            strong Inspect/Test/Tag:
        ul
          li Break extinguisher seal to remove locking pin
          li Replace seal with new seal for current year
          li For dry extinguishers, fluff the powder by turning unit upside down
          li Check hose for cracks
          li Check for proper installation and location
          li Check gauge condition
          li Check 6-yr maintenance or 12-yr hydro is within code requirements
          li Check the valve and shell for damage and/or corrosion
          li Check that extinguisher is visible and unobstructed
          li Verify that the extinguisher classification corresponds with hazard
          li Check unit location, path of travel and height
          li Check extinguisher cabinets for damage and locking properly (if applicable)
          li Tag extinguishers as required per NFPA 10
          .row
              .col
                strong Report/Repairs:
          li Comprehensive report w/deficiencies
          li Familiarize client with proper use of equipment
          li Repair deficiencies will be sent on a separate quote
</template>

<script>
  import '../../images/kodiak.jpg'

  export default {
    name: 'InspectionQuotePrint',

    data() {
      return {
        form: null,
        loaded: false,
        monthes: [
          {id: "1", name: "January" },
          {id: "2", name: "February" },
          {id: "3", name: "March" },
          {id: "4", name: "April" },
          {id: "5", name: "May" },
          {id: "6", name: "June" },
          {id: "7", name: "July" },
          {id: "8", name: "August" },
          {id: "9", name: "September" },
          {id: "10", name: "Octomber" },
          {id: "11", name: "November" },
          {id: "12", name: "December" },
        ]
      }
    },

    mounted() {
      const url = 'customers/' + this.$route.params.id + '/inspection_quotes/' + this.$route.params.inspection_quote_id

      console.log( url );

      this.$http.secured.get( url )
        .then( ( response ) => {
          this.form = response.data
          this.loaded = true
        })
    },

    methods: {
      yesno( value ) {
        return value ? 'Yes' : 'No';
      },
      selectedMonthName( form ) {
        for (let month of this.monthes) {
          if (parseInt(month.id) === form.month_due) {
            return month.name;
          }
        }
      },
      setServiceItemValue( price ) {
        return (Math.round(price * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

  }
</script>

<style scoped lang="scss">
  .signature {
    border: 1px solid;
    border-radius: 4px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 12px;
  }

  .pdf {
    font-size: 10px;
    width: 595px;
    background-color: white;
    margin: 0 auto;
    padding: 12px;
  }

  .bordered {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
</style>
