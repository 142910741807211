<template lang="pug">
  div.custom-control.custom-checkbox
    input.custom-control-input( type="checkbox"
                                :value="value"
                                @input="$emit( 'input', $event.target.checked )"
                                :checked="value"
                                :id="name")
    label.custom-control-label( :for="name" ) {{ label }}
</template>

<script>
  export default {
    name: "formCheckbox",

    props: {
      value: {
        type: [ Boolean, String ],
        required: false,
        default: null
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    }

  }
</script>

<style scoped>
</style>
