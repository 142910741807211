<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fas.fa-building.mr-2
          template( v-if="exists" )
            | Edit Fire Inspection Worksheet {{ form.number }}
          template( v-else )
            | New Fire Inspection Worksheet
      .card-body
        form( novalidate @submit.prevent="submit" )
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information
              .row.mb-2
                .col-md
                  .text-muted Customer Name
                  | {{form.constructiondetails.customer_name}}
                .col-md
                  .text-muted Customer Address
                  | {{form.constructiondetails.customer_address}}
                .col-md
                  .text-muted City
                  | {{form.constructiondetails.customer_city}}
                .col-md
                  .text-muted State
                  | {{form.constructiondetails.customer_state}}
                .col-md
                  .text-muted Zip
                  | {{form.constructiondetails.customer_zip}}
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Project Information
              .row.mb-2
                .col-md
                  .text-muted Project Number
                  |  {{form.constructiondetails.project_number}}
                .col-md
                  .text-muted Email
                  | {{form.constructiondetails.project_email}}
                .col-md
                  .text-muted Phone
                  | {{form.constructiondetails.project_phone}}
          .card.mb-3
            .card-body
              h5.card-title
                i.fas.fa-address-book.mr-2
                | New Fire Inspection Worksheet

              .row
                .col-md-2
                  form-datetime( :value="form.date_inspected"
                                    name="date_inspected"
                                    label="Inspection Date Time"
                                    :validation="$v.form.date_inspected"
                                    @input="form.date_inspected = $event; $v.form.date_inspected.$touch()" )
                //- .col-md-12
                //-   form-datetime( :value="form.inspection_time"
                //-                     name="inspection_time"
                //-                      label="Inspection Time"
                //-                     :validation="$v.form.inspection_time"
                //-                     @input="form.inspection_time = $event; $v.form.inspection_time.$touch()" )

              .row
                .col-md-3
                  form-input( :value="form.project_name"
                              name="project_name"
                              label="Project Name"
                              :validation="$v.form.project_name"
                              @input="form.project_name = $event; $v.form.project_name.$touch()" )
                .col-md-2
                  form-input( :value="form.billing_number"
                              name="billing_number"
                              label="Billing Number"
                              disabled=true
                              :validation="$v.form.billing_number"
                              @input="form.billing_number = $event; $v.form.billing_number.$touch()" )
              .row
                .col-md-3
                  form-input( :value="form.address"
                              name="address"
                              label="Address"
                              :validation="$v.form.address"
                              @input="form.address = $event; $v.form.address.$touch()" )
                .col-md
                  form-input( :value="form.city"
                              name="city"
                              label="City"
                              :validation="$v.form.city"
                              @input="form.city = $event; $v.form.city.$touch()" )
                .col-md
                  form-input( :value="form.state"
                              name="state"
                              label="State"
                              :validation="$v.form.state"
                              @input="form.state = $event; $v.form.state.$touch()" )
                .col-md
                  form-input( :value="form.zip"
                              name="zip"
                              label="zip"
                              :validation="$v.form.zip"
                              @input="form.zip = $event; $v.form.zip.$touch()" )
              .row
                .col-md-3
                  form-input( :value="form.contractor"
                                name="contractor"
                                label="Contractor"
                                :validation="$v.form.contractor"
                                @input="form.contractor = $event; $v.form.contractor.$touch()" )
                .col-md-3
                  form-input( :value="form.phone_number"
                                name="phone_number"
                                label="Phone Number"
                                :validation="$v.form.phone_number"
                                @input="form.phone_number = $event; $v.form.phone_number.$touch()" )
                .col-md-3
                  form-input( :value="form.city_inspector"
                                name="city_inspector"
                                label="City Inspector"
                                :validation="$v.form.city_inspector"
                                @input="form.city_inspector = $event; $v.form.city_inspector.$touch()" )
                .col-md-3
                  form-input( :value="form.kodiak_technician"
                                name="kodiak_technician"
                                label="Kodiak Technician"
                                :validation="$v.form.kodiak_technician"
                                @input="form.kodiak_technician = $event; $v.form.kodiak_technician.$touch()" )
                .col-md-3
                  form-radio( :value="form.fire_alarm_inspection"
                        :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false }]"
                        name="fire_alarm_inspection"
                        label="Fire Alarm Inspection"
                        :validation="$v.form.fire_alarm_inspection"
                        @input="form.fire_alarm_inspection = $event; $v.form.fire_alarm_inspection.$touch()" )
                .col-md-3
                  form-radio( :value="form.fire_sprinkler_inspection"
                        :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false }]"
                        name="fire_sprinkler_inspection"
                        label="Fire Sprinkler Inspection"
                        :validation="$v.form.fire_sprinkler_inspection"
                        @input="form.fire_sprinkler_inspection = $event; $v.form.fire_sprinkler_inspection.$touch()" )
                .col-md-3
                  form-radio( :value="form.did_fire_alarm_pass"
                        :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false }, { label: 'N/A', value: null }]"
                        name="did_fire_alarm_pass"
                        label="Did Fire Alarm Pass"
                        :validation="$v.form.did_fire_alarm_pass"
                        @input="form.did_fire_alarm_pass = $event; $v.form.did_fire_alarm_pass.$touch()" )
                .col-md-3
                  form-radio( :value="form.did_fire_sprinkler_pass"
                        :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false }, { label: 'N/A', value: null }]"
                        name="did_fire_sprinkler_pass"
                        label="Did Fire Sprinkler Pass"
                        :validation="$v.form.did_fire_sprinkler_pass"
                        @input="form.did_fire_sprinkler_pass = $event; $v.form.did_fire_sprinkler_pass.$touch()" )
              .row
                .col-md-6
                  form-textarea( :value="form.system_did_not_pass_reason"
                                name="system_did_not_pass_reason"
                                label="If system did not pass explain why"
                                rows=5
                                :validation="$v.form.system_did_not_pass_reason"
                                @input="form.system_did_not_pass_reason = $event; $v.form.system_did_not_pass_reason.$touch()" )
              .row
                .col-md-3
                  form-radio( :value="form.is_system_taken_off_test_and_put_back_on_line"
                        :options="[ { label: 'Yes', value: true  }, { label: 'No', value: false }]"
                        name="is_system_taken_off_test_and_put_back_on_line"
                        label="Is system taken off Test and put back on-line?"
                        :validation="$v.form.is_system_taken_off_test_and_put_back_on_line"
                        @input="form.is_system_taken_off_test_and_put_back_on_line = $event; $v.form.is_system_taken_off_test_and_put_back_on_line.$touch()" )
              .row
                .col-md-3
                  form-input( :value="form.technician_signature"
                                name="technician_signature"
                                label="Technician Signature"
                                :validation="$v.form.technician_signature"
                                @input="form.technician_signature = $event; $v.form.technician_signature.$touch()" )
                .col-md-3
                  form-input( :value="form.city_inspector_signature"
                                name="city_inspector_signature"
                                label="City Inspector Name"
                                :validation="$v.form.city_inspector_signature"
                                @input="form.city_inspector_signature = $event; $v.form.city_inspector_signature.$touch()" )
                .col-md-3
                  form-input( :value="form.contractor_signature"
                                name="contractor_signature"
                                label="Contractor Signature"
                                :validation="$v.form.contractor_signature"
                                @input="form.contractor_signature = $event; $v.form.contractor_signature.$touch()" )                  
          .row
            .col-md
              button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

              button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
              
              button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                i.fa.fa-file-pdf.mr-1
                | View Report
            .col-md
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import FormCheckbox from '../shared/form_checkbox'
  import FormDatetime from '../shared/form_datetime'
  import parseAddress from 'parse-address'
  import ServiceReports from '../service_reports/index'

  export default {
    name: 'fireinspectionworksheet_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormCheckbox,
      FormDatetime,
      ServiceReports
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        date_inspected: { required },
        // inspection_time: { required },
        project_name: { required },
        billing_number: { required },
        address: { required },
        city: { required },
        state: { required },
        zip: { required },
        contractor: { required },
        phone_number: { required },
        city_inspector: { required },
        kodiak_technician: { required },
        fire_alarm_inspection: { required },
        fire_sprinkler_inspection: { required },
        did_fire_alarm_pass: { },
        did_fire_sprinkler_pass: { },
        system_did_not_pass_reason: { },
        is_system_taken_off_test_and_put_back_on_line: { },
        technician_signature: { },
        city_inspector_signature: { },
        contractor_signature: { },
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'fireinspectionworksheet_new' ) {
          this.exists = false
          url = '/construction/' + this.$route.params.construction_id + '/fire_inspection_worksheet/new'
        }
        else {
          this.exists = true
          url = '/construction/' + this.$route.params.construction_id + '/fire_inspection_worksheet/' + this.$route.params.id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.fireinspection
            this.form.constructiondetails = response.data.constructiondetails
            this.loaded = true
            if(response.data.fireinspection.billing_number == null || response.data.fireinspection.billing_number.length==0)
              response.data.fireinspection.billing_number =  response.data.constructiondetails.project_number
          })

      },

      submit() {
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( '/construction/' + this.$route.params.construction_id + '/fire_inspection_worksheet/' + this.$route.params.id, { fire_inspection_worksheet: this.form } )
            .then( ( response ) => {
              this.form = response.data.fireinspection
              this.form.constructiondetails = response.data.constructiondetails
              this.$toastr.success( 'The fire inspectionwork sheet has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( '/construction/' + this.$route.params.construction_id + '/fire_inspection_worksheet', { fire_inspection_worksheet: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The fire inspection worksheet has been created successfully!' )
              this.$router.push( { name: 'fireinspectionworksheet', params: { construction_id: this.$route.params.construction_id } } )
            })
        }

      },
      downloadPDF() {
        this.$router.push( { name: 'fireinspectionworksheet_print', params: { id: this.form.id ,construction_id: this.form.construction_id } } )
      },
    }
  }
</script>

<style scoped>
</style>
