<template lang="pug">
  div( v-if="loaded" )
    .row
      .offset-md-3.col-md-6

        .card.border-secondary
          .card-header.bg-dark.text-white
            h4.mb-0
              i.fa.fa-user.mr-2
              template( v-if="exists" )
                | Edit User ({{ form.email }})
              template( v-else )
                | New User
          .card-body

            form( novalidate @submit.prevent="submit" )

              .card.mb-3
                .card-body
                  h5.card-title
                    i.fa.fa-user.mr-2
                    | User Information

                  .row
                    .col-md-6
                      form-input( :value="form.name"
                                  name="name"
                                  label="User's Full Name"
                                  :validation="$v.form.name"
                                  @input="form.name = $event; $v.form.name.$touch()" )
                    .col-md
                      form-input( :value="form.email"
                                  name="email"
                                  label="Email"
                                  :validation="$v.form.email"
                                  @input="form.email = $event; $v.form.email.$touch()" )
                  .row
                    .col-md-6
                      form-input( :value="form.username"
                                  name="username"
                                  label="Username"
                                  :validation="$v.form.username"
                                  @input="form.username = $event; $v.form.username.$touch()" )
                    .col-md
                      form-select( :value="form.role"
                                   name="role"
                                   :options="options"
                                   label="User Role"
                                   :validation="$v.form.role"
                                   @input="form.role = $event; $v.form.role.$touch()" )

              .card.mb-3
                .card-body
                  h5.card-title
                    i.fa.fa-user.mr-2
                    | Password
                    small.text-muted.ml-1 (Can be changed to self serve password reset later)

                  .row
                    .col-md-6
                      form-input( :value="form.password"
                                  type="password"
                                  name="password"
                                  label="Password"
                                  :validation="$v.form.password"
                                  @input="form.password = $event; $v.form.password.$touch()" )
                    .col-md
                      form-input( :value="form.password_confirmation"
                                  type="password"
                                  name="password_confirmation"
                                  label="Confirm Password"
                                  :validation="$v.form.password_confirmation"
                                  @input="form.password_confirmation = $event; $v.form.password_confirmation.$touch()" )

              .row
                .col-md
                  button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

                  button.btn.btn-link( @click.prevent="$router.go( -1 )" ) Cancel
                .col-md
                  div( style="position: absolute; right: 1em; bottom: 0;" )
                    i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                    | are
                    |
                    strong required
                    |
                    | fields

</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormSelect from '../shared/form_select'

  export default {
    name: 'UserEdit',

    components: {
      FormInput,
      FormSelect
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false,
        options: [ 'Management','Back Office', 'Tech','Construction' ]
      }
    },

    computed: {
      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      }
    },

    validations: {
      form: {
        name:                  { required },
        username:              { required },
        email:                 { email },
        role:                  { required },
        password:              {},
        password_confirmation: {},
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'user_new' ) {
          this.exists = false
          url = 'users/new'
        }
        else {
          this.exists = true
          url = 'users/' + this.$route.params.id
        }

        if (this.$route.name !== 'user_delete'){
          this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data
            this.loaded = true
          })
        }else{
          this.$http.secured.delete( url )
            .then( response => {
              this.$toastr.success( 'The User has been deleted successfully!' )
              this.$router.push( { name: 'users' } )
          })
        }
      },

      submit() {
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( 'users/' + this.$route.params.id, { user: this.form } )
            .then( ( response ) => {
              this.form = response.data
              this.$toastr.success( 'The User has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            }, error => this.handleHttpErrors(error))
        }
        else {
          this.$http.secured.post( 'users', { user: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The User has been created successfully!' )
              this.$router.push( { name: 'user_edit', params: { id: response.data.id } } )
            }, error => this.handleHttpErrors(error))
        }

      },
      handleHttpErrors(error){
          if(error.response.status == 422) {
            console.log(error.response)
            let error_details = ''
            for (let key in error.response.data.errors) {
                error_details += `${error.response.data.errors[key]}<br/>`
            }
            this.$toastr.error( error_details )
          }
          this.submitting = false
          this.$scrollToTop()
        }
    }
  }
</script>

<style scoped>
</style>
