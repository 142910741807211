<template lang="pug">
  div
    .card.border-secondary
      .card-header.bg-dark.text-white
        .float-right
          router-link.btn.btn-outline-light.btn-sm( :to="{ name: 'fireinspectionworksheet_new', params: { construction_id: construction_id} }" v-if="construction_id" )
            i.fas.fa-plus.mr-1
            | New Fire Inspection Worksheet
        h4.mb-0
          i.fas.fa-building.mr-2
          | Fire Inspection Worksheet
      .card-body

        .row.mb-2
          .col-md-1
            select#per-page.form-control( v-model.number="perPage" )
              option( value="1" ) 1
              option( value="10" ) 10
              option( value="25" ) 25
              option( value="50" ) 50
              option( value="100" ) 100

          .col-md-5
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}

          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

        table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
          thead.bg-secondary.text-white
            tr
              //- th( @click="changeSort( 'number' )" )
              //-   | Customer Number
              //-   i.fa.fas.ml-2( :class="sortClass( 'number' )" )
              th( @click="changeSort( 'date_inspected' )" )
                | Date Inspected
                i.fa.fas.ml-2( :class="sortClass( 'project_name' )" )
              th( @click="changeSort( 'project_name' )" )
                | Project Name
                i.fa.fas.ml-2( :class="sortClass( 'billing_number' )" )
              th( @click="changeSort( 'billing_number' )" )
                | Billing Number
                i.fa.fas.ml-2( :class="sortClass( 'billing_number' )" )
              th( @click="changeSort( 'contractor' )" )
                | Contractor
                i.fa.fas.ml-2( :class="sortClass( 'contractor' )" )
              th( @click="changeSort( 'phone_number' )" )
                | Phone Number
                i.fa.fas.ml-2( :class="sortClass( 'phone_number' )" )
              th( @click="changeSort( 'city_inspector' )" )
                | City Inspector
                i.fa.fas.ml-2( :class="sortClass( 'city_inspector' )" )
              th.action( width="10%" )
            tr
              //- th
              //-   .input-group
              //-     input.form-control.form-control-sm( :value="filters.number"
              //-                                         @input="debounceNumber"
              //-                                         placeholder="Filter Number" )
              //-     .input-group-append
              //-       button.btn.bg-white.btn-sm( @click="filters.number = ''" )
              //-         i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.date_inspected"
                                                      @input="debounceDate_inspected"
                                                      placeholder="Filter Date Inspected" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.date_inspected = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.project_name"
                                                      @input="debounceProject_name"
                                                      placeholder="Filter Project Name" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.project_name = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.billing_number"
                                                      @input="debounceBilling_number"
                                                      placeholder="Filter Billing Number" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.billing_number = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.contractor"
                                                      @input="debounceContractor"
                                                      placeholder="Filter Contractor" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.contractor  = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.phone_number"
                                                      @input="debouncePhone_number"
                                                      placeholder="Filter Phone Number" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.phone_number = ''" )
                      i.fas.fa-times
              th
                .input-group
                  input.form-control.form-control-sm( :value="filters.kodiak_technician"
                                                      @input="debounceKodiak_technician"
                                                      placeholder="Filter Kodiak Technician" )
                  .input-group-append
                    button.btn.bg-white.btn-sm( @click="filters.kodiak_technician = ''" )
                      i.fas.fa-times
              th.action
          tbody
            tr.pointer( v-for="row in data" :key="row.number" @click="gotoConstruction( row )" v-if="loaded" )
              td {{ row.date_inspected }}
              td {{ row.project_name }}
              td {{ row.billing_number }}
              td {{ row.contractor }}
              td {{ row.phone_number }}
              td {{ row.kodiak_technician }}
              td
                router-link( :to="{ name: 'fireinspectionworksheet_edit', params: { id: row.id ,construction_id: row.construction_id } }"
                             @click.native="$event.stopImmediatePropagation()"
                             v-if="$session.$isBackOffice()" )
                  i.fas.fa-pencil-alt.mr-2( v-tooltip="'Edit Fire Inspection Worksheet'" )

            tr( v-if="data.length === 0 && loaded" )
              td( colspan="8" )
                small.text-muted
                  | (No matching Fire Inspection Worksheet)

            tr( v-if="loading" )
              td( colspan="8" )
                i.fa.fa-spinner.fa-spin.fa-2x.mt-2( v-if="loading" )


        .row
          .col-md-6
            .d-flex.h-100.text-muted( v-if="loaded" )
              .align-self-center.pb-2 {{ paginateStatus }}
          .col-md-6
            .float-right( v-if="loaded" )
              pagination( :currentPage="page"
                          :total="count"
                          :page-size="perPage"
                          :callback="pageChanged"
                          :options="paginationOptions" )

</template>

<script>
  import tableUtils from '../../mixins/table_utils'
  import pagination from '../../mixins/paginate'

  export default {
    name: 'fireinspectionworksheetIndex',

    mixins: [ tableUtils ],

    components: { pagination },
    props: {
      construction_id: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        data:   [],
        loaded: false,
        loading: false,

        perPage: 10,
        count:   0,
        page:    1,

        filters: {
          customer_name:            '',
          customer_address:              '',
          customer_city:           '',
          pm_name:              '',
          job_name:      '',
          superintendent_name: ''
        },

        paginationOptions: {
          offset:             4,
          alwaysShowPrevNext: true
        }
      }
    },

    computed: {
      paginateStatus() {
        let start = ( this.page - 1 ) * this.perPage + 1
        let end = start + this.perPage - 1
        if ( end < this.perPage ) {
          end = this.perPage
        }
        else if ( end > this.count ) {
          end = this.count
        }
        return 'Showing ' + start + ' to ' + end + ' of ' + this.count + ' Fire Inspection Worksheet'
      }
    },

    watch: {
      perPage: 'fetchData',
      filters: { handler: function() { this.fetchData() }, deep: true }
    },

    mounted() {
      this.perPage = parseInt( localStorage.customerIndexPerPage ) || 10
      this.sortColumn = 'date_inspected'

      this.$watch( () => { return this.reverse }, () => this.fetchData() )
      this.$watch( () => { return this.sortColumn }, () => this.fetchData() )

      this.fetchData()
    },

    methods: {
      fetchData() {
        let url
        if ( this.construction_id ) {
          url = 'construction/' + this.construction_id + '/fire_inspection_worksheet'
        }
        else {
          url = 'fire_inspection_worksheet'
        }

        localStorage.customerIndexPerPage = this.perPage
        this.loaded = false
        this.loading = true

        this.$http.secured.get( url, { params: {
            page:           this.page,
            per_page:       this.perPage,
            sort_column:    this.sortColumn,
            sort_direction: this.reverse ? 'desc' : 'asc',
            filters:        this.filters
          }})
          .then( ( response ) => {
            this.data = response.data.fireinspectionworksheets
            this.count = response.data.count
            this.loaded = true
            this.loading = false
          })
      },

      gotoConstruction( construction ) {
        if ( this.$session.$isBackOffice() ) {
          this.$router.push( { name: 'fireinspectionworksheet_edit', params: { id: construction.id,construction_id:construction.construction_id } } )
        }
      },

      pageChanged( page ) {
        this.page = page
        this.fetchData()
      },

      debounceDate_inspected: _.debounce( function( e ) { this.filters.date_inspected = e.target.value }, 500 ),
      debounceProject_name: _.debounce( function( e ) { this.filters.project_name = e.target.value }, 500 ),
      debounceBilling_number: _.debounce( function( e ) { this.filters.billing_number = e.target.value }, 500 ),
      debounceContractor: _.debounce( function( e ) { this.filters.contractor = e.target.value }, 500 ),
      debouncePhone_number: _.debounce( function( e ) { this.filters.phone_number = e.target.value }, 500 ),
      debounceKodiak_technician: _.debounce( function( e ) { this.filters.kodiak_technician = e.target.value }, 500 )
    }
  }
</script>

<style scoped>
</style>
