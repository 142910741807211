<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary      
        form( novalidate @submit.prevent="submit" )
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information
              .row.mb-2
                .col-md
                  .text-muted Customer Name
                  | {{form.constructiondetails.customer_name}}
                .col-md
                  .text-muted Customer Address
                  | {{form.constructiondetails.customer_address}}
                .col-md
                  .text-muted City
                  | {{form.constructiondetails.customer_city}}
                .col-md
                  .text-muted State
                  | {{form.constructiondetails.customer_state}}
                .col-md
                  .text-muted Zip
                  | {{form.constructiondetails.customer_zip}}
          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Project Information
              .row.mb-2
                .col-md
                  .text-muted Project Number
                  |  {{form.constructiondetails.project_number}}
                .col-md
                  .text-muted Email
                  | {{form.constructiondetails.project_email}}
                .col-md
                  .text-muted Customer Phone
                  | {{form.constructiondetails.project_phone}}
          .card.mb-3
            .card-body
              h5.card-title
                i.fas.fa-address-book.mr-2
                | Daily Report Details

              .row
                .col-md
                  form-input( :value="form.sub_contractor_name"
                              name="sub_contractor_name"
                              label="Subcontractor Name"
                              :validation="$v.form.sub_contractor_name"
                              @input="form.sub_contractor_name = $event; $v.form.sub_contractor_name.$touch()" )
                .col-md
                  form-datetime( :value="form.start_date"
                              name="start_date"
                              label="Start Date"
                              :validation="$v.form.start_date"
                              @input="form.start_date = $event; $v.form.start_date.$touch()")
                .col-md
                  form-datetime( :value="form.end_date"
                                  name="end_date"
                                  label="End Date"
                                  :validation="$v.form.end_date"
                                  @input="form.end_date = $event; $v.form.end_date.$touch()" )
              .row
                .col-md
                  .card.border-secondary
                    .card-header.bg-dark.text-white
                      .float-right
                        button.btn.btn-outline-light.btn-sm(type='button' @click="addnewreportitem()" v-if="$session.$isBackOffice()" )
                          i.fas.fa-plus.mr-1
                          | Add New Entry
                    table.table.table-sm.table-bordered.table-hover.table-stripped.table-sortable.w-100.d-block.d-md-table.table-responsive
                      thead.bg-secondary.text-white
                        tr
                          th Arrival Date
                          th Departure Date
                          th Hours
                          th Description of Work
                      tbody
                        tr.pointer( v-for="row in form_items" :key="row.id" v-if="loaded" )
                          td
                            form-datetime( :value="row.report_item_arrival_date"
                                            name="report_item_arrival_date"
                                            :validation="$v.form_items.report_item_arrival_date"
                                            @input="row.report_item_arrival_date = $event; $v.row.report_item_arrival_date.$touch()")

                          td
                            form-datetime( :value="row.report_item_departure_date"
                                            name="report_item_departure_date"
                                            :validation="$v.form_items.report_item_departure_date"
                                            @input="row.report_item_departure_date = $event; $v.row.report_item_departure_date.$touch()")
                          td
                            form-input( :value="row.report_item_hours"
                                            name="report_item_hours"
                                            :validation="$v.form_items.report_item_hours"
                                            @input="row.report_item_hours = $event; $v.row.report_item_hours.$touch()")
                          td
                            form-input( :value="row.description_of_work"
                                            name="description_of_work"
                                            :validation="$v.form_items.description_of_work"
                                            @input="row.description_of_work = $event; $v.row.description_of_work.$touch()")
                        tr(v-if="form_items.length==0")
                          td(:colspan="4") No Records
              .row
                .col-md
                  form-input( :value="form.sub_contractor_signature"
                              name="sub_contractor_signature"
                              label="Subcontractor Signature"
                              :validation="$v.form.sub_contractor_signature"
                              @input="form.sub_contractor_signature = $event; $v.form.sub_contractor_signature.$touch()")
                .col-md
                  form-input( :value="form.approved_by"
                              name="approved_by"
                              label="Approved By"
                              :validation="$v.form.approved_by"
                              @input="form.approved_by = $event; $v.form.approved_by.$touch()")
                //- .col-md
                //-   .card( v-if="exists" )
                //-     .card-body.bg-light.p-2
                //-       table.table-sm
                //-         tbody
                //-           tr
                //-             td.text-right.text-muted.pb-0 Created At:
                //-             td.pb-0 {{ form.created_at | datetime }}
                //-           tr
                //-             td.text-right.text-muted.pb-0 Updated At:
                //-             td.pb-0 {{ form.updated_at | datetime }}
                //-           tr
                //-             td.text-right.text-muted.pb-0 Last Updated By:
                //-             td.pb-0 {{ form.updating_user.name }}
              .row
                .col-md
                  button.btn.btn-primary( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}

                  button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
                  button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                    i.fa.fa-file-pdf.mr-1
                    | View Report
                .col-md
                  div( style="position: absolute; right: 1em; bottom: 0;" )
                    i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                    | are
                    |
                    strong required
                    |
                    | fields
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'
  import FormInput from '../../shared/form_input'
  import FormTextarea from '../../shared/form_textarea'
  import FormRadio from '../../shared/form_radio'
  import FormSelect from '../../shared/form_select'
  import FormDatetime from '../../shared/form_datetime'
  import parseAddress from 'parse-address'
  import ServiceReports from '../../service_reports/index'

  export default {
    name: 'subcontractor_daily_activity_report_edit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormDatetime,
      ServiceReports
    },

    data() {
      return {
        form: null,
        form_items: [],
        is_editable: false,
        loaded: false,
        exists: false,
        submitting: false
      }
    },

    computed: {
      customerNumber() {
        if ( !this.exists ) {
          let n = this.$moment().format( 'YY' ) + '-'

          if ( !_.isEmpty( this.form.address ) ) {
            const p = parseAddress.parseLocation( this.form.address )
            if ( p.number ) {
              n += p.number

              if ( !_.isEmpty( this.form.building_type ) ) {
                n += this.form.building_type[0]
              }
            }
          }

          this.form.number = n

          return n
        }
        else {
          return null
        }
      },

      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        sub_contractor_name: { required },
        start_date: { required },
        end_date: { required },
        sub_contractor_signature: {},
        approved_by: { required }
      },
      form_items:{
        report_item_arrival_date:{},
        report_item_departure_date:{},
        report_item_hours:{},
        description_of_work:{},
        subcontractor_daily_activity_report_id:{}
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = null
        if ( this.$route.name === 'subcontractor_daily_activity_report_new' ) {
          this.exists = false
          url = 'construction/' + this.$route.params.construction_id + '/subcontractor/daily_activity_report/new'
        }
        else {
          this.exists = true
          url = 'construction/' + this.$route.params.construction_id + '/subcontractor/daily_activity_report/' + this.$route.params.id
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data.daily_report
            this.form_items = response.data.daily_report_items
            this.form.constructiondetails = response.data.constructiondetails
            this.loaded = true
          })

      },
      addnewreportitem(){
        this.form_items.push({
        report_item_arrival_date:'',
        report_item_departure_date:'',
        report_item_departure_date:'',
        description_of_work:'',
        subcontractor_daily_activity_report_id:this.form.id
      })
      },

      submit() {
        this.submitting = true
        if ( this.exists ) {
          this.$http.secured.patch( 'construction/' + this.$route.params.construction_id + '/subcontractor/daily_activity_report/' + this.$route.params.id, { daily_report: this.form, daily_report_items:this.form_items} )
            .then( ( response ) => {
              this.form = response.data
              this.form_items = response.data.daily_report_items
              this.form.constructiondetails = response.data.constructiondetails
              this.$toastr.success( 'The Daily Report has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( 'construction/' + this.$route.params.construction_id + '/subcontractor/daily_activity_report', { daily_report: this.form, daily_report_items:this.form_items} )
            .then( ( response ) => {
              this.$toastr.success( 'The Daily Report has been created successfully!' )
              this.$router.push( { name: 'subcontractor_daily_activity_report', params: { construction_id: this.$route.params.construction_id } } )
            })
        }
      },
      downloadPDF() {
        this.$router.push( { name: 'subcontractor_daily_activity_report_print', params: { id: this.form.id,construction_id: this.form.construction_id } } )
      }
    }
  }
</script>

<style scoped>
</style>
