<template lang="pug">
  div.form-group
    label( :for="name" )
      i.fas.fa-asterisk.fa-xs.text-danger.mr-1( v-if="validation.hasOwnProperty( 'required' )" )
      | {{ label }}
    select.form-control( :value="value"
                         @input="onInput"
                         :name="name"
                         :id="name" )
      option( value="" )
      option( v-for="option in options" :value="option" v-if="options" ) {{ option }}
      slot

    small.form-text.text-muted( v-if="hint" )
      | {{ hint }}
    span.invalid-feedback( v-if="validation.hasOwnProperty( 'required' ) && !validation.required && validation.$error" )
      | can't be blank
</template>

<script>
  export default {
    name: "formSelect",

    props: {
      value: {
        type: [ Number, String ],
        required: false,
        default: null
      },
      validation: Object,
      name: String,
      label: String,
      hint: String,
      options: {
        type: Array,
        required: false,
        default: null
      },
      trim: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      onInput( event ) {
        const value = event.target.value;

        this.$emit( 'input', this.trim ? value.trim() : value );
      }
    }
  }
</script>

<style scoped>
</style>
