/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from 'vue/dist/vue.esm'

import Dropdown from 'vue-simple-search-dropdown';

import Tooltip from '../directives/tooltip'
Vue.directive( 'tooltip', Tooltip )

import Session from './session'
Vue.use( Session )

import router from './router.js'

import ActionCableVue from 'actioncable-vue';

Vue.use( ActionCableVue, {
  debug:              true,
  debugLevel:         'error',
  connectionUrl:      '/cable',
  connectImmediately: true
} );

import { securedAxiosInstance, plainAxiosInstance } from './axios'
import VueAxios from 'vue-axios'
Vue.use( VueAxios, {
  secured: securedAxiosInstance,
  plain:   plainAxiosInstance
} )

import Vuelidate from 'vuelidate'
Vue.use( Vuelidate )

import moment from 'moment'
Object.defineProperty(Vue.prototype, '$moment', { value: moment })

import toastr from 'toastr'
toastr.options.progressBar = true
toastr.options.positionClass = 'toast-top-center'
Object.defineProperty(Vue.prototype, '$toastr', { value: toastr })

Vue.filter( 'dayname', ( m ) => moment( m ).format( "dddd" ) )
Vue.filter( 'datetime', ( m ) => moment( m ).format( "M/D/YYYY, h:mma" ) )
Vue.filter( 'date', ( m ) => moment( m ).format( "M/D/YYYY" ) )

import Dinero from 'dinero.js'
Vue.filter( 'price', ( m ) => m ? Dinero( { amount: m, precision: 2 } ).toUnit().toFixed( 2 ) : null )

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

Vue.config.productionTip = false
const app = new Vue( {
  router,
  securedAxiosInstance,
  plainAxiosInstance
} ).$mount( '#app' )

