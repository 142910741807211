<template lang="pug">
  div
    nav.navbar.fixed-top.navbar-dark.bg-dark.navbar-expand-lg
      router-link.navbar-brand( :to="{ name: 'dashboard' }" ) KFP
      button.navbar-toggler( type="button" data-toggle="collapse" data-target="#navbar-contents" )
        span.navbar-toggler-icon

      #navbar-contents.collapse.navbar-collapse
        ul.navbar-nav.mr-auto
          li.nav-item.dropdown( v-if="$session.$isBackOffice() || $session.$isTech()" )
            a#navbarDropdownMenuLink.nav-link.dropdown-toggle(role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
              i.fas.fa-building.mr-2
              | Customers
            div.dropdown-menu(aria-labelledby="navbarDropdownMenuLink")
              router-link.dropdown-item(:to="{ name: 'customers' }" )
                i.fas.fa-building.mr-2
                | Customers List
              router-link.dropdown-item(:to="{ name: 'service_reports' }" )( v-if="!$session.$isTech()" )
                i.fas.fa-building.mr-2
                | Service Tickets
              router-link.dropdown-item(:to="{ name: 'repair_quotes' }" )( v-if="!$session.$isTech()" )
                i.fas.fa-building.mr-2
                | Repair Quotes
              router-link.dropdown-item(:to="{ name: 'inspection_quotes' }" )( v-if="!$session.$isTech()" )
                i.fas.fa-building.mr-2
                | Annual inspection Quotes
          li.nav-item.dropdown( v-if="$session.$isBackOffice()" )
            a#constructionavbarDropdownMenuLink.nav-link.dropdown-toggle(role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
              i.fas.fa-building.mr-2
              | Construction
            div.dropdown-menu(aria-labelledby="constructionavbarDropdownMenuLink")
              router-link.dropdown-item(:to="{ name: 'construction' }" )
                i.fas.fa-building.mr-2
                | Construction List
              router-link.dropdown-item(:to="{ name: 'fireinspectionworksheet' }" )
                i.fas.fa-building.mr-2
                | Fire Inspection Worksheet
              router-link.dropdown-item(:to="{ name: 'subcontractor_daily_activity_report' }" )
                i.fas.fa-building.mr-2
                | Subcontractor Daily Activity Report
              router-link.dropdown-item(:to="{ name: 'subcontractor_job_order' }" )
                i.fas.fa-building.mr-2
                | Subcontractor Job Order
          li.nav-item.dropdown( v-if="!$session.$isTech()" )
            a#navbarDropdownMenuLink.nav-link.dropdown-toggle(role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
              i.fas.fa-building.mr-2
              | Employees
            div.dropdown-menu
              router-link.dropdown-item(:to="{ name: 'employees' }" )
                i.fas.fa-building.mr-2
                | Employee List
              router-link.dropdown-item(:to="{ name: 'timeoff_requests' }" )
                i.fas.fa-building.mr-2
                | Time Off Requests
          li.nav-item( v-if="$session.$isBackOffice()" )
            //a.nav-link.disabled
              i.fa.fa-city.mr-2
              | Cities
            router-link.nav-item( tag="li" :to="{ name: 'users' }" v-if="$session.$isBackOffice()" )
              a.nav-link
                i.fa.fa-user.mr-2
                | Users
        span.navbar-text
          | Hello {{ user.name }}
          button.btn.btn-outline-secondary.btn-sm.mx-2.my-0( v-tooltip="'Log out'" @click="logout" )
            i.fas.fa-sign-out
          small( v-if="env !== 'production'" )
            | ({{ env }})

    .container-fluid.application-padding
      #app-spinner.float-right( style="display: none" )
        i.fas.fa-circle-notch.fa-2x.text-primary.fa-spin

      #application
        router-view( :key="$route.fullPath" )

      br/
      .text-center
        i.fa.fa-copyright.mr-1
        | {{ $moment().format( "YYYY" ) }} Kodiak Fire Protection. All rights reserved.
      br/

</template>

<script>
  import 'bootstrap/js/dist/collapse.js'
  import 'bootstrap/js/dist/dropdown.js'
  import Push from 'push.js'

  export default {
    name: 'dashboard',

    data() {
      return {
      }
    },

    computed: {
      env() {
        return process.env.RAILS_ENV
      },

      user() {
        return this.$session.$currentUser()
      }
    },

    mounted() {
      //this.$cable.subscribe({ channel: 'TicketNotifierChannel' });
    },

    methods: {
      logout() {
        this
          .$http.secured.delete( 'sessions/a' )
          .then( () => {
            this.$session.$logout()
            this.$router.push( { name: 'login' } )
          })
      }
    },

    /*channels: {
      TicketNotifierChannel: {
        received( data ) {
          if ( data.state === 'Dispatched' ) {
            Push.create( data.title, {
              body:    data.body,
              onClick: function () {
                window.focus()
                this.close()
              }
            } )
          }
          if ( this.$route.name === 'dashboard' ) {
            this.$router.go()
          }
        }
      }
    }*/

  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Lato');
  $fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';

  @import '../../node_modules/bootstrap/scss/bootstrap';
  @import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
  @import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
  @import '../../node_modules/@fortawesome/fontawesome-free/scss/regular';

  @import '../../node_modules/toastr/toastr';

  html, body {
    font-family: 'Lato', sans-serif;
  }

  body {
    background-color: $gray-200;
  }

  .application-padding {
    padding-top: 4.5em;
  }

  .table-sortable {
    th {
      cursor: pointer;
      &.action {
        cursor: default;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }


  $nprogress-color: $primary;
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #app-spinner {
    display: block;
    position: fixed;
    z-index: 1070;
    top: 4em;
    right: 15px;
  }

  #nprogress .bar {
    background: $nprogress-color;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $nprogress-color, 0 0 5px $nprogress-color;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

</style>
