<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fa.fa-calendar-check.mr-2
          template( v-if="exists" )
            | Edit Repair Quote {{ form.quote_number }}
          template( v-else )
            | New Repair Quote
      .card-body

        form( novalidate @submit.prevent="submit" )

          .card.mb-3
            .card-body
              .float-right
                span.lead( v-if="exists" ) No: {{ form.quote_number }}
                span.lead( v-else v-tooltip="'The ID will be filled in after creation'" )
                  | No: {{ $moment().format( 'YY' ) }}-
                  i.fa.fa-question-circle.text-muted
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information

              .row.mb-2
                .col-md
                  .text-muted Customer Number
                  | {{ form.customer.number }}
                .col-md
                  .text-muted Customer Name
                  | {{ form.customer.name }}
                .col-md
                  .text-muted Customer Phone
                  | {{ form.customer.phone_number }}
                .col-md
                  .text-muted Fire Account #
                  | {{ form.customer.fire_alarm_number }}
              .row
                .col-md-6
                  .text-muted Customer Address
                  | {{ form.customer.address_formatted }}
                .col-md
                  .text-muted Manager Name
                  | {{ form.customer.manager_name }}
                .col-md
                  .text-muted Manager Email
                  | {{ form.customer.manager_email }}


          .card.mb-3
            .card-body
              .float-right
              .row
                .col-md-6
                  form-textarea( :value="form.scope_of_work"
                                 name="scope_of_work"
                                 label="Scope of Work"
                                 :validation="$v.form.scope_of_work"
                                 :disabled="$session.$isTech() && exists"
                                 rows="5"
                                 @input="form.scope_of_work = $event; $v.form.scope_of_work.$touch()" )
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.reference_number"
                                  name="reference_number"
                                  label="Reference Number"
                                  :disabled="$session.$isTech() && exists"
                                  :validation="$v.form.reference_number"
                                  @input="form.reference_number = $event; $v.form.reference_number.$touch()" )
                  .row
                    .col-md
                      label
                        | Price
                      input.form-control( type="number" :value="form.price"
                      :disabled="$session.$isTech() && exists"
                      @change="setServiceItemValue( form, $event.target.value )" )
                      

          .row
            .col-md-8
              button.btn.btn-primary.mr-1( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
              button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
              button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                i.fa.fa-file-pdf.mr-1
                | View Report
              button.btn.btn-secondary.mr-5( @click.prevent="sentEmail" v-if="exists" )
                i.far.fa-envelope.mr-1
                | Email Report
              button.btn.btn-danger.mr-5( @click.prevent="deleteRepairquote" v-if="exists" )
                i.fa.fa-trash.mr-1
                | Delete Repair Quote
            .col-md-4
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormInputWithoutLabel from '../shared/form_input_without_label'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import FormCheckbox from '../shared/form_checkbox'
  import FormDatetime from '../shared/form_datetime'
  import VueSignaturePad from 'vue-signature-pad';

  // import Dinero from 'dinero.js'

  import 'bootstrap/js/dist/modal'

  export default {
    name: 'RepairQuoteEdit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormCheckbox,
      FormInputWithoutLabel,
      FormDatetime,
      VueSignaturePad
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false,
      }
    },

    computed: {
      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },
    },

    validations: {
      form: {
        reference_number:   {required},
        scope_of_work:      {},
        price:              {required},
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = 'customers/' + this.$route.params.id + '/repair_quotes/'
        if ( this.$route.name === 'repair_quote_new' ) {
          this.exists = false
          url += 'new'
        }
        else {
          this.exists = true
          url += this.$route.params.repair_quote_id
        }

        if (this.$route.name !== 'repair_quote_delete'){
          this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data
              this.loaded = true
            })
        }else{
          this.$http.secured.delete( url )
            .then( response => {
              this.$toastr.success( 'The Repair Quote has been deleted successfully!' )
              this.$router.push( { name: 'repair_quotes' } )
          })
        }
      },

      submit() {
        this.submitting = true
        let url = 'customers/' + this.$route.params.id  + '/repair_quotes'
        if ( this.exists ) {
          url += '/' + this.$route.params.repair_quote_id
          this.$http.secured.patch( url, { repair_quote: this.form } )
            .then( ( response ) => {
              this.form = response.data
              this.$toastr.success( 'The Repair Quote has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( url, { repair_quote: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The Repair Quote has been created successfully!' )
              this.$router.push( {
                name: 'repair_quote_edit',
                params: { id: this.$route.params.id, repair_quote_id: response.data.id }
              } )
            })
        }
      },
      deleteRepairquote() {
        let url = 'customers/' + this.$route.params.id + '/repair_quotes/' + this.$route.params.repair_quote_id
        this.$http.secured.delete( url )
          .then( response => {
            this.$toastr.success( 'The Repair quote has been deleted successfully!' )
            this.$router.push( { name: 'repair_quotes' } )
        })
      },
      
      downloadPDF() {
        this.$router.push( { name: 'repair_quote_print', params: { id: this.form.customer_id, repair_quote_id: this.form.id } } )
      },

      setServiceItemValue( form, price ) {
        form.price = (Math.round(price * 100) / 100).toFixed(2)
      },

      sentEmail() {
        this.$http.secured
          .post( 'customers/' + this.form.customer_id + '/repair_quotes/' + this.form.id + '/sent_email', {} )
          .then( ( response ) => {
            this.form = response.data
            this.$toastr.success( 'Repair Quote Email' + this.form.quote_number + ' has been set to ' + this.form.customer.manager_email )
          })
      }

    }
  }
</script>