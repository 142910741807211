<template lang="pug">
  div( v-if="loaded" )
    .row
      .col
        .card.border-secondary
          .card-header.bg-dark.text-white
            h4.mb-0
              i.fa.fa-tachometer-alt-fast.mr-2
              | Dashboard
          .card-body

            .row.mb-2( v-if="$session.$isBackOffice()" )
              .col
                .card.bg-light
                  .card-body
                    ul.list-inline.mb-0
                      li.list-inline-item.mr-4
                        span.text-muted
                          i.fa.fa-user.fa-fw
                          | Tech Filter:
                      li.list-inline-item.mr-4( v-for="tech in techs" )
                        .custom-control.custom-checkbox
                          input.custom-control-input( type="checkbox"
                                                      :checked="techChecked( tech )"
                                                      @click="toggleTechCheck( tech )"
                                                      :id="'tech-' + tech.id" )
                          label.custom-control-label( :for="'tech-' + tech.id" ) {{ tech.name }}

            .row.pointer.mb-2( v-for="ticket in filteredTickets" @click="gotoServiceTicket( ticket )" )
              .col
                .card.bg-light
                  .card-header
                    h5.card-title.mb-0
                      i.fa.fa-calendar-check.mr-2
                      | {{ ticket.customer.name }}
                      span.ml-3
                      | {{ ticket.service_date | datetime }}
                  .card-body
                    .row.mb-1
                      .col
                        .row
                          .col
                            .row
                              .col
                                i.fa.fa-id-card-alt.mr-1.fa-fw
                                | {{ ticket.contact }}
                              .col
                                i.fa.fa-phone-alt.mr-1.fa-fw
                                | {{ ticket.customer.phone_number }}
                          .col
                            .row
                              .col
                                i.fa.fa-user.fa-fw.mr-1
                                | Assigned Techs:
                                |
                                template( v-for="( tech, index ) in ticket.service_report_techs" )
                                  | {{ tech.tech.name }}
                                  template( v-if="tech.primary" )
                                    i.fa.fa-asterisk.ml-1.fa-sm
                                  template( v-if="index < ticket.service_report_techs.length - 1" )
                                    |,
                                    |
                    .row.mb-1
                      .col
                        i.fa.fa-map-marker-alt.mr-1.fa-fw
                        a( :href="'https://www.google.com/maps/search/?api=1&query=' + encodeURI( ticket.customer.address_formatted )"
                           target="_blank" @click.stop="" )
                          | {{ ticket.customer.address_formatted }}
                    .row
                      .col
                        i.fa.fa-thumbtack.mr-1.fa-fw
                        | {{ ticket.service_requested }}
            .row.mb-2( v-if="filteredTickets.length === 0" )
              .col
                .text-muted.lead
                  i (Currently, No Tickets for Today)
</template>

<script>
  export default {
    name: 'dashboard',

    data() {
      return {
        loaded: false,
        tickets: [],
        techs: [],
        selectedTechs: []
      }
    },

    computed: {
      filteredTickets() {
        return _.filter( this.tickets, ( ticket ) => {
          const ticketTechIds = _.map( ticket.service_report_techs, 'tech_id' )
          return _.intersection( this.selectedTechs, ticketTechIds ).length > 0
        } )
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        this.$http.secured.get( 'dashboard' )
          .then( ( response ) => {
            this.tickets = response.data
            this.loaded = true
          })

        this.$http.secured.get( 'techs' ).then( ( response ) => {
          this.techs = response.data
          this.selectedTechs = _.map( this.techs, 'id' )
        } )
      },

      gotoServiceTicket( ticket ) {
        this.$router.push( { name: 'service_ticket_edit', params: { id: ticket.customer.id, service_ticket_id: ticket.id } } )
      },

      techChecked( tech ) {
        return _.includes( this.selectedTechs, tech.id )
      },

      toggleTechCheck( tech ) {
        if ( _.includes( this.selectedTechs, tech.id ) ) {
          this.selectedTechs = _.without( this.selectedTechs, tech.id )
        }
        else {
          this.selectedTechs.push( tech.id )
        }
      }
    }
  }
</script>

<style scoped>
</style>
