<template lang="pug">
  div( v-if="loaded" )
    .card.border-secondary
      .card-header.bg-dark.text-white
        h4.mb-0
          i.fa.fa-calendar-check.mr-2
          template( v-if="exists" )
            | Edit Service Ticket {{ form.number }}
          template( v-else )
            | New Service Ticket
      .card-body

        form( novalidate @submit.prevent="submit" )

          .card.mb-3
            .card-body
              .float-right
                span.lead.mr-3 ({{form.state}})
                span.lead( v-if="exists" ) No: {{ form.number }}
                span.lead( v-else v-tooltip="'The ID will be filled in after creation'" )
                  | No: {{ $moment().format( 'YY' ) }}-
                  i.fa.fa-question-circle.text-muted
              h5.card-title
                i.fa.fa-address-book.mr-2
                | Customer Information

              .row.mb-2
                .col-md
                  .text-muted Customer Number
                  | {{ form.customer.number }}
                .col-md
                  .text-muted Customer Name
                  | {{ form.customer.name }}
                .col-md
                  .text-muted Customer Phone
                  | {{ form.customer.phone_number }}
                .col-md
                  .text-muted Fire Account #
                  | {{ form.customer.fire_alarm_number }}
              .row
                .col-md-6
                  .text-muted Customer Address
                  | {{ form.customer.address_formatted }}
                .col-md
                  .text-muted Manager Name
                  | {{ form.customer.manager_name }}
                .col-md
                  .text-muted Manager Email
                  | {{ form.customer.manager_email }}


          .card.mb-3
            .card-body
              .float-right
              h5.card-title
                i.fa.fa-tools.mr-2
                | Service Requested

              .row
                .col-md-6
                  form-textarea( :value="form.service_requested"
                                 name="service_requested"
                                 label="Service Requested"
                                 :validation="$v.form.service_requested"
                                 :disabled="$session.$isTech() && exists"
                                 rows="5"
                                 @input="form.service_requested = $event; $v.form.service_requested.$touch()" )
                .col-md-3
                  .row
                    .col-md
                      form-input( :value="form.contact"
                                  name="contact"
                                  label="Contact"
                                  :disabled="$session.$isTech() && exists"
                                  :validation="$v.form.contact"
                                  @input="form.contact = $event; $v.form.contact.$touch()" )
                  .row
                    .col-md
                      form-datetime( :value="form.service_date"
                                     name="service_date"
                                     label="Service Date"
                                     :disabled="$session.$isTech() && exists"
                                     :validation="$v.form.service_date"
                                     @input="form.service_date = $event; $v.form.service_date.$touch()" )
                .col-md-3
                  h5 Techs
                  .row.mb-1( v-for="( tech, index ) in service_report_techs_not_destroyed" )
                    .col-md-6
                      select.form-control( v-model="tech.tech_id" :disabled="$session.$isTech() && exists" )
                        option( value="" ) {{ }}
                        option( v-if="tech.tech_id" :value="tech.tech_id" ) {{ techName( tech ) }}
                        option( v-for="t in availableTechs" :value="t.id" )
                          | {{ t.name }}
                    .col-md-3
                      .custom-control.custom-checkbox
                        input.custom-control-input( type="checkbox"
                                                    v-model="tech.primary"
                                                    @change="togglePrimaryTech( tech )"
                                                    :disabled="!tech.tech_id || ( $session.$isTech() && exists )"
                                                    :id="'i-' + index" )
                        label.custom-control-label( :for="'i-' + index" )
                          | Primary?
                    .col-md-3( v-if="$session.$isBackOffice()" )
                      button.btn.btn-link.p-0( @click.prevent="addTech"
                                           v-tooltip="'Add an additional tech'" )
                        i.fa.fa-plus-square.fa-2x
                      button.btn.btn-link.p-0.ml-1( @click.prevent="removeTech( tech )"
                                           v-tooltip="'Remove this tech'" )
                        i.fa.fa-minus-square.fa-2x

          .card.mb-3
            .card-body
              h5.card-title
                i.fa.fa-tools.mr-2
                | Services Performed

              .row
                .col-md
                  form-checkbox( :value="form.fire_alarm"
                                 name="fire_alarm"
                                 label="Fire Alarm"
                                 @input="form.fire_alarm = $event; $v.form.fire_alarm.$touch()" )
                .col-md
                  form-checkbox( :value="form.fire_riser_backflow"
                                 name="fire_riser_backflow"
                                 label="Fire Riser Backflow"
                                 @input="form.fire_riser_backflow = $event; $v.form.fire_riser_backflow.$touch()" )
                .col-md
                  form-checkbox( :value="form.antifreeze_systems"
                                 name="antifreeze_systedatePickerms"
                                 label="Antifreeze System"
                                 @input="form.antifreeze_systems = $event; $v.form.antifreeze_systems.$touch()" )
                .col-md
                  .row
                    .col-md-auto
                      form-checkbox( :value="form.quote"
                                     name="quote"
                                     label="Quote:"
                                     @input="form.quote = $event; $v.form.quote.$touch()" )
                    .col-md-auto.pl-0
                      form-input-without-label.mb-0(
                        :value="form.quote_id"
                        name="quote_id"
                        input-class="form-control-sm"
                        :validation="$v.form.quote_id"
                        @input="form.quote_id = $event; $v.form.quote_id.$touch()" )

              .row
                .col-md
                  form-checkbox( :value="form.fire_sprinkler"
                                 name="fire_sprinkler"
                                 label="Fire Sprinkler"
                                 @input="form.fire_sprinkler = $event; $v.form.fire_sprinkler.$touch()" )
                .col-md
                  form-checkbox( :value="form.irrigation_backflow"
                                 name="irrigation_backflow"
                                 label="Irrigation Backflow"
                                 @input="form.irrigation_backflow = $event; $v.form.irrigation_backflow.$touch()" )
                .col-md
                  form-checkbox( :value="form.warranty"
                                 name="warranty"
                                 label="Warranty"
                                 @input="form.warranty = $event; $v.form.warranty.$touch()" )
                .col-md
                  .row
                    .col-md-auto
                      form-checkbox( :value="form.charge"
                                     name="charge"
                                     label="Charge:"
                                     @input="form.charge = $event; $v.form.charge.$touch()" )
                    .col-md-auto.pl-0
                      form-input-without-label.mb-0(
                        :value="form.charge_amount"
                        name="charge_amount"
                        input-class="form-control-sm"
                        :validation="$v.form.charge_amount"
                        @input="form.charge_amount = $event; $v.form.charge_amount.$touch()" )
              .row
                .col-md
                  form-checkbox( :value="form.fire_extinguishers"
                                 name="fire_extinguishers"
                                 label="Fire Extinguisher"
                                 @input="form.fire_extinguishers = $event; $v.form.fire_extinguishers.$touch()" )
                .col-md
                  form-checkbox( :value="form.domestic_backflow"
                                 name="domestic_backflow"
                                 label="Domestic Backflow"
                                 @input="form.domestic_backflow = $event; $v.form.domestic_backflow.$touch()" )
                .col-md
                  form-checkbox( :value="form.service_agreement"
                                 name="service_agreement"
                                 label="Service Agreement"
                                 @input="form.service_agreement = $event; $v.form.service_agreement.$touch()" )
                .col-md
                  .row
                    .col-md-auto
                      form-checkbox( :value="form.other"
                                     name="other"
                                     label="Other:"
                                     @input="form.other = $event; $v.form.other.$touch()" )
                    .col-md-auto.pl-0
                      form-input-without-label.mb-0(
                        :value="form.other_details"
                        name="other_details"
                        input-class="form-control-sm"
                        :validation="$v.form.other_details"
                        @input="form.other_details = $event; $v.form.other_details.$touch()" )

              .row.mb-3
                .col-md
                  form-checkbox( :value="form.fire_hydrants"
                                 name="fire_hydrants"
                                 label="Fire Hydrants"
                                 @input="form.fire_hydrants = $event; $v.form.fire_hydrants.$touch()" )
                .col-md
                  form-checkbox( :value="form.access_control"
                                 name="access_control"
                                 label="Access Control"
                                 @input="form.access_control = $event; $v.form.access_control.$touch()" )

              .row.mb-3
                .col-md
                  .row
                    .col-md-1 Units
                    .col-md-8 Description
                    .col-md-2 Per Unit Amount
                    .col-md-1

                  .row.mb-1( v-for="( row ) in service_report_items_not_destroyed" )
                    .col-md-1
                      input.form-control( type="text" v-model.trim="row.units" )
                    .col-md-8
                      input.form-control( type="text" v-model.trim="row.description" )
                    .col-md-2
                      .input-group
                        .input-group-prepend
                          .input-group-text
                            i.fa.fa-dollar-sign
                        input.form-control( type="number" :value="getServiceItemValue( row )" @change="setServiceItemValue( row, $event.target.value )" )
                    .col-md-1.p-0
                      button.btn.btn-link.p-0( @click.prevent="addItem"
                                           v-tooltip="'Add an additional item'" )
                        i.fa.fa-plus-square.fa-2x
                      button.btn.btn-link.p-0.ml-1( @click.prevent="removeItem( row )"
                                           v-tooltip="'Remove this item'" )
                        i.fa.fa-minus-square.fa-2x

              .row
                .col-md
                  form-textarea( :value="form.service_performed"
                                 name="service_performed"
                                 label="Service Work Performed"
                                 :validation="$v.form.service_performed"
                                 rows="5"
                                 @input="form.service_performed = $event; $v.form.service_performed.$touch()" )
              .row.mb-3
                .col-md
                  form-checkbox( :value="form.need_to_return"
                                 name="need_to_return"
                                 label="Need to Return - Fill out 'Work to be completed' once checked, and parts to order form (Need to complete for office use)"
                                 @input="form.need_to_return = $event; $v.form.need_to_return.$touch()" )

              .row.mb-3
                .col-md-12
                  ul.list-inline.my-0
                    li.list-inline-item.mr-4
                      h6 Service Tag Color:
                    li.list-inline-item.mr-4
                      form-checkbox( :value="form.white_tag"
                                     name="white_tag"
                                     label="White"
                                     @input="form.white_tag = $event; $v.form.white_tag.$touch()" )
                    li.list-inline-item.mr-4
                      form-checkbox( :value="form.red_tag"
                                     name="red_tag"
                                     label="Red"
                                     @input="form.red_tag = $event; $v.form.red_tag.$touch()" )
                    li.list-inline-item.mr-4
                      form-checkbox( :value="form.yellow_tag"
                                     name="yellow_tag"
                                     label="Yellow"
                                     @input="form.yellow_tag = $event; $v.form.yellow_tag.$touch()" )
                    li.list-inline-item.mr-4
                      form-checkbox( :value="form.no_tag"
                                     name="no_tag"
                                     label="None"
                                     @input="form.no_tag = $event; $v.form.no_tag.$touch()" )

              hr/
              .row
                .col-md-3
                  div( v-if="!form.signature_url" )
                    button.btn.btn-info.btn-lg( @click.prevent="showSignaturePad" )
                      i.fa.fa-signature.mr-1
                      | Customer Signature
                  div.text-success.lead.mt-1( v-if="form.signature" )
                    i.fa.fa-check-circle.mr-1
                    | Signature Captured

                  div( v-if="form.signature_url" )
                    h6 Customer Signature:
                    img.signature.img-fluid( :src="form.signature_url" )

                .col-md-3.offset-md-1
                  .card( v-if="exists" )
                    .card-body.bg-light.p-2
                      table.table-sm
                        tbody
                          tr
                            td.text-right.text-muted.pb-0 Created At:
                            td.pb-0 {{ form.created_at | datetime }}
                          tr
                            td.text-right.text-muted.pb-0 Updated At:
                            td.pb-0 {{ form.updated_at | datetime }}
                          tr
                            td.text-right.text-muted.pb-0 Last Updated By:
                            //- td.pb-0 {{ form.updating_user.name }}

                .col-md-4.offset-md-1
                  table.table.table-sm.table-bordered
                    tbody
                      tr
                        td.text-muted.text-right Subtotal:
                        td
                          i.fa.fa-dollar-sign
                          | {{ form.subtotal | price }}
                      //tr
                        td.text-muted.text-right Tax (8.25%):
                        td
                          i.fa.fa-dollar-sign
                          | {{ form.tax | price }}
                      tr
                        td.text-muted.text-right Total:
                        td
                          i.fa.fa-dollar-sign
                          | {{ form.total | price }}

          .row
            .col-md-8
              button.btn.btn-primary.mr-1( @click.prevent="submit" :disabled="$v.$invalid || submitting" ) {{ saveLabel }}
              button.btn.btn-link.mr-5( @click.prevent="$router.go( -1 )" ) Cancel
              button.btn.btn-secondary.mr-5( @click.prevent="downloadPDF" v-if="exists" )
                i.fa.fa-file-pdf.mr-1
                | View Report
              button.btn.btn-outline-primary.mr-5( @click.prevent="setState( 'Dispatched' )"
                                              v-tooltip="'Sends this Ticket to the assigned Techs queue for work'"
                                              v-if="form.state === 'Draft' && exists" )
                i.fa.fa-tools.mr-1
                | Dispatch to Tech
              template( v-if="form.state !== 'Draft'" )
                button.btn.btn-outline-primary.mr-1( @click.prevent="setState( 'Draft' )"
                                                     v-tooltip="'Makes this Ticket Draft, removing it from the Tech queue'"
                                                     v-if="$session.$isBackOffice()" )
                  i.fa.fa-pencil.mr-1
                  | Back to Draft
              template( v-if="form.state === 'Dispatched'" )
                button.btn.btn-outline-primary( @click.prevent="setState( 'Complete' )"
                                                v-tooltip="'Sets this Ticket as Complete, will email office and customer with ticket.'"
                                                v-if="!$v.$invalid && !$v.$dirty" )
                  i.fa.fa-clipboard-check.mr-1
                  | Complete Ticket!

              button.btn.btn-danger.mr-5( @click.prevent="deleteReport" v-if="$session.$isBackOffice()" )
                i.fa.fa-trash.mr-1
                | Delete Report
            .col-md-4
              div( style="position: absolute; right: 1em; bottom: 0;" )
                i.fas.fa-asterisk.fa-xs.text-danger.mr-1
                | are
                |
                strong required
                |
                | fields

    #signatureDialog.modal.fade( role="dialog" ref="signatureDialog" )
      .modal-dialog.modal-xl( role="document" )
        .modal-content
          .modal-header
            h5.modal-title
              i.fa.fa-signature.mr-1
              | Customer Signature
          .modal-body
            VueSignaturePad( id="signature" height="500px" ref="signaturePad" )
            span.text-danger.lead( v-if="signatureRequired" )
              i.fa.fa-exclamation-triangle.mr-1.mt-1
              | A Signature is Required

          .modal-footer
            button.btn.btn-primary.mr-1( @click.prevent="saveSignature" ) Done
            button.btn.btn-info.mr-1( @click.prevent="undoSignature" ) Start Over
            button.btn.btn-link.mr-1( @click.prevent="cancelSignature" ) Cancel

</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import FormInput from '../shared/form_input'
  import FormInputWithoutLabel from '../shared/form_input_without_label'
  import FormTextarea from '../shared/form_textarea'
  import FormRadio from '../shared/form_radio'
  import FormSelect from '../shared/form_select'
  import FormCheckbox from '../shared/form_checkbox'
  import FormDatetime from '../shared/form_datetime'
  import VueSignaturePad from 'vue-signature-pad';

  // import Dinero from 'dinero.js'

  import 'bootstrap/js/dist/modal'

  export default {
    name: 'ServiceTicketEdit',

    components: {
      FormInput,
      FormTextarea,
      FormRadio,
      FormSelect,
      FormCheckbox,
      FormInputWithoutLabel,
      FormDatetime,
      VueSignaturePad
    },

    data() {
      return {
        form: null,
        loaded: false,
        exists: false,
        submitting: false,
        serviceItemPrototype: null,
        serviceTechPrototype: null,
        techs: [],
        signatureRequired: false
      }
    },

    computed: {
      saveLabel() {
        return this.exists ? 'Save' : 'Create'
      },

      service_report_items_not_destroyed() {
        return this.loaded ? _.filter( this.form.service_report_items_attributes, ( item ) => { return item._destroy !== '1' } ) : []
      },

      service_report_techs_not_destroyed() {
        return _.filter( this.form.service_report_techs_attributes, ( item ) => { return item._destroy !== '1' } )
      },

      availableTechs() {
        const assignedTechs = _.map( _.map( this.service_report_techs_not_destroyed, ( tech ) => tech.tech_id ),
                                     ( t ) => _.find( this.techs, [ 'id', t ] ) )
        return _.without( this.techs, ...assignedTechs )
      },

    },

    watch: {
      service_report_items_not_destroyed: {
        handler: function() {
          if ( this.service_report_items_not_destroyed.length > 0 ) {
            this.form.subtotal = _.reduce( this.service_report_items_not_destroyed, ( acc, item ) => {
              return item.cost ? acc + ( item.cost * item.units ) : acc
            }, 0 )

            // this.form.tax = Dinero( { amount: this.form.subtotal } ).multiply( 0.0825 ).getAmount()
            this.form.total = this.form.subtotal// + this.form.tax
          }
        },
        deep: true
      }
    },

    validations: {
      form: {
        number:             {},
        contact:            { required },
        service_date:       { required },
        quote_number:       {},
        fire_alarm:         {},
        fire_sprinkler:     {},
        fire_extinguishers: {},

        antifreeze_systems:  {},
        fire_riser_backflow: {},
        irrigation_backflow: {},
        domestic_backflow:   {},
        other:               {},
        other_details:       {},
        service_agreement:   {},
        fire_hydrants:       {},
        access_control:      {},
        quote:               {},
        quote_id:            {},

        charge:            {},
        charge_amount:     {},
        warranty:          {},
        service_performed: {},
        service_requested: { required },
        service_duration:  {},
        need_to_return:    {},
        white_tag:         {},
        yellow_tag:        {},
        red_tag:           {},
        no_tag:            {},
        tech_id:           {},
        subtotal:          {},
        tax:               {},
        total:             {},
        service_date:      {},
      }
    },

    mounted() {
      this.fetchData()
    },

    methods: {
      fetchData() {
        let url = 'customers/' + this.$route.params.id + '/service_reports/'
        if ( this.$route.name === 'service_ticket_new' ) {
          this.exists = false
          url += 'new'
        }
        else {
          this.exists = true
          url += this.$route.params.service_ticket_id
        }
        if (this.$route.name !== 'service_ticket_delete'){
          this.$http.secured.get( url )
            .then( ( response ) => {
              this.form = response.data
              this.serviceItemPrototype = _.clone( this.form.service_report_items_attributes[0] )
              _.each( _.keys( this.serviceItemPrototype ), ( x ) => this.serviceItemPrototype[x] = null )

              this.serviceTechPrototype = _.clone( this.form.service_report_techs_attributes[0] )
              _.each( _.keys( this.serviceTechPrototype ), ( x ) => this.serviceTechPrototype[x] = null )

              this.loaded = true
            })

        this.$http.secured.get( 'techs' ).then( ( response ) => { this.techs = response.data } )
        }else{
          this.$http.secured.delete( url )
            .then( response => {
              this.$toastr.success( 'The Service Report has been deleted successfully!' )
              this.$router.push( { name: 'service_reports' } )
          })
        }

        this.$http.secured.get( url )
          .then( ( response ) => {
            this.form = response.data
            this.serviceItemPrototype = _.clone( this.form.service_report_items_attributes[0] )
            _.each( _.keys( this.serviceItemPrototype ), ( x ) => this.serviceItemPrototype[x] = null )

            this.serviceTechPrototype = _.clone( this.form.service_report_techs_attributes[0] )
            _.each( _.keys( this.serviceTechPrototype ), ( x ) => this.serviceTechPrototype[x] = null )

            this.loaded = true
          })

        this.$http.secured.get( 'techs' ).then( ( response ) => { this.techs = response.data } )

      },

      submit() {
        this.submitting = true
        let url = 'customers/' + this.$route.params.id  + '/service_reports'
        if ( this.exists ) {
          url += '/' + this.$route.params.service_ticket_id
          this.$http.secured.patch( url, { service_report: this.form } )
            .then( ( response ) => {
              this.form = response.data
              this.$toastr.success( 'The Service Ticket has been updated successfully!' )
              this.submitting = false
              this.$scrollToTop()
            })
        }
        else {
          this.$http.secured.post( url, { service_report: this.form } )
            .then( ( response ) => {
              this.$toastr.success( 'The Service Ticket has been created successfully!' )
              this.$router.push( {
                name: 'service_ticket_edit',
                params: { id: this.$route.params.id, service_ticket_id: response.data.id }
              } )
            })
        }
      },

      removeItem( row ) {
        if ( row.id ) {
          row._destroy = '1'
        }
        else {
          this.form.service_report_items_attributes = _.without( this.form.service_report_items_attributes, row )
        }
      },

      addItem() {
        this.form.service_report_items_attributes.push( _.clone( this.serviceItemPrototype ) )
      },

      removeTech( row ) {
        if ( row.id ) {
          row._destroy = '1'
        }
        else {
          this.form.service_report_techs_attributes = _.without( this.form.service_report_techs_attributes, row )
        }
      },

      addTech() {
        this.form.service_report_techs_attributes.push( _.clone( this.serviceTechPrototype ) )
      },

      togglePrimaryTech( tech ) {
        if ( tech.primary ) {
          _.each( this.service_report_techs_not_destroyed, ( t ) => {
            if ( tech !== t ) {
              t.primary = null
            }
          } )
        }
      },

      showSignaturePad() {
        const pad = this.$refs.signaturePad
        $( this.$refs.signatureDialog ).on( 'shown.bs.modal', ( e ) => pad.resizeCanvas() )

        $( this.$refs.signatureDialog ).modal( 'show' )
      },

      saveSignature() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if ( isEmpty ) {
          this.signatureRequired = true
        }
        else {
          this.form.signature = data
          this.cancelSignature()
        }
      },

      undoSignature() {
        this.signatureRequired = false
        this.form.signature = null
        this.$refs.signaturePad.undoSignature()
      },

      cancelSignature() {
        $( this.$refs.signatureDialog ).off( 'shown.bs.modal' )
        $( this.$refs.signatureDialog ).modal( 'hide' )
      },
      deleteReport() {
        let url = 'customers/' + this.$route.params.id + '/service_reports/' + this.$route.params.service_ticket_id
        this.$http.secured.delete( url )
          .then( response => {
            this.$toastr.success( 'The Service Report has been deleted successfully!' )
            this.$router.push( { name: 'service_reports' } )
        })
      },
      downloadPDF() {
        this.$router.push( { name: 'service_ticket_print', params: { id: this.form.customer_id, service_ticket_id: this.form.id } } )
      },

      techName( tech ) {
        const t = _.find( this.techs, [ 'id', tech.tech_id ] )
        return t ? t.name : tech.id
      },

      getServiceItemValue( row ) {
        return this.$options.filters.price( row.cost )
      },

      setServiceItemValue( row, cost ) {
        row.cost = Math.round( parseFloat( cost ) * Math.pow( 10, 2 ) )
      },

      setState( state ) {
        this.$http.secured
          .post( 'customers/' + this.form.customer_id + '/service_reports/' + this.form.id + '/set_state', {
            state: state
          } )
          .then( ( response ) => {
            this.form = response.data
            this.$toastr.success( 'Service Ticket ' + this.form.number + ' has been set to ' + state )
          })
      }

    }
  }
</script>

<style scoped lang="scss">
  #signatureDialog .modal-body div, .signature {
    border: 1px solid;
    border-radius: 4px;
  }
</style>
